import React, { useState } from 'react';
import '../assets/css/landingPage.css';
import LogoImg from '../assets/Logo/gavfh6Hp_400x400.jpg';
import { CiUser } from "react-icons/ci";
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    // function closeBellAlertPopup() {
    //     var dropdownDiv = document.getElementById('alertsDropdownDiv');
    //     dropdownDiv.style.display = "none";
    // }
    const [showDropdown, setShowDropdown] = useState(false);
    const [showInnerDropdown, setShowInnerDropdown] = useState(false);
    const { logout } = useAuth();
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const toggleInnerDropdown = () => {
        setShowInnerDropdown(!showInnerDropdown);
    };
    const handleLogout = () => {
        logout(); // Call logout from AuthContext
        navigate('/login'); // Redirect to login page after logout
    };

    return (
        <>
            <div className='navbar navbar-expand-lg navbar-dark bg-top' id='mainNav-top'>
                <div className='navbar-brand text-left d-flex'>
                    <a href="/">
                        <img src={LogoImg} alt="logo"  style={{ height: '80px', borderRadius: '10px' }} />
                    </a>
                </div>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className='navbar-collapse collapse' id='mainNav'>
                    <div className='position-relative d-flex align-items-center w-100 py-2 px-3' id='headerMenuIconGroupContainer'>
                        <ul id="navbar_searchbar" className="navbar-nav mx-auto">
                            <li className="nav-item">
                                <form className="search-bx form-inline mb-0" action="#" id="search_module">
                                    <div className="input-group">
                                        <input className="form-control" name="search-box" id="search-box" type="text" autocomplete="off" placeholder="ex.Live portfolio" onkeyup="showResult(this.value);" fdprocessedid="z3g1rr" />
                                        <span className="input-group-btn">
                                            <button className="btn " type="button" fdprocessedid="hh1wqq">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </span>
                                        <div className="top-search nc-sugges-box hide" id="suggesstion-box"></div>
                                    </div>
                                </form>
                            </li>
                        </ul>
                        <ul className='navbar-nav right-menu' id='ncTopIconToolBar'>
                            <li className='nav-item nc-nav-item'>
                                <a className="nav-link nc-nav-link help-header-btn" target="_blank" id="helpHeaderBtn" href="#">Help</a>
                            </li>
                            <li className='nav-item nc-nav-item dropdown' data-toggle="tooltip" data-placement="top" title="Market Update">
                                <a className="nav-link nc-nav-link dropdown-toggle" id="messagesDropdown" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa-solid fa-horse-head icons"></i>
                                </a>
                                <div className="dropdown-menu live-trading nc-ddm-live-trading px-2" aria-labelledby="messagesDropdown">
                                    <h6 className="dropdown-header">Market Update</h6>
                                    <div className="nc-ddm-lt-holder nc-scrollbar">
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>GOLD</p></div>
                                            <div className="trad-info right green">
                                                <div className="num-right">
                                                    <strong>72540.00</strong>
                                                    <span><img src="https://wealthelite.in/img/up.png" alt="up" /> 22.00 (0.03%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>SILVER</p></div>
                                            <div className="trad-info right green">
                                                <div className="num-right">
                                                    <strong>91638.00</strong>
                                                    <span><img src="https://wealthelite.in/img/up.png" alt="up" /> 1,194.00 (1.32%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>SENSEX</p></div>
                                            <div className="trad-info right green">
                                                <div className="num-right">
                                                    <strong>75,146.54</strong>
                                                    <span><img src="https://wealthelite.in/img/up.png" alt="up" /> 764.30 (1.03%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>Crude</p></div>
                                            <div className="trad-info right green">
                                                <div className="num-right">
                                                    <strong>6226.00</strong>
                                                    <span><img src="https://wealthelite.in/img/up.png" alt="up" /> 36.00 (0.58%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>USD/INR</p></div>
                                            <div className="trad-info right danger">
                                                <div className="num-right">
                                                    <strong>0.0000</strong>
                                                    <span><img src="https://wealthelite.in/img/down.png" alt="down" /> -83.0975 (-100.00%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>EURO</p></div>
                                            <div className="trad-info right danger">
                                                <div className="num-right">
                                                    <strong>91.2175</strong>
                                                    <span><img src="https://wealthelite.in/img/down.png" alt="down" /> 0.0000 (0.00%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>UK</p></div>
                                            <div className="trad-info right danger">
                                                <div className="num-right">
                                                    <strong>106.4600</strong>
                                                    <span><img src="https://wealthelite.in/img/down.png" alt="down" /> 0.0000 (0.00%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>YEN</p></div>
                                            <div className="trad-info right danger">
                                                <div className="num-right">
                                                    <strong>56.2000</strong>
                                                    <span><img src="https://wealthelite.in/img/down.png" alt="down" /> 0.0000 (0.00%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>NIFTY 50</p></div>
                                            <div className="trad-info right green">
                                                <div className="num-right">
                                                    <strong>22,870.25</strong>
                                                    <span><img src="https://wealthelite.in/img/up.png" alt="up" /> 249.90 (1.10%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>NASDAQ</p></div>
                                            <div className="trad-info right green">
                                                <div className="num-right">
                                                    <strong>17,187.90</strong>
                                                    <span><img src="https://wealthelite.in/img/up.png" alt="up" /> 330.86 (1.96%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>FTSE</p></div>
                                            <div className="trad-info right green">
                                                <div className="num-right">
                                                    <strong>8,246.95</strong>
                                                    <span><img src="https://wealthelite.in/img/up.png" alt="up" /> 14.91 (0.18%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trad-box">
                                            <div className="trad-type text-white"><p>Nikkei</p></div>
                                            <div className="trad-info right green">
                                                <div className="num-right">
                                                    <strong>38,789.79</strong>
                                                    <span><img src="https://wealthelite.in/img/up.png" alt="up" /> 299.62 (0.78%)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item nc-nav-item">
                                <a className="nav-link nc-nav-link nc-idea-bank-btn btn-THI" href="#" data-toggle="tooltip" title="Idea Bank">
                                    <i className="fa-solid fa-lightbulb icons"></i>
                                </a>
                            </li>
                            <li className="nav-item nc-nav-item">
                                <a className="nav-link nc-nav-link nc-idea-bank-btn btn-THI" href="#" data-toggle="tooltip" title="Idea Bank">
                                    <i className="fa-solid fa-lightbulb icons"></i>
                                </a>
                            </li>
                            <li className="nav-item nc-nav-item">
                                <a className="nav-link nc-nav-link nc-idea-bank-btn btn-THI" href="#" data-toggle="tooltip" title="Idea Bank">
                                    <i className="fa-solid fa-lightbulb icons"></i>
                                </a>
                            </li>
                            <li className="nav-item nc-nav-item">
                                <a className="nav-link nc-nav-link nc-idea-bank-btn btn-THI" href="#" data-toggle="tooltip" title="Idea Bank">
                                    <i className="fa-solid fa-lightbulb icons"></i>
                                </a>
                            </li>
                            <li className="nav-item nc-nav-item dropdown">
                                <a
                                    className="nav-link nc-nav-link nc-idea-bank-btn btn-THI"
                                    href="#"
                                    data-toggle="tooltip"
                                    title="Idea Bank"
                                    onClick={toggleDropdown}
                                >
                                    <CiUser style={{ color: 'black', fontSize: '25px' }} />
                                </a>

                                {showDropdown && (
                                    <ul className="dropdown-menu show" style={{left:'-135px'}}>
                                        <li className="dropdown-item" >
                                            Profile
                                            
                                        </li>
                                        <li className="dropdown-item">Settings</li>
                                        <li className="dropdown-item" onClick={handleLogout}>Logout</li>
                                    </ul>
                                )}
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;
