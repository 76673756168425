import React from 'react'
import { useEffect } from 'react'
import Navbar from '../../Navbar'
import Header from '../../Header'
import Footer from '../../Footer'
import '../../../assets/css/Pages css/CommonCard.css'
import NewPolicy from '../../../assets/Images/Insurance/new-insurance.png'
import DueList from '../../../assets/Images/Insurance/duList.png'
import updatePolicy from '../../../assets/Images/Insurance/liUpdate.png'
import liHolders from '../../../assets/Images/Insurance/liHolders.png'
import bulkUpload from '../../../assets/Images/Insurance/bulkUpload.png'
import RenewPolicy from '../../../assets/Images/Insurance/renewPolicy.png'
import { Link } from 'react-router-dom'
const GeneralInsurance = () => {
  const cardData = [
    { name: 'Enter New Policy', icon: NewPolicy, link: '/Insurance/general/new-gi-policy' },
    { name: 'Update / Delete Policy', icon: updatePolicy, link: '/Insurance/general/update-delete-gi-policy' },
    { name: 'View / Update Due List', icon: DueList, link: '/Insurance/general/view-update-gi-due' },
    { name: 'Renew Policy', icon: RenewPolicy, link: '/Insurance/general/renew-gi-policy' },
    { name: 'GI Holders', icon: liHolders, link: '/Insurance/general/gi-holder' },
    { name: 'Update Bulk Policy', icon: bulkUpload, link: '/Insurance/general/update-bulk-gi-policy' }
  ]

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <>
      <Navbar/>
      <Header/>
      <div className='content-wrapper position-relative mt-3 d-flex flex-column' style={{ minHeight: 'calc(100vh - 152px)'}}>
        <div className='container' id='changesWidth'>
          <div id="main-content-wrapper" className='container-fluid'>
            <div className='container-fluid'>
              <div className='row'>
                {cardData.map((card, index) => (
                  <div key={index} className='col-lg-4 col-md-6'>
                    <div className='card-menu'>
                      <div className='icon'>
                        <img src={card.icon} alt={card.name} height={54}/>
                      </div>
                      <div className='card-menu-content'>
                        <h4>{card.name}</h4>
                      </div>
                      <Link to={card.link} className='card-menu-link'></Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer sticlyFooter={true}/>
      </div>
    </>
  )
}

export default GeneralInsurance
