import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../../component/Loader';
import notify from '../../Notification';
import axios from 'axios';
import { FaGlobe, FaWhatsapp, FaPrint } from 'react-icons/fa';
import { MdOutlineTextsms, MdEmail } from "react-icons/md";
import { FaFilePdf, FaFileExcel } from 'react-icons/fa6';
import { AgGridReact } from 'ag-grid-react';




const LiveMFPortfolio = () => {
  const baseURL = process.env.REACT_APP_BASEURL;

  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedOutput, setSelectedOutput] = useState('web');
  const [clientSearch, setClientSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedClientData, setSelectedClientData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [viewBy, setViewBy] = useState('');
  const [rowData, setRowData] = useState([]);



  useEffect(() => {
    const fetchResults = async () => {
      if (clientSearch.length > 2) {
        setIsLoading(true);
        try {
          const response = await axios.get(`${baseURL}/api/search_client?q=${clientSearch}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          setSearchResults(response.data);
          setShowDropdown(true);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false)
          notify('danger', 'Error', 'Failed to load search results');
        }
      } else {
        setIsLoading(false);
        setShowDropdown(false);
      }
    };

    const debounceFetchResults = setTimeout(() => {
      fetchResults();
    }, 300);

    return () => clearTimeout(debounceFetchResults);
  }, [clientSearch, baseURL]);

  const handleOutputSelect = (outputType) => {
    setSelectedOutput(outputType);
  };


  const handleResultClick = (result) => {
    setClientSearch(`${result.user_details.first_name} ${result.user_details.last_name}`);
    setSelectedClientId(result._id);
    setSelectedClientData(result.user_details);
    setShowDropdown(false);
  };



  const currentDate = new Date().toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });




  const get_mutual_fund_details = async () => {
    if (clientSearch.length > 2) {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/api/get_mutual_fund_details/${selectedClientId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setRowData(response.data.investments);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false)
        notify('danger', 'Error', 'Failed to load search results');
      }
    } else {
      setIsLoading(false);
    }
  };

  const get_client_investment_fund_list = async () => {
    if (clientSearch.length > 2) {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/api/get_client_investment_fund_list/${selectedClientId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
       
        console.log(response.data)
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false)
        notify('danger', 'Error', 'Failed to load search results');
      }
    } else {
      setIsLoading(false);
    }
  };

  const get_client_investment_fund_type_list = async () => {
    if (clientSearch.length > 2) {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/api/get_client_investment_fund_type_list/${selectedClientId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        // setRowData(response.data.investments);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false)
        notify('danger', 'Error', 'Failed to load search results');
      }
    } else {
      setIsLoading(false);
    }
  };

  const columnDefs = [
    { headerName: "Scheme Name", field: "scheme_name" },
    { headerName: "Scheme Type", field: "scheme_type" },
    { headerName: "Scheme Sub-Type", field: "scheme_sub_type" },
    { headerName: "Folio Number", field: "folio_number" },
    { headerName: "Investment Since", field: "inv_since", valueFormatter: (params) => new Date(params.value).toLocaleDateString() },
    { headerName: "Purchase", field: "purchase" },
    { headerName: "Redemption", field: "redemption" },
    { headerName: "Current Value", field: "cur_value" },
    { headerName: "Current Units", field: "cur_units" },
    { headerName: "Current NAV", field: "cur_nav" },
    { headerName: "Gain/Loss", field: "gain_loss" },
    { headerName: "Absolute Return", field: "abs_rtn" },
    { headerName: "XIRR", field: "xirr" }
  ];

  const handleViewByChange = (e) => {
    setViewBy(e.target.value);
  };

  const handelShowData = (e) => {
    e.preventDefault()
   
    if (!viewBy) {
      notify('danger', 'Error', 'Please select an option to view by');
      return;
    }
    if (!selectedClientId) {
      notify('danger', 'Error', 'Please select client')
      return
    }
    if (viewBy === 'client') {
      get_mutual_fund_details();
      get_client_investment_fund_list()
    }

    setShowDetails(true)
  };


  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div
              className="rounded p-lg-4 p-3 my-3"
              style={{
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              }}
            >
              <form>
                <div className='row'>
                  <div className="col-12 col-sm-12 col-md-6 ">
                    <div className="row">
                      <div className=' col-md-4 text-left '>
                        <label>Valuation As On:</label>
                      </div>
                      <div className="col-md-8">
                        <div class="form-group">
                          <input type="date" className="form-control" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className=' col-md-4 text-left '>
                        <label>Select View By:</label>
                      </div>
                      <div className="col-md-8">
                        <div class="form-group">
                          <select className="form-select" value={viewBy} onChange={handleViewByChange}>
                            <option value=''>Select</option>
                            <option value="client">Client</option>
                            <option value="family">Family</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className=' col-md-4 text-left '>
                        <label>Search By Name / PAN / Folio No.:</label>
                      </div>
                      <div className="col-md-8">
                        <div class="form-group mb-0 mt-3">
                          <input type="text" className="form-control" placeholder="Search client" value={clientSearch} onChange={(e) => setClientSearch(e.target.value)} />
                          {showDropdown && searchResults.length > 0 && (
                            <ul
                              className="dropdown-menu show mx-2 border-bottom"
                              style={{ width: '90%', maxHeight: '200px', overflowY: 'auto' }}
                            >
                              {searchResults.map((result, index) => (
                                <li
                                  key={index}
                                  className="dropdown-item"
                                  onClick={() => handleResultClick(result)}
                                >
                                  {`${result.user_details.first_name} ${result.user_details.last_name} [${result._id}]`}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className='col-12 col-sm-12 col-md-6 pl-xl-5'>
                    <div className="row mb-3">
                      <div className="col-md-4 text-start">
                        <label>CAS Details:</label>
                      </div>
                      <div className='col-md-8'>
                        <div className="form-group">
                          <input className="form-check-input" type="checkbox" id="includeCas" />
                          <label className="form-check-label" htmlFor="includeCas">
                            Include CAS
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>View Transaction:</label>
                      </div>
                      <div className='col-md-8'>
                        <div className='form-group'>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-1"
                              type="radio"
                              name="transactionType"
                              id="liveTransactions"
                              defaultChecked
                            />
                            <label className="form-check-label" htmlFor="liveTransactions">
                              Live Transactions
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-1"
                              type="radio"
                              name="transactionType"
                              id="allTransactions"
                            />
                            <label className="form-check-label" htmlFor="allTransactions">
                              All Transactions
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-3'>
                      <div className="col-md-4">
                        <label>View Funds:</label>
                      </div>
                      <div className='col-md-8'>
                        <div className='form-group d-flex pr-0'>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-1"
                              type="radio"
                              name="fundView"
                              id="allFunds"
                              defaultChecked
                            />
                            <label className="form-check-label" htmlFor="allFunds">
                              All Funds
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-1"
                              type="radio"
                              name="fundView"
                              id="selectedFunds"
                            />
                            <label className="form-check-label" htmlFor="selectedFunds">
                              Selected Funds
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-1"
                              type="radio"
                              name="fundView"
                              id="selectedTypes"
                            />
                            <label className="form-check-label" htmlFor="selectedTypes">
                              Selected Types
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>View MF Report:</label>
                      </div>
                      <div className='col-md-8 d-flex gap-3'>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="fundTypeWise"
                          />
                          <label className="form-check-label" htmlFor="fundTypeWise">
                            Fund Type Wise
                          </label>
                        </div>

                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="tagWise" />
                          <label className="form-check-label" htmlFor="tagWise">
                            Tag Wise
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-3'>
                      <div className="col-md-4">
                        <label>Transaction Duration:</label>
                      </div>
                      <div className='col-md-8'>
                        <select className="form-select">
                          <option>All</option>
                        </select>
                      </div>
                    </div>

                    <div className='row mb-3'>
                      <div className="col-md-4">
                        <label>Show Valuation With:</label>
                      </div>
                      <div className='col-md-8'>
                        <div className='form-group'>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="all" />
                            <label className="form-check-label" htmlFor="all">
                              All
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="fundDetail" />
                            <label className="form-check-label" htmlFor="fundDetail">
                              Fund Detail
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="summary" />
                            <label className="form-check-label" htmlFor="summary">
                              Summary
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="graph" />
                            <label className="form-check-label" htmlFor="graph">
                              Graph
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="pnlSummary" />
                            <label className="form-check-label" htmlFor="pnlSummary">
                              P&L Summary
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>Exclude Column:</label>
                      </div>
                      <div className='col-md-8'>
                        <select className="form-select">
                          <option></option>
                        </select>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className="col-md-4">
                        <label>Out Put In:</label>
                      </div>
                      <div className='col-md-8'>
                        <div className="btn-group" role="group">
                          <button
                            type="button"
                            className={`btn ${selectedOutput === 'web' ? 'btn-info' : 'btn-light'} px-4`}
                            onClick={() => handleOutputSelect('web')}
                          >
                            <FaGlobe size={20} />
                          </button>
                          <button
                            type="button"
                            className={`btn ${selectedOutput === 'sms' ? 'btn-info' : 'btn-light'}  px-4`}
                            onClick={() => handleOutputSelect('sms')}
                          >
                            <MdOutlineTextsms size={20} />
                          </button>
                          <button
                            type="button"
                            className={`btn ${selectedOutput === 'mail' ? 'btn-info' : 'btn-light'}  px-4`}
                            onClick={() => handleOutputSelect('mail')}
                          >
                            <MdEmail size={20} />
                          </button>
                          <button
                            type="button"
                            className={`btn ${selectedOutput === 'whatsApp' ? 'btn-info' : 'btn-light'}  px-4`}
                            onClick={() => handleOutputSelect('whatsApp')}
                          >
                            <FaWhatsapp size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-3 my-3 justify-content-center">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="newTab" />
                      <label className="form-check-label" htmlFor="newTab">
                        New Tab
                      </label>
                    </div>

                    <button className="btn btn-success" onClick={handelShowData}>Show</button>
                  </div>
                </div>
              </form>
            </div>
            {showDetails && (
              <>
                <div
                  className="rounded p-lg-4 p-3 my-3"
                  style={{
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                  }}
                >
                  <div className="container-fluid mb-4">
                    <div className="row">
                      <div className="col-md-4">
                        <p className="mb-0"><strong>Client Name :</strong></p>
                        <p className="mb-0">{`${selectedClientData.first_name} ${selectedClientData.last_name}`}</p>
                        <p className="mb-0 text-muted small">{`[PAN: ${selectedClientData.pan_number}]`}</p>
                        <p className="mb-0 text-muted small">{`[Email: ${selectedClientData.email}]`}</p>
                        <p className="mb-0 text-muted small">{`[DOB: ${selectedClientData.date_of_birth}]`}</p>
                      </div>
                      <div className="col-md-4">
                        <p className="mb-0"><strong>Address :</strong></p>
                        <p className="mb-0">76 INDRALOK COLONY SUDAMANAGAR NEAR</p>
                        <p className="mb-0">ASHIRWAD BHAWANINDOREINDORE452009</p>
                        <p className="mb-0 mt-2"><strong>Contact No. :</strong></p>
                        <p className="mb-0">{selectedClientData.mobile_number}</p>
                      </div>
                      <div className="col-md-4">
                        <p className="mb-0"><strong>Current Sensex :</strong></p>
                        <p className="mb-0 text-success fw-bold">83462.61 ↑ 514.38 (0.62%)</p>
                        <p className="mb-0 mt-2"><strong>Export In:</strong></p>
                        <div className="btn-group mt-1" role="group" aria-label="Export options">
                          <button type="button" className="btn btn-outline-primary btn-sm"><FaPrint /></button>
                          <button type="button" className="btn btn-outline-danger btn-sm"><FaFilePdf /></button>
                          <button type="button" className="btn btn-outline-success btn-sm"><FaFileExcel /></button>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <p className="mb-0"><strong>Valuation Report as on Date</strong></p>
                        <p className="mb-0">{currentDate}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ag-theme-alpine my-4"
                    style={{ width: '100%' }}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={rowData}
                      domLayout="autoHeight"
                    />
                  </div>
                </div>

              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LiveMFPortfolio;


