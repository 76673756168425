import React from 'react'
import { useEffect } from 'react'
import Navbar from '../../Navbar'
import Header from '../../Header'
import Footer from '../../Footer'
import '../../../assets/css/Pages css/CommonCard.css'
import { Link } from 'react-router-dom'
import FammilyMaster1 from '../../../assets/Images/family-masters_db-1.png'
import FammilyMaster2 from '../../../assets/Images/family-masters_db-2.png'
import FammilyMaster3 from '../../../assets/Images/family-masters_db-3.png'
import FammilyMaster4 from '../../../assets/Images/family-masters_db-4.png'
import FammilyMaster5 from '../../../assets/Images/family-masters_db-5.png'
import FammilyMaster6 from '../../../assets/Images/family-masters_db-6.png'
import FammilyMaster7 from '../../../assets/Images/family-setting.png'
import FammilyMaster8 from '../../../assets/Images/family-relationship.png'


const FamilyMaster = () => {
  const cardData = [
    { name: 'Create Family', icon: FammilyMaster1, link: '/UserManager/utility/create-family-filter' },
    { name: 'Update Family', icon: FammilyMaster2, link: '/UserManager/utility/update-family-filter' },
    { name: 'Delete Family', icon: FammilyMaster3, link: '/UserManager/utility/delete-family-filter' },
    { name: 'Family List', icon: FammilyMaster4, link: '/UserManager/utility/show-family-list' },
    { name: 'Non Family List', icon: FammilyMaster5, link: '/UserManager/utility/non-family-list' }, 
    { name: 'Member Search', icon: FammilyMaster6, link: '/UserManager/utility/family-member-search' },
    { name: 'Family Setting', icon: FammilyMaster7, link: '/UserManager/utility/family-member-setting' },
    { name: 'Family Relationship Management', icon: FammilyMaster8, link: '/UserManager/utility/family-relation-manage' },
  ]

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <>
      <Navbar/>
      <Header/>
      <div className='content-wrapper position-relative mt-3 d-flex flex-column' style={{ minHeight: 'calc(100vh - 152px)'}}>
        <div className='container' id='changesWidth'>
          <div id="main-content-wrapper" className='container-fluid'>
            <div className='container-fluid'>
              <div className='row'>
                {cardData.map((card, index) => (
                  <div key={index} className='col-lg-4 col-md-6'>
                    <div className='card-menu'>
                      <div className='icon'>
                        <img src={card.icon} alt={card.name} height={54}/>
                      </div>
                      <div className='card-menu-content'>
                        <h4>{card.name}</h4>
                      </div>
                      <Link to={card.link} className='card-menu-link'></Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer sticlyFooter={true}/>
      </div>

     
    </>
  )
}

export default FamilyMaster
