import React, { useEffect, useState } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../Loader';
import notify from '../../Notification';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';

const AllMergedClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const baseURL = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    const getAllClient = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/api/get_all_merge_client`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.data.status === true) {
          setRowData(response.data.data);
        }
      } catch (error) {
        notify('danger', 'Error', 'Failed to load all clients');
      } finally {
        setIsLoading(false);
      }
    };

    getAllClient();
  }, [baseURL]);

  const columnDefs = [
    {
      headerName: 'Main Client',
      field: 'mainClient',
      width: 500,
      valueGetter: (params) =>
        `${params.data.main_client.user_details.first_name} ${params.data.main_client.user_details.last_name}`,
    },
    {
      headerName: 'Merged Clients',
      field: 'mergedClients',
      width: 740,
      valueGetter: (params) => {
        const mergedClients = params.data.merged_client_list.map(
          (client) => `${client.user_details.first_name} ${client.user_details.last_name}`
        );
        return mergedClients.join(', ');
      },
    },
  ];

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div
              className="rounded p-lg-4 p-3 my-3"
              style={{
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              }}
            >
              {rowData.length > 0 && (
                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                  <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    domLayout="autoHeight"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AllMergedClient;
