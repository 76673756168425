import React, { useState, useRef } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../../component/Loader';
import notify from '../../Notification';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';

const searchOptions = [
  { label: 'Name', placeholder: 'Search by Name' },
  { label: 'Email', placeholder: 'Search by Email' },
  { label: 'PAN No.', placeholder: 'Search by PAN No.' },
  { label: 'Mobile No.', placeholder: 'Search by Mobile No.' },
  { label: 'Address', placeholder: 'Search by Address' },
];

const CreateFamily = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeSearchOptionStep1, setActiveSearchOptionStep1] = useState(searchOptions[0]);
  const [activeSearchOptionStep2, setActiveSearchOptionStep2] = useState(searchOptions[0]);
  const [rowData, setRowData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowDataStep2, setRowDataStep2] = useState([]); 
  const [searchQueryStep2, setSearchQueryStep2] = useState('');
  const debounceTimeoutRef = useRef(null);
  const debounceTimeoutRefStep2 = useRef(null); 
  const [selectedData, setSelectedData] = useState(null);
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([]);
  const [familyMemberRelations, setFamilyMemberRelations] = useState({});

  const baseURL = process.env.REACT_APP_BASEURL;

  const mapSearchBy = (activeSearchOptionStep1) => {
    switch (activeSearchOptionStep1.label) {
      case 'Name':
        return 'first_name';
      case 'Email':
        return 'email';
      case 'PAN No.':
        return 'pan_number';
      case 'Mobile No.':
        return 'mobile_number';
      case 'Address':
        return 'address';
      default:
        return 'first_name';
    }
  };

  const mapSearchByStep2 = (activeSearchOptionStep2) => {
    switch (activeSearchOptionStep2.label) {
      case 'Name':
        return 'first_name';
      case 'Email':
        return 'email';
      case 'PAN No.':
        return 'pan_number';
      case 'Mobile No.':
        return 'mobile_number';
      case 'Address':
        return 'address';
      default:
        return 'first_name';
    }
  };

  // Fetch search results for Step 1
  const fetchSearchResults = async (query) => {
    if (query.length > 2) {
      setIsLoading(true);
      try {
        const transformedSearchBy = mapSearchBy(activeSearchOptionStep1);
        const response = await axios.post(`${baseURL}/api/search_client_by_type`, {
          searchQuery: query,
          searchBy: transformedSearchBy,
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        const data = response.data;

        if (data && data.length) {
          setRowData(data);
        } else {
          notify('danger', 'Error', 'No clients found!');
          setRowData([]);
        }
      } catch (error) {
        notify('danger', 'Error', 'Failed to search!');
        setRowData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Fetch search results for Step 2
  const fetchSearchResultsStep2 = async (query) => {
    if (!selectedData) {
      notify('danger', 'Error', 'Please select a family head.');
      return;
    }
    if (query.length > 2) {
      setIsLoading(true);
      try {
        const transformedSearchBy = mapSearchByStep2(activeSearchOptionStep2);
        const response = await axios.post(`${baseURL}/api/search_client_by_type`, {
          searchQuery: query,
          searchBy: transformedSearchBy,
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        const data = response.data;

        if (data && data.length) {
          setRowDataStep2(data);
        } else {
          notify('danger', 'Error', 'No family members found!');
          setRowDataStep2([]);
        }
      } catch (error) {
        notify('danger', 'Error', 'Failed to search!');
        setRowDataStep2([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSearchInput = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      fetchSearchResults(query);
    }, 500);
  };

  const handleSearchInputStep2 = (e) => {
    const query = e.target.value;
    setSearchQueryStep2(query);

    if (debounceTimeoutRefStep2.current) {
      clearTimeout(debounceTimeoutRefStep2.current);
    }

    debounceTimeoutRefStep2.current = setTimeout(() => {
      fetchSearchResultsStep2(query);
    }, 500);
  };

  // Step 1 table columns
  const columnDefs = [
    { headerName: 'Sr. No.', field: 'srNo', valueGetter: 'node.rowIndex + 1', width: 50 },
    {
      headerName: 'Select Head',
      field: 'actions',
      width: 130,
      cellRenderer: (params) => (
        <div>
          <input
            type="radio"
            name="clientSelect"
            checked={selectedData && selectedData._id === params.data._id}
            onChange={() => setSelectedData(params.data)}
          />
        </div>
      ),
    },
    { headerName: 'Client Name', field: 'clientName', valueGetter: (params) => `${params.data.user_details.first_name} ${params.data.user_details.last_name}` },
    { headerName: 'Client ID', field: '_id', width: 220 },
    { headerName: 'PAN', field: 'pan_number', width: 150, valueGetter: (params) => params.data.user_details.pan_number },
    { headerName: 'Mobile', field: 'mobile_number', width: 150, valueGetter: (params) => params.data.user_details.mobile_number },
    { headerName: 'Email', field: 'email', width: 220, valueGetter: (params) => params.data.user_details.email },
    { headerName: 'Pincode', field: 'pincode', width: 120, valueGetter: (params) => params.data.user_details.pincode },
  ];


  const columnDefsStep2 = [
    { headerName: 'Sr. No.', field: 'srNo', valueGetter: 'node.rowIndex + 1', width: 50 },
    {
      headerName: 'Select Member',
      field: 'actions',
      width: 130,
      cellRenderer: (params) => (
        <div>
          <input
            type="checkbox"
            checked={selectedFamilyMembers.some((member) => member._id === params.data._id)}
            onChange={() => handleFamilyMemberSelection(params.data)}
          />
        </div>
      ),
    },
    { headerName: 'Family Member Name', field: 'memberName', valueGetter: (params) => `${params.data.user_details.first_name} ${params.data.user_details.last_name}` },
    { headerName: 'Family Member ID', field: '_id', width: 220 },
    { headerName: 'Mobile', field: 'mobile_number', width: 150, valueGetter: (params) => params.data.user_details.mobile_number },
    { headerName: 'Email', field: 'email', width: 240, valueGetter: (params) => params.data.user_details.email },
    {
      headerName: 'Relation with Head',
      field: 'relation',
      width: 250,
      cellRenderer: (params) => (
        <div>
          <select
            value={familyMemberRelations[params.data._id] || ''}
            onChange={(e) => handleRelationChange(params.data._id, e.target.value)}
          >
            <option value="">Select</option>
            <option value="father">Father</option>
            <option value="mother">Mother</option>
            <option value="brother">Brother</option>
            <option value="sister">Sister</option>
            <option value="son">Son</option>
            <option value="daughter">Daughter</option>
            <option value="grand_son">Grand Son</option>
            <option value="grand_daughter">Grand Daughter</option>
            <option value="spouse">Spouse</option>
            <option value="self">Self</option>
            <option value="other">Other</option>
          </select>
        </div>
      ),
    },
  ];
  const handleRelationChange = (memberId, relation) => {
    setFamilyMemberRelations((prevRelations) => ({
      ...prevRelations,
      [memberId]: relation,
    }));
  };

  const handleFamilyMemberSelection = (data) => {
    setSelectedFamilyMembers((prevSelected) => {
      if (prevSelected.some((member) => member._id === data._id)) {
        return prevSelected.filter((member) => member._id !== data._id);
      } else {
        return [...prevSelected, data];
      }
    });
  };

  const handelCreateFamily = async () => {
    if (!selectedData) {
      notify('danger', 'Error', 'Please select a family head.');
      return;
    }
  
    if (selectedFamilyMembers.length === 0) {
      notify('danger', 'Error', 'Please select at least one family member.');
      return;
    }
  
    const familyData = {
      familyHeadClientId: selectedData._id,  
      familyMembersClientId: selectedFamilyMembers.map((member) => member._id), 
    };
  
    setIsLoading(true);
  
    try {
      const response = await axios.post(`${baseURL}/api/create_family`, familyData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (response.status) {
        notify('success', 'Success', 'Family created successfully!');
      } else if(!response.status) {
        notify('danger', 'Error', `${response.message}`);
      }
      else {
        throw new Error('Failed to create family');
      }
    } catch (error) {
      notify('danger', 'Error', 'Failed to create family. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  


  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <Header />

      <Container className="mt-4">
        {/* Step 1 */}
        <Row className="mb-3">
          <Col>
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              <h5 className="mb-3">
                <span className="badge bg-secondary me-2">Step 1</span>
                Select Family Head
              </h5>
              <Form>
                <Row>
                  <Col lg={4} md={6}>
                    <Form.Select value={activeSearchOptionStep1.label} onChange={(e) => setActiveSearchOptionStep1(searchOptions.find(option => option.label === e.target.value))}>
                      {searchOptions.map((option, index) => (
                        <option key={index} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Control type="text" placeholder={activeSearchOptionStep1.placeholder} value={searchQuery} onChange={handleSearchInput} />
                  </Col>
                </Row>
              </Form>

              {rowData.length > 0 && (
                <div className="ag-theme-alpine mt-3" style={{ width: '100%' }}>
                  <AgGridReact rowData={rowData} columnDefs={columnDefs} domLayout="autoHeight" />
                </div>
              )}
            </div>
          </Col>
        </Row>

        {/* Step 2 */}
        <Row className="mb-3">
          <Col>
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              <h5 className="mb-3">
                <span className="badge bg-secondary me-2">Step 2</span>
                Select Family Member
              </h5>
              <Form>
                <Row>
                  <Col lg={4} md={6}>
                    <Form.Select value={activeSearchOptionStep2.label} onChange={(e) => setActiveSearchOptionStep2(searchOptions.find(option => option.label === e.target.value))}>
                      {searchOptions.map((option, index) => (
                        <option key={index} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Control type="text" placeholder={activeSearchOptionStep2.placeholder} value={searchQueryStep2} onChange={handleSearchInputStep2} />
                  </Col>
                </Row>
              </Form>
             

              {rowDataStep2.length > 0 && (
                <div className="ag-theme-alpine mt-3" style={{ width: '100%' }}>
                  <AgGridReact rowData={rowDataStep2} columnDefs={columnDefsStep2} domLayout="autoHeight" />
                </div>
              )}
               <div class="col-4 mb-3 mt-3 p-0">
                <div class="form-group">
                  <label>Family Label</label>
                  <input type="text" class="form-control" id="familyLabel" name="familyLabel" />
                </div>
              </div>
              <Row className='mt-3'>
                <Col>
                  <div>
                    <h5>Selected Members</h5>
                    <Table striped bordered hover className='w-75'>
                      <thead >
                        <tr>
                          <th className='text-center bg-success text-white'>Sr. No.</th>
                          <th className='text-center bg-success text-white'>Member Name</th>
                          <th className='text-center bg-success text-white'>Member ID</th>
                          <th className='text-center bg-success text-white'>Relation with head</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedFamilyMembers.map((member, index) => (
                          <tr key={member._id}>
                            <td>{index + 1}</td>
                            <td>{`${member.user_details.first_name} ${member.user_details.last_name}`}</td>
                            <td>{member._id}</td>
                            <td>{familyMemberRelations[member._id] || 'Not selected'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <button className='btn btn-success my-2' onClick={handelCreateFamily}>Create Family</button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default CreateFamily;
