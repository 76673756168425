import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CRM from '../pages/CRM';
import Utility from '../pages/Utilities';
import CRMSetting from '../pages/CRMPages/CRMSetting';
import BulkAssigner from '../pages/CRMPages/BulkAssigner';
import ClientAnalysis from '../pages/CRMPages/ClientAnalysis';
import Bouquet from '../pages/CRMPages/BouquetManager'
import AddBouquetVendor from '../pages/CRMPages/AddBouquetVendor'
import EventCalender from '../pages/CRMPages/EventCalender'
import VendorItem from '../pages/CRMPages/VendorItem';
import BouquetEventList from '../pages/CRMPages/BouquetEventList';
import VendorBilling from '../pages/CRMPages/VendorBilling';
import MergeClient from '../pages/UtilitiesPages/MergeClient';
import MergeClientFilter from '../pages/UtilitiesPages/MergeClientFilter';
import FamilyMaster from '../pages/UtilitiesPages/FamilyMaster'
import CreateFamily from '../pages/UtilitiesPages/CreateFamily';
import UpdateFamily from '../pages/UtilitiesPages/UpdateFamily';
import DeleteFamily from '../pages/UtilitiesPages/DeleteFamily';
import FamilyList from '../pages/UtilitiesPages/FamilyList';
import NonFamilyList from '../pages/UtilitiesPages/NonFamilyList';
import FamilyMemberSearch from '../pages/UtilitiesPages/FamilyMemberSearch';
import FamilyMemberSetting from '../pages/UtilitiesPages/FamilyMemberSetting';
import FamilyReletion from '../pages/UtilitiesPages/FamilyReletionShip';
import LoginManagement from '../pages/UtilitiesPages/LoginManagement';
import LoginManager from '../pages/UtilitiesPages/LoginManager';
import ClientMaster from '../pages/UtilitiesPages/ClientMaster';
import AddClient from '../pages/UtilitiesPages/AddClient';
import UpdateClient from '../pages/UtilitiesPages/UpdateClient';
import BirthdayAnniList from '../pages/UtilitiesPages/BirthdayAnniList';
import EmployeeMaster from '../pages/UtilitiesPages/EmployeeMaster'
import AddEmployee from '../pages/UtilitiesPages/AddEmployee'
import UpdateDeleteEmp from '../pages/UtilitiesPages/UpdateDeleteEmp';
import UploadManager from '../pages/UtilitiesPages/UploadManager';
import EmployeeWiseMapping from '../pages/UtilitiesPages/EmployeeMapping';
import EmployeeClientMap from '../pages/UtilitiesPages/EmployeeClientMap';
import ChangeExisting from '../pages/UtilitiesPages/ChangeExisting';
import EmployeeToEmployee from '../pages/UtilitiesPages/EmployeeToEmployeeBulkMap';
import TermAndConditions from '../pages/CRMPages/TermsCondition';
import Aboutus from '../pages/CRMPages/AboutUs';
import DifferClients from '../pages/UtilitiesPages/DifferClient'
import AllMergedClient from '../pages/UtilitiesPages/AllMergedClient';


const UserManager = () => {
  return (
    <>
      <Routes>
        <Route path="/crm/*" element={<CRMRoutes />} />
        <Route path="/utility/*" element={<UtilityRoutes />} />
      </Routes>
    </>
  );
};


function CRMRoutes() {
  return (
    <>
      <Routes>
        <Route path="/crm-dashboard" element={<CRM />} />
        <Route path="/crm-setting" element={<CRMSetting />} />
        <Route path="/bulk-assigner" element={<BulkAssigner />} />
        <Route path="/client-analysis" element={<ClientAnalysis />} />
        <Route path="/event-calender" element={<EventCalender />} />
        <Route path="/bouquet-dashboard" element={<Bouquet />} />
        <Route path="/add-bouquet-vendor" element={<AddBouquetVendor />} />
        <Route path="/vendor-item" element={<VendorItem />} />
        <Route path="/bouquet-event-list" element={<BouquetEventList />} />
        <Route path="/vendor-billing" element={<VendorBilling />} />
        <Route path='/term-and-conditions' element={<TermAndConditions />} />
        <Route path='/about-us' element={<Aboutus />} />
      </Routes>
    </>
  );
}
function UtilityRoutes() {
  return (
    <>
      <Routes>
        <Route path="/utility-dashboard" element={<Utility />} />
        <Route path="/merge-client-dashboard" element={<MergeClient />} />
        <Route path='/merge-client-filter' element={<MergeClientFilter />} />
        <Route path='/differ-clients' element={<DifferClients />} />
        <Route path='/view-all-merged-clients' element={<AllMergedClient />} />
        <Route path='/family-master-dashboard' element={<FamilyMaster />} />
        <Route path='/create-family-filter' element={<CreateFamily />} />
        <Route path='/update-family-filter' element={<UpdateFamily />} />
        <Route path='/delete-family-filter' element={<DeleteFamily />} />
        <Route path='/show-family-list' element={<FamilyList />} />
        <Route path='/non-family-list' element={<NonFamilyList />} />
        <Route path='/family-member-search' element={<FamilyMemberSearch />} />
        <Route path='/family-member-setting' element={<FamilyMemberSetting />} />
        <Route path='/family-relation-manage' element={<FamilyReletion />} />
        <Route path='/login-action' element={<LoginManagement />} />
        <Route path='/login-manager' element={<LoginManager />} />
        <Route path='/client-master-dashboard' element={<ClientMaster />} />
        <Route path='/add-client' element={<AddClient />} />
        <Route path='/update-client' element={<UpdateClient />} />
        <Route path='/birthday-anniversary-list' element={<BirthdayAnniList />} />
        <Route path='/emp-master-dashboard' element={<EmployeeMaster />} />
        <Route path='/add-emp' element={<AddEmployee />} />
        <Route path='/update-del-emp' element={<UpdateDeleteEmp />} />
        <Route path='/upload-manager' element={<UploadManager />} />
        <Route path='/employee-wise-mapping-dashboard' element={<EmployeeWiseMapping />} />
        <Route path='/map-client-to-employee' element={<EmployeeClientMap />} />
        <Route path='/change-existing-employee' element={<ChangeExisting />} />
        <Route path='/employee-to-employee-bulk-mapping' element={<EmployeeToEmployee />} />


      </Routes>
    </>
  );
}


export default UserManager;
