import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CustomButtonComponent from './Button';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../Loader';
import notify from '../../Notification';
import '../../../assets/css/CommonTable.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function VendorList() {
  const baseURL = process.env.REACT_APP_BASEURL;

  const [vendorData, setVendorData] = useState([]);
  const [formData, setFormData] = useState({
    vendorName: '',
    mobileNumber: '',
    emailAddress: '',
    address: ''
  });

const [editFormData, setEditFormData] = useState({
  vendorName: '',
  mobileNumber: '',
  emailAddress: '',
  address: ''
});
  const [showModal, setShowModal] = useState(false);
  const [currentVendor, setCurrentVendor] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
        ...editFormData,
        [name]: value
    });
};

  const validateForm = () => {
    const { vendorName, mobileNumber, emailAddress, address } = formData;
    if (!vendorName || !mobileNumber || !emailAddress || !address) {
      notify('danger', 'Error', 'All fields are required!');
      return false;
    }
    if (!/^\d{10}$/.test(mobileNumber)) {
      notify('danger', 'Error', 'Mobile number must be 10 digits!');
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(emailAddress)) {
      notify('danger', 'Error', 'Please enter a valid email address!');
      return false;
    }
    return true;
  };

  const editValidateForm = () => {
    const { vendorName, mobileNumber, emailAddress, address } = editFormData;
    if (!vendorName || !mobileNumber || !emailAddress || !address) {
      notify('danger', 'Error', 'All fields are required!');
      return false;
    }
    if (!/^\d{10}$/.test(mobileNumber)) {
      notify('danger', 'Error', 'Mobile number must be 10 digits!');
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(emailAddress)) {
      notify('danger', 'Error', 'Please enter a valid email address!');
      return false;
    }
    return true;
  };

  const handleAddVendor = async () => {
    if (!validateForm()) return;
    setIsLoading(true);
  const transformedData = {
    vendor_name: formData.vendorName,
    mobile_number: formData.mobileNumber,
    email_address: formData.emailAddress,
    address: formData.address
  };

    try {
      const response = await axios.post(`${baseURL}/api/add_vendor`, transformedData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.status === true) {
        setVendorData([...vendorData, { ...formData, _id: response.data.vendor._id }]);
        setFormData({ vendorName: '', mobileNumber: '', emailAddress: '', address: '' });
        notify('success', 'Success', 'Vendor added successfully!');
      } else {
        notify('danger', 'Error', 'Failed to add vendor!');
      }
    } catch (error) {
      notify('danger', 'Error', 'Failed to add vendor!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getVendorList = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/api/get_all_vendors`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.data.status === true) {
          setVendorData(response.data.vendors);
        } else {
          notify('danger', 'Error', 'Failed to load vendors!');
        }
      } catch (error) {
        notify('danger', 'Error', 'Failed to load vendors!');
      } finally {
        setIsLoading(false);
      }
    };

    getVendorList();
  }, [baseURL]);

  const handleEditVendor = (vendor) => {
    setCurrentVendor(vendor);
    setEditFormData({
        vendorName: vendor.vendor_name,
        mobileNumber: vendor.mobile_number,
        emailAddress: vendor.email_address,
        address: vendor.address
    });
    setShowModal(true);
};
const handleUpdateVendor = async () => {
  if (!editValidateForm()) return;

  setIsLoading(true);

  try {
      const response = await axios.put(`${baseURL}/api/update_vendor/${currentVendor._id}`, editFormData, {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
          }
      });

      if (response.data.status === true) {
          const updatedVendor = {
              ...currentVendor,
              vendor_name: editFormData.vendorName,
              mobile_number: editFormData.mobileNumber,
              email_address: editFormData.emailAddress,
              address: editFormData.address
          };

          setVendorData(vendorData.map(vendor => 
              vendor._id === currentVendor._id ? updatedVendor : vendor
          ));

          setShowModal(false);
          notify('success', 'Success', 'Vendor updated successfully!');
      } else {
          notify('danger', 'Error', 'Failed to update vendor!');
      }
  } catch (error) {
      notify('danger', 'Error', 'Failed to update vendor!');
  } finally {
      setIsLoading(false);
  }
};



  const handleDeleteVendor = (vendor) => {
    setVendorToDelete(vendor);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(`${baseURL}/api/delete_vendor/${vendorToDelete._id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.data.status === true) {
        setVendorData(vendorData.filter(vendor => vendor._id !== vendorToDelete._id));
        notify('success', 'Success', 'Vendor deleted successfully!');
      } else {
        notify('danger', 'Error', 'Failed to delete vendor!');
      }
    } catch (error) {
      notify('danger', 'Error', 'Failed to delete vendor!');
    } finally {
      setIsLoading(false);
      setShowDeleteModal(false);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const filteredData = vendorData.filter(vendor =>

    (vendor.vendor_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (vendor.mobile_number || '').includes(searchQuery) ||
    (vendor.email_address?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (vendor.address?.toLowerCase() || '').includes(searchQuery.toLowerCase())
  );
  

  const columnDefs = [
    { headerName: 'Sr. No.', field: 'id', resizable: true, width: 80, valueGetter: (params) => vendorData.indexOf(params.data) + 1 },
    { headerName: 'Vendor Name', field: 'vendor_name', sortable: true, filter: true, resizable: true },
    { headerName: 'Mobile Number', field: 'mobile_number', sortable: true, filter: true, resizable: true },
    { headerName: 'Email Id', field: 'email_address', sortable: true, filter: true, resizable: true },
    { headerName: 'Address', field: 'address', sortable: true, filter: true, resizable: true },
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: (params) => (
        <CustomButtonComponent
          data={params.data}
          onEdit={() => handleEditVendor(params.data)}
          onDelete={() => handleDeleteVendor(params.data)}
          onAdd={() => handleAddItemVendor(params.data)}
        />
      ),
    },
  ];
  const handleAddItemVendor = (vendor) => {
    Navigate(`/UserManager/crm/vendor-item?vendor=${vendor._id}`);
  };
 

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="card p-3 mb-4" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
          <div className="row">
            <div className="col-md-4 mt-3">
              <label className="form-label">Vendor Name</label>
              <input
                type="text"
                name="vendorName"
                className="form-control"
                placeholder="Vendor Name"
                value={formData.vendorName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label className="form-label">Mobile No.</label>
              <input
                type="text"
                name="mobileNumber"
                className="form-control"
                placeholder="Mobile Number"
                value={formData.mobileNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label className="form-label">Email Address</label>
              <input
                type="email"
                name="emailAddress"
                className="form-control"
                placeholder="Email Address"
                value={formData.emailAddress}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="form-label">Address</label>
              <input
                type="text"
                name="address"
                className="form-control"
                placeholder="Address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6 mt-3">
              <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                <Button className="btn btn-success" onClick={ handleAddVendor}>
                  Add Vendor
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="card p-3 my-4
        " style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
          <div className="row mb-3">
            <div className="col-md-6">
              <h4>Vendor List</h4>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="ag-theme-alpine " style={{ height: '400px', width: '100%' }}>
            <AgGridReact
              rowData={filteredData}
              columnDefs={columnDefs}
              defaultColDef={{
                sortable: true,
                flex: 1,
                minWidth: 100,
                filter: true,
                resizable: true,
              }}
            />
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <label className="form-label">Vendor Name</label>
              <input
                type="text"
                name="vendorName"
                className="form-control"
                placeholder="Vendor Name"
                value={editFormData.vendorName}
                onChange={handleEditInputChange}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Mobile No.</label>
              <input
                type="text"
                name="mobileNumber"
                className="form-control"
                placeholder="Mobile Number"
                value={editFormData.mobileNumber}
                onChange={handleEditInputChange}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Email Address</label>
              <input
                type="email"
                name="emailAddress"
                className="form-control"
                placeholder="Email Address"
                value={editFormData.emailAddress}
                onChange={handleEditInputChange}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Address</label>
              <input
                type="text"
                name="address"
                className="form-control"
                placeholder="Address"
                value={editFormData.address}
                onChange={handleEditInputChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateVendor}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this vendor?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </>
  );
}
