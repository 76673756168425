import React, { useState } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import '../../../assets/css/AddClient.css';
import { ImCross } from "react-icons/im";

const AddEmployee = () => {
  const [key, setKey] = useState('personal');
  const [maritalStatus, setMaritalStatus] = useState('single');
  const [files, setFiles] = useState([]);

  const handleNext = () => {
    if (key === 'personal') setKey('bank');
    if (key === 'bank') setKey('accessControl');
    if (key === 'accessControl') setKey('document');
  };
  
  const handlePrev = () => {
    if (key === 'bank') setKey('personal');
    if (key === 'accessControl') setKey('bank');
    if (key === 'document') setKey('accessControl');
  };
  const [bankDetails, setBankDetails] = useState([
    {
      bankName: '',
      bankAddress: '',
      accountNumber: '',
      iscCode: '',
    },
  ]);

  const handleBankDetailChange = (index, field, value) => {
    const newBankDetails = [...bankDetails];
    newBankDetails[index][field] = value;
    setBankDetails(newBankDetails);
  };

  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  }

  const handleFileRemove = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };


  return (
    <>
      <Navbar />
      <Header />
      <div className="container my-5">
        <ul className="nav nav-tabs mb-3 gap-md-4 gap-2" id="controlled-tab-example">
          <li className="nav-item tab-items">
            <a
              className={`nav-link  ${key === 'personal' ? 'active' : ''}`}
            >
              <div className='d-flex flex-column'>
                <span className="number">Step 1</span>
                <p className="m-0">Personal Information</p>
              </div>
            </a>
          </li>
          <li className="nav-item tab-items">
            <a
              className={`nav-link  ${key === 'bank' ? 'active' : ''}`}
            >
              <div className='d-flex flex-column'>
                <span className="number">Step 2</span>
                <p className="m-0">  Bank Information</p>
              </div>

            </a>
          </li>
          <li className="nav-item tab-items">
            <a
              className={`nav-link  ${key === 'accessControl' ? 'active' : ''}`}
            >
              <div className='d-flex flex-column'>
                <span className="number">Step 3</span>
                <p className="m-0">Access Control</p>
              </div>
            </a>
          </li>

          <li className="nav-item tab-items">
            <a
              className={`nav-link  ${key === 'document' ? 'active' : ''}`}
            >
              <div className='d-flex flex-column'>
                <span className="number">Step 4</span>
                <p className="m-0">Document Upload</p>
              </div>
            </a>
          </li>
        </ul>
        <div className="tab-content p-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
          {key === 'personal' && (
            <div>
              <strong className='h4'>Personal Information</strong>
              <form>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group  mt-3">
                      <label>First Name</label>
                      <input type="text" className="form-control" placeholder="Enter First name" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Last Name</label>
                      <input type="text" className="form-control" placeholder="Enter Last Name" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Phone Number</label>
                      <input type="text" className="form-control" placeholder="Enter Phone Number" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Mobile Number</label>
                      <input type="text" className="form-control" placeholder="Enter Mobile Number" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Aadhar Number</label>
                      <input type="text" className="form-control" placeholder="Enter Aadhar number" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Address</label>
                      <input type="text" className="form-control" placeholder="Enter Address" />
                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <label>Email</label>
                      <input type="email" className="form-control" placeholder="Enter email" />
                    </div>
                    <div className="form-group mt-3">
                      <label>PAN Number</label>
                      <input type="text" className="form-control" placeholder="Enter PAN Number" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Designation</label>
                      <input type="text" className="form-control" placeholder="Enter Designation" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Date of Joining</label>
                      <input type="date" className="form-control" placeholder="" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Anniversary (If Applicable)</label>
                      <select className="form-select" value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value)}>
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                      </select>
                    </div>
                    {maritalStatus === 'married' && (
                      <div className="form-group mt-3">
                        <label>Anniversary Date</label>
                        <input
                          type="date"
                          className="form-control"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <button type="button" className="btn btn-success mt-3" onClick={handleNext}>
                    Next
                  </button>
                </div>
              </form>
            </div>
          )}
          {key === 'bank' && (
            <div>
              <strong className='h4'>Bank Information</strong>
              <form>
                {bankDetails.map((bankDetail, index) => (
                  <div key={index} className="mb-4 mt-2">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Bank Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={bankDetail.bankName}
                          onChange={(e) => handleBankDetailChange(index, 'bank_name', e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>Bnank Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={bankDetail.bankAddress}
                          onChange={(e) => handleBankDetailChange(index, 'bank_address', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <label>Account Number</label>
                        <input
                          type="text"
                          className="form-control"
                          value={bankDetail.accountNumber}
                          onChange={(e) => handleBankDetailChange(index, 'account_number', e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>IFSC Code</label>
                        <input
                          type="text"
                          className="form-control"
                          value={bankDetail.ifscCode}
                          onChange={(e) => handleBankDetailChange(index, 'ifsc_code', e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="d-flex justify-content-end mt-3">
                  <button type="button" className="btn btn-secondary" onClick={handlePrev}>
                    Previous
                  </button>
                  <button type="button" className="btn btn-success ms-2" onClick={handleNext}>
                    Next
                  </button>
                </div>
              </form>
            </div>
          )}

          {key === 'accessControl' && (
            <div>
              <strong className='h4'>Access Control</strong>
              <form className="mt-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="li" />
                      <label className="form-check-label" htmlFor="li">Enable 'LI' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="fd" />
                      <label className="form-check-label" htmlFor="fd">Enable 'FD' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="mf" />
                      <label className="form-check-label" htmlFor="mf">Enable 'MF' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="commodities" />
                      <label className="form-check-label" htmlFor="commodities">Enable 'Commodities' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="wealthReports" />
                      <label className="form-check-label" htmlFor="wealthReports">Enable 'Wealth Reports' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="utilities" />
                      <label className="form-check-label" htmlFor="utilities">Enable 'Utilities' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="goalGPS" />
                      <label className="form-check-label" htmlFor="goalGPS">Enable 'Goal GPS' Section To This Employee</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="gi" />
                      <label className="form-check-label" htmlFor="gi">Enable 'GI' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="po" />
                      <label className="form-check-label" htmlFor="po">Enable 'PO' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="equity" />
                      <label className="form-check-label" htmlFor="equity">Enable 'Equity' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="realEstate" />
                      <label className="form-check-label" htmlFor="realEstate">Enable 'Real Estate' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="uploads" />
                      <label className="form-check-label" htmlFor="uploads">Enable 'Uploads' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="financialPlanning" />
                      <label className="form-check-label" htmlFor="financialPlanning">Enable 'Financial Planning' Section To This Employee</label>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="investOnline" />
                      <label className="form-check-label" htmlFor="investOnline">Enable 'Invest Online' Section To This Employee</label>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-3">
                  <button type="button" className="btn btn-secondary" onClick={handlePrev}>
                    Previous
                  </button>
                  <button type="button" className="btn btn-success ms-2" onClick={handleNext}>
                    Next
                  </button>
                </div>
              </form>
            </div>
          )}
          {key === 'document' && (
            <div>
              <strong className='h4'>Document Upload</strong>
              <div className="file-upload-container mt-4">
                <div className="file-upload-wrapper">
                  <input
                    type="file"
                    className="file-input"
                    multiple
                    onChange={handleFileUpload}
                  />
                  <span className="file-upload-text">
                    Upload Document<br/>
                    <small className="text-muted">Maximum file size 2MB</small>
                  </span>
                </div>
              </div>
              <div className="uploaded-files mt-4">
                {files.length > 0 && (
                  <ul className="list-group">
                    {files.map((file, index) => (
                      <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                        <span>{file.name}</span>
                        <button
                          className="btn btn-sm"
                          onClick={() => handleFileRemove(file.name)}
                        >
                          <ImCross color='red'/>
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="d-flex justify-content-end mt-3">
                <button type="button" className="btn btn-secondary" onClick={handlePrev}>
                  Previous
                </button>
                <button type="button" className="btn btn-success ms-2" onClick={handleNext}>
                  Finish
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddEmployee;
