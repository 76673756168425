import React from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';

const EmployeeClientMap = () => {
    return (
        <>
            <Navbar />
            <Header />
            <div className="container mt-4">
                <div className="row mb-3">
                    <div className="col">
                        <div className="rounded p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
                            <div className="row">
                                <div className="col-lg-10">
                                    <div className="p-3">
                                        <div class="form-check mb-3">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Employee Mapping Enable
                                            </label>
                                            <i className="fas fa-info-circle ms-2 text-muted"></i>
                                        </div>

                                        <h2 className="h5 fw-bold mb-3">Map Client To Employee</h2>
                                        <div className="d-flex align-items-center mb-3">
                                            <div className="form-check me-4">
                                                <input type="radio" id="searchByClient" name="searchOption" className="form-check-input" defaultChecked />
                                                <label htmlFor="searchByClient" className="form-check-label">Search by Client / PAN</label>
                                            </div>
                                            <div className="form-check">
                                                <input type="radio" id="viewUnmappedClients" name="searchOption" className="form-check-input" />
                                                <label htmlFor="viewUnmappedClients" className="form-check-label">View UnMapped Clients</label>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <input type="text" placeholder="Search client" className="form-control col-4" />
                                            <button className="btn btn-success ms-2">Search Client</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default EmployeeClientMap;
