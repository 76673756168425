import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../Loader';
import notify from '../../Notification';

const CategoryRules = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [profiles, setProfiles] = useState([]);
  let baseURL = process.env.REACT_APP_BASEURL;

  const addCategory = () => {
    setCategories([...categories, { categoryName: '', minAum: '', maxAum: '' }]);
  };

  const removeCategory = async (index) => {
    const categoryId = categories[index]._id;
  
    try {
      const response = await axios.delete(`${baseURL}/api/delete_rule`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        data: { id: categoryId } 
      });
  
      if (response.data.status === true) {
        notify('success', 'Success', response.data.message);
        const newCategories = categories.filter((_, i) => i !== index);
        setCategories(newCategories);
      } else {
        notify('danger', 'Error', 'Failed to remove category!');
      }
    } catch (error) {
      notify('danger', 'Error', 'Failed to remove category!');
    }
  };
  

  const handleCategoryChange = (index, field, value) => {
    const newCategories = categories.map((category, i) =>
      i === index ? { ...category, [field]: value } : category
    );
    setCategories(newCategories);
  };

  const addProfile = () => {
    setProfiles([...profiles, { profileName: '', minAge: '', maxAge: '' }]);
  };

  const removeProfile = async (index) => {
    const profileId = profiles[index]._id;
  
    try {
      const response = await axios.delete(`${baseURL}/api/delete_rule`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        data: { id: profileId } 
      });
  
      if (response.data.status === true) {
        notify('success', 'Success', response.data.message);
        const newProfiles = profiles.filter((_, i) => i !== index);
        setProfiles(newProfiles);
      } else {
        notify('danger', 'Error', 'Failed to remove profile!');
      }
    } catch (error) {
      notify('danger', 'Error', 'Failed to remove profile!');
    }
  };
  

  const handleProfileChange = (index, field, value) => {
    const newProfiles = profiles.map((profile, i) =>
      i === index ? { ...profile, [field]: value } : profile
    );
    setProfiles(newProfiles);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const categoryRules = categories.map(category => ({
      category_name: category.categoryName,
      minimum_aum_range: category.minAum,
      maximum_aum_range: category.maxAum
    }));

    const riskProfileRules = profiles.map(profile => ({
      profile_name: profile.profileName,
      minimum_age_range: profile.minAge,
      maximum_age_range: profile.maxAge
    }));

    const data = {
      categoryRules,
      riskProfileRules
    };

    try {
      const response = await axios.post(`${baseURL}/api/set_crm_setting`, data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setIsLoading(false);
      if (response.data.status === true) {
        notify('success', 'Success', `${response.data.message}`);
      } else {
        notify('danger', 'Error', `Something went wrong!`);
      }
    } catch (error) {
      setIsLoading(false);
      notify('danger', 'Error', 'Failed to save settings!');
    }
  };

  useEffect(() => {
    const getCRMSetting = async () => {
      
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/api/get_crm_setting`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setIsLoading(false);
        const { categoryRuleSchema, riskProfileRuleSchema } = response.data.crm_settings;
        setCategories(categoryRuleSchema.map(item => ({
          _id: item._id,
          categoryName: item.category_name,
          minAum: item.minimum_aum_range,
          maxAum: item.maximum_aum_range
        })));
        setProfiles(riskProfileRuleSchema.map(item => ({
          _id: item._id,
          profileName: item.profile_name,
          minAge: item.minimum_age_range,
          maxAge: item.maximum_age_range
        })));
      } catch (error) {
        setIsLoading(false);
        notify('danger', 'Error', 'Failed to get settings!');
      }
    };
  
    getCRMSetting();
  }, []);
  

  return (
    <>
    {isLoading && <Loader />}
      <Navbar />
      <Header />
      <div className='content-wrapper position-relative mt-3 d-flex flex-column' >
        <div className="container mt-5">
          <h4>Set Categories Rules</h4>
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th>Categories</th>
                <th>AUM Range</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category, index) => (
                <tr key={index}>
                  <td colSpan="3">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Category Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Category Name"
                          value={category.categoryName}
                          onChange={(e) => handleCategoryChange(index, 'categoryName', e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Minimum Aum Range</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Minimum Aum Range"
                          value={category.minAum}
                          onChange={(e) => handleCategoryChange(index, 'minAum', e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Maximum Aum Range</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Maximum Aum Range"
                          value={category.maxAum}
                          onChange={(e) => handleCategoryChange(index, 'maxAum', e.target.value)}
                        />
                        {index >= 2 && (
                          <button className="btn btn-danger mt-2" onClick={() => removeCategory(index)}>
                            Remove
                          </button>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="3">
                  <button className="btn btn-link" onClick={addCategory}>
                    [+] Add New Category
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="mt-4">Set Risk Profile Rules</h4>
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th>Profile Name</th>
                <th>Age Range</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {profiles.map((profile, index) => (
                <tr key={index}>
                  <td colSpan="3">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Profile Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Profile Name"
                          value={profile.profileName}
                          onChange={(e) => handleProfileChange(index, 'profileName', e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Minimum Age Range</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Minimum Age Range"
                          value={profile.minAge}
                          onChange={(e) => handleProfileChange(index, 'minAge', e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Maximum Age Range</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Maximum Age Range"
                          value={profile.maxAge}
                          onChange={(e) => handleProfileChange(index, 'maxAge', e.target.value)}
                        />
                        {index >= 2 && (
                          <button className="btn btn-danger mt-2" onClick={() => removeProfile(index)}>
                            Remove
                          </button>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="3">
                  <button className="btn btn-link" onClick={addProfile}>
                    [+] Add New Profile
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="text-center my-4">
            <button className="btn btn-success" onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default CategoryRules;
