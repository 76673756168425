import React from 'react';
import Button from 'react-bootstrap/Button';

const CustomButtonComponent = ({ onEdit, onDelete, onAdd }) => {
  return (
    <div>
      <Button variant="success" size='sm' onClick={onEdit} className="me-2">
        Edit
      </Button>
      <Button variant="danger" size='sm' onClick={onDelete} className="me-2">
        Delete
      </Button>
    </div>
  );
};

export default CustomButtonComponent;