import React from 'react'
import Navbr from './Navbar'
import '../assets/css/landingPage.css'
import Header from './Header'
import Content from './Content'

const LandingPage = () => {
    return (
        <>
            <div className='main-app-wrapper'>
                <div className='main-wapper'>
                    <Navbr />
                    <Header/>
                    <Content/>
                </div>
            </div>

        </>
    )
}

export default LandingPage
