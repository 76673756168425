import React, { useState, useRef } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import '../../../assets/css/Pages css/MergeClientFilter.css';
import { AgGridReact } from 'ag-grid-react';

import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../../component/Loader';
import notify from '../../Notification';
import CustomButtonComponent from './MergeClientFilterButton';
import axios from 'axios';
import LeaderLine from 'leader-line';

export default function SearchComponent() {
  const [searchBy, setSearchBy] = useState('name');
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null); 
  const [isStepTwoVisible, setIsStepTwoVisible] = useState(false); 

  const [stepTwoSearchQuery, setStepTwoSearchQuery] = useState('');
  const [stepTwoRowData, setStepTwoRowData] = useState([]);
  const debounceTimeoutRef = useRef(null);
  const [isMergePreviewVisible, setIsMergePreviewVisible] = useState(false);
  const [selectedClientsToMerge, setSelectedClientsToMerge] = useState([]);
  const gridRefStepTwo = useRef(null);


  const baseURL = process.env.REACT_APP_BASEURL;

  const mapSearchBy = (searchBy) => {
    switch (searchBy) {
      case 'name':
        return 'first_name';
      case 'email':
        return 'email';
      case 'pan':
        return 'pan_number';
      case 'mobile':
        return 'mobile_number';
      case 'address':
        return 'address';
      default:
        return 'first_name';
    }
  };

  const fetchSearchResults = async (query) => {
    if (query.length > 2) {
      setIsLoading(true);
      try {
        const transformedSearchBy = mapSearchBy(searchBy);
        const response = await axios.post(`${baseURL}/api/search_client_by_type`, {
          searchQuery: query,
          searchBy: transformedSearchBy,
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = response.data;

        if (response.data && response.data) {
          setRowData(response.data);
        } else {
          notify('danger', 'Error', 'No clients found!');
          setRowData([]);
        }
      } catch (error) {
        notify('danger', 'Error', 'Failed to search!');
        setRowData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchStepTwoResults = async (query) => {
    if (query.length > 2) {
      setIsLoading(true);
      try {
        const transformedSearchBy = mapSearchBy(searchBy);
        const response = await axios.post(`${baseURL}/api/search_client_by_type`, {
          searchQuery: query,
          searchBy: transformedSearchBy,
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });

        const data = response.data;

        if (response.data && response.data) {
          setStepTwoRowData(response.data);
        } else {
          notify('danger', 'Error', 'No clients found!');
          setStepTwoRowData([]);
        }
      } catch (error) {
        notify('danger', 'Error', 'Failed to search!');
        setStepTwoRowData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSearchInput = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      fetchSearchResults(query);
    }, 500);
  };

  const handleStepTwoSearchInput = (e) => {
    const query = e.target.value;
    setStepTwoSearchQuery(query);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      fetchStepTwoResults(query);
    }, 500);
  };

  const columnDefs = [
    { headerName: 'Sr. No.', field: 'srNo', valueGetter: 'node.rowIndex + 1', width: 50 },
    { headerName: 'Client Name', field: 'clientName', valueGetter: (params) => `${params.data.user_details.first_name} ${params.data.user_details.last_name}` },
    { headerName: 'Client ID', field: '_id', width: 220 },
    { headerName: 'PAN', field: 'pan_number', width: 150,valueGetter: (params) => `${params.data.user_details.pan_number}` },
    { headerName: 'Mobile', field: 'mobile_number', width: 150, valueGetter: (params) => `${params.data.user_details.mobile_number}` },
    { headerName: 'Email', field: 'email', width: 200, valueGetter: (params) => `${params.data.user_details.email}` },
    { headerName: 'Pincode', field: 'pincode', width: 120, valueGetter: (params) => `${params.data.user_details.pincode}` },
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: (params) => (
        <CustomButtonComponent
          data={params.data}
          mainClient={() => mainClient(params.data)} 
        />
      ),
    },
  ];

  const mainClient = (clientData) => {
    setSelectedClient(clientData);
    setIsStepTwoVisible(true);
    setRowData([]);
  };
  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };  
  const columnDefsStep2 = [
    {
      headerCheckboxSelection: true, 
      checkboxSelection: true, 
      width: 70 
    },
    { headerName: 'Sr. No.', field: 'srNo', valueGetter: 'node.rowIndex + 1', width: 100 },
    { headerName: 'Client Name', field: 'clientName', valueGetter: (params) => `${params.data.user_details.first_name} ${params.data.user_details.last_name}` },
    { headerName: 'Client ID', field: '_id', width: 300 },
    { headerName: 'PAN', field: 'pan_number', width: 150,valueGetter: (params) => `${params.data.user_details.pan_number}` },
    { headerName: 'Mobile', field: 'mobile_number', width: 150, valueGetter: (params) => `${params.data.user_details.mobile_number}` },
    { headerName: 'Email', field: 'email', width: 200, valueGetter: (params) => `${params.data.user_details.email}` },
    { headerName: 'Pincode', field: 'pincode', width: 120, valueGetter: (params) => `${params.data.user_details.pincode}` },
    
  ];

  const handelMergePreview = () => {
    const selectedNodes = gridRefStepTwo.current.api.getSelectedNodes(); 
    const selectedClientData = selectedNodes.map(node => node.data);

    if (selectedClientData.length === 0) {
      notify('danger', 'Error', 'Please select a client to merge!');
    } else {
      setSelectedClientsToMerge(selectedClientData); 
      setIsStepTwoVisible(false); 
      setIsMergePreviewVisible(true); 
    }
  };

  const handelMergeClient = async () => {
    if (!selectedClient || selectedClientsToMerge.length === 0) {
      notify('danger', 'Error', 'Please select clients to merge.');
      return;
    }
  
    const payload = {
      mainClient: selectedClient._id,
      mergedClientList: selectedClientsToMerge.map(client => client._id) 
    };
  
    setIsLoading(true); 
  
    try {
      const response = await axios.post(`${baseURL}/api/merge_client`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      if (response.status) {
        notify('success', 'Success', 'Clients merged successfully!');
        setIsMergePreviewVisible(false); 
        setSelectedClient(null); 
        setSelectedClientsToMerge([]); 
        setStepTwoRowData([])
      } else {
        notify('danger', 'Error', 'Failed to merge clients.');
      }
    } catch (error) {
      notify('danger', 'Error', 'An error occurred while merging clients.');
    } finally {
      setIsLoading(false);
    }
  };
  
  

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <Header />
      <div className='content-wrapper position-relative mt-3 d-flex flex-column' style={{ minHeight: 'calc(100vh - 152px)' }}>
        <div className="container my-4" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
          <div className="p-3 bg-white rounded">
            <Row className="align-items-center">
              <Col xs={12} md={2} className="p-3 d-flex flex-column justify-content-center align-items-center rounded" style={{ backgroundColor: '#C8C8C8' }}>
                <div className="fw-bold text-danger me-2">Step 1</div>
                <div className="step-description fw-bold">Select Main Client</div>
              </Col>

              <Col xs={12} md={10}>
                <Form>
                  <Row className="align-items-center">
                    <Col xs={12} md={4}>
                      <Form.Label className="fw-bold">Search by -</Form.Label>
                      <Form.Select
                        value={searchBy}
                        onChange={(e) => setSearchBy(e.target.value)}
                      >
                        <option value="name">Name</option>
                        <option value="email">Email</option>
                        <option value="pan">PAN No.</option>
                        <option value="mobile">Mobile No.</option>
                        <option value="address">Address</option>
                      </Form.Select>
                    </Col>

                    <Col xs={12} md={6} className="mt-3 mt-md-0">
                      <Form.Label className="fw-bold">Search Client Here</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`Search ${searchBy.charAt(0).toUpperCase() + searchBy.slice(1)} Here...`}
                        value={searchQuery}
                        onChange={handleSearchInput}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>

     
          {selectedClient && (
            <div className="bg-light p-3 mt-3 rounded">
              <div className="fw-bold text-dark">
                You have selected this person as main client for merging.
              </div>
              <div className="bg-info d-flex text-white p-3 mt-2 rounded">
                <div className='mx-4'><p className='m-0'>Name:</p> <strong>{selectedClient.user_details.first_name} {selectedClient.user_details.last_name} </strong></div>
                <div className='mx-4'><p className='m-0'> Client ID:</p> <strong>{selectedClient._id}</strong></div>
                <div className='mx-4'> <p className='m-0'>PAN:</p> <strong>{selectedClient.user_details.pan_number}</strong></div>
                <div className='mx-4'> <p className='m-0'>Mobile:</p> <strong>{selectedClient.user_details.mobile_number}</strong></div>
              </div>
            </div>
          )}

          <div className="mt-3">
            
            {rowData.length > 0 && (
              <>
                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                  <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    domLayout="autoHeight"
                  />
                </div>
              </>
            )}
          </div>

          
          {isStepTwoVisible && (
            <div className="bg-white rounded mt-4 p-4">
              <Row className="align-items-center">
                <Col xs={12} md={2} className="p-3 d-flex flex-column justify-content-center align-items-center rounded" style={{ backgroundColor: '#C8C8C8' }}>
                  <div className="fw-bold text-danger me-2">Step 2</div>
                  <div className="step-description fw-bold">Select Client to Merge</div>
                </Col>
                
                <Col xs={12} md={10}>
                  <Form>
                    <Row className="align-items-center">
                      <Col xs={12} md={4}>
                        <Form.Label className="fw-bold">Search by -</Form.Label>
                        <Form.Select
                          value={searchBy}
                          onChange={(e) => setSearchBy(e.target.value)}
                        >
                          <option value="name">Name</option>
                          <option value="email">Email</option>
                          <option value="pan">PAN No.</option>
                          <option value="mobile">Mobile No.</option>
                          <option value="address">Address</option>
                        </Form.Select>
                      </Col>

                      <Col xs={12} md={6} className="mt-3 mt-md-0">
                        <Form.Label className="fw-bold">Search Client Here</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={`Search ${searchBy.charAt(0).toUpperCase() + searchBy.slice(1)} Here...`}
                          value={stepTwoSearchQuery}
                          onChange={handleStepTwoSearchInput}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
                 
              </Row>
            </div>
         )}

          <div className="mt-3">
            
            {stepTwoRowData.length > 0 && (
              <>
                <div className="ag-theme-alpine" style={{  width: '100%' }}>
                  <AgGridReact
                   ref={gridRefStepTwo}
                    rowData={stepTwoRowData}
                    columnDefs={columnDefsStep2}
                    defaultColDef={defaultColDef}
                    domLayout="autoHeight"
                    rowSelection="multiple"
                  />
                </div>
                <button className='btn btn-success my-2' onClick={handelMergePreview}>Merge Preview</button>
              </>
            )}
          </div>
          {isMergePreviewVisible && (
          <div className="bg-white rounded mt-4 p-4">
              <Row className="align-items-center">
                <Col xs={12} md={2} className="p-3 d-flex flex-column justify-content-center align-items-center rounded" style={{ backgroundColor: '#C8C8C8' }}>
                  <div className="fw-bold text-danger me-2">Step 3</div>
                  <div className="step-description fw-bold">Select Client to Merge</div>
                </Col>
              
           
              <div className="bg-white rounded mt-4 p-4">
                <Row>
                  <Col xs={12} md={8}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Client Name</th>
                          <th>Client ID</th>
                          <th>PAN</th>
                          <th>Login</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedClientsToMerge.map((client, index) => (
                          <tr key={index}>
                            <td>{client.user_details.first_name} {client.user_details.last_name}</td>
                            <td>{client._id}</td>
                            <td>{client.user_details.pan_number || 'Without PAN'}</td>
                            <td>No</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                  <Col xs={12} md={1}></Col>
                  <Col xs={12} md={3}>
                    <h5>Main Client for Merging</h5>
                    <div className="p-3 border">
                      <p><strong>Name:</strong> {selectedClient.user_details.first_name} {selectedClient.user_details.last_name}</p>
                      <p><strong>Client ID:</strong> {selectedClient._id}</p>
                      <p><strong>PAN:</strong> {selectedClient.user_details.pan_number}</p>
                      <p><strong>Mobile:</strong> {selectedClient.user_details.mobile_number}</p>
                    </div>
                  </Col>
                </Row>
                <button className='btn btn-danger my-2' onClick={() => setIsMergePreviewVisible(false)}>Go Back</button>
                <button className='btn btn-success my-2 mx-2' onClick={handelMergeClient}>Merge Clients</button>
              </div>
           
            </Row>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
