import React from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

const FamilyList = () => {
  return (
    <>
      <Navbar />
      <Header />
    </>
  );
}

export default FamilyList;
