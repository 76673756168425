import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';

const SearchPanel = () => {
  const [viewBy, setViewBy] = useState('');
  const [searchType, setSearchType] = useState('client');
  const [clientSearch, setClientSearch] = useState('');
  const [option, setOption] = useState('Incomplete Records');
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  let baseURL = process.env.REACT_APP_BASEURL;

  const handleViewByChange = (e) => {
    setViewBy(e.target.value);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleClientSearchChange = (e) => {
    setClientSearch(e.target.value);
  };

  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };

  const handleSubmit = () => {
    console.log({ viewBy, searchType, clientSearch, option });
  };

  useEffect(() => {
    const fetchResults = async () => {
      if (clientSearch.length > 2) {
        try {
          const response = await axios.get(`${baseURL}/api/search_client?q=${clientSearch}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });

          // Assuming the data structure is in response.data
          setSearchResults(response.data); 
          setShowDropdown(true);
        } catch (error) {
          console.error('Error fetching search results', error);
        }
      } else {
        setShowDropdown(false);
      }
    };

    const debounceFetchResults = setTimeout(() => {
      fetchResults();
    }, 300);

    return () => clearTimeout(debounceFetchResults);
  }, [clientSearch, searchType, baseURL]);

  const handleResultClick = (result) => {
    const fullName = `${result.user_details.first_name} ${result.user_details.last_name}`;
    setClientSearch(fullName);
    setShowDropdown(false);
  };

  return (
    <>
      <Navbar />
      <Header />
      <div className='content-wrapper position-relative mt-3 d-flex flex-column' style={{ minHeight: 'calc(100vh - 152px)' }}>
        <div className="container mt-4">
          <div className="card p-3">
            <div className="row align-items-center">
              <div className="col-md-3">
                <label>Select View By</label>
                <select className="form-select" value={viewBy} onChange={handleViewByChange}>
                  <option>Select</option>
                  <option value="client">Client</option>
                  <option value="family">Family</option>
                </select>
              </div>
              <div className="col-md-6">
                <div className="mb-2 d-flex">
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      value="client"
                      checked={searchType === 'client'}
                      onChange={handleSearchTypeChange}
                    />
                    <label className="form-check-label">Search Client / PAN</label>
                  </div>
                  <div className="form-check mx-2">
                    <input
                      type="radio"
                      className="form-check-input"
                      value="all"
                      checked={searchType === 'all'}
                      onChange={handleSearchTypeChange}
                    />
                    <label className="form-check-label">All Client</label>
                  </div>
                </div>
                {searchType === 'client' && (
                  <div>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Search client"
                      value={clientSearch}
                      onChange={handleClientSearchChange}
                    />
                    {showDropdown && searchResults.length > 0 && (
                      <ul className="dropdown-menu show mx-3" style={{ width: '90%', maxHeight: '200px', overflowY: 'auto' }}>
                        {searchResults.map((result, index) => (
                          <li
                            key={index}
                            className="dropdown-item"
                            onClick={() => handleResultClick(result)}
                          >
                           {`${result.user_details.first_name} ${result.user_details.last_name} [${result._id}]`}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label>Select Option</label>
                <select className="form-select" value={option} onChange={handleOptionChange}>
                  <option value="Incomplete Records">Incomplete Records</option>
                  <option value="Complete Records">Complete Records</option>
                  <option value="Pending Records">Pending Records</option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-1">
                <button className="btn btn-success w-100" onClick={handleSubmit}>
                  Show
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer sticlyFooter={true} />
      </div>
    </>
  );
};

export default SearchPanel;
