import React from 'react'
import { Routes, Route } from 'react-router-dom';
import LifeInsurance from '../pages/Insurance/LifeInsurance';
import GeneralInsurance from '../pages/Insurance/GeneralInsurance';
import EnterNewPolicy from '../pages/Insurance/LifeInsurancePage/EnterNewPolicy'
import DueList from '../pages/Insurance/LifeInsurancePage/DueList'
import PayPremium from '../pages/Insurance/LifeInsurancePage/PayPremium'
import UpdatePolicy from '../pages/Insurance/LifeInsurancePage/UpdatePlicy'
import PolicyTransaction from '../pages/Insurance/LifeInsurancePage/PolicyTransiction'
import PolicyHolder from '../pages/Insurance/LifeInsurancePage/PolicyHolders'
import PolicySurrender from '../pages/Insurance/LifeInsurancePage/PolicySurrender'
import AddBuikPolicy from '../pages/Insurance/LifeInsurancePage/BulkPolicy'
import NewGIPolicy from '../pages/Insurance/GeneralInsurancePages/EnterNewPolicyG'
import UpdateGiPolicy from '../pages/Insurance/GeneralInsurancePages/UpdatePolicyG'
import ViewGiPolicy from '../pages/Insurance/GeneralInsurancePages/DueListG'
import RenewGIPolicy from '../pages/Insurance/GeneralInsurancePages/RenewPolicyG'
import GIHolders from '../pages/Insurance/GeneralInsurancePages/GIHolders'
import BulkGIPolicy from '../pages/Insurance/GeneralInsurancePages/BulkPolicyG'


const Insurance = () => {
  return (
    <>
     <Routes>
        <Route path="/li/*" element={<LifeRoutes />} />
        <Route path="/general/*" element={<GeneralRoutes />} />
      </Routes>
    </>
  )
}

function LifeRoutes() {
  return (
    <>
      <Routes>
        <Route path="/li-dashboard" element={<LifeInsurance />} />
        <Route path="/new-li-policy" element={<EnterNewPolicy/>} />
        <Route path="/due-list" element={<DueList />} />
        <Route path="/pay-premium" element={<PayPremium />} />
        <Route path="/update-policy" element={<UpdatePolicy />} />
        <Route path="/policy-transaction" element={<PolicyTransaction />} />
        <Route path="/policy-holders" element={<PolicyHolder />} />
        <Route path="/policy-surrender" element={<PolicySurrender />} />
        <Route path="/add-bulk-policy" element={<AddBuikPolicy />} />

      </Routes>
    </>
  )
}
function GeneralRoutes() {
  return (
    <>
      <Routes>
        <Route path="/general-dashboard" element={<GeneralInsurance />} />
        <Route path="/new-gi-policy" element={<NewGIPolicy />} />
        <Route path="/update-delete-gi-policy" element={<UpdateGiPolicy />} />
        <Route path="/view-update-gi-due" element={<ViewGiPolicy />} />
        <Route path="/renew-gi-policy" element={<RenewGIPolicy />} />
        <Route path="/gi-holder" element={<GIHolders />} />
        <Route path="/update-bulk-gi-policy" element={<BulkGIPolicy />} />

      </Routes>
    </>
  )
}

export default Insurance