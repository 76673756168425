import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../../component/Loader';
import notify from '../../Notification';

const TermsCondition = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [termsText, setTermsText] = useState('');
    const baseURL = process.env.REACT_APP_BASEURL;

 
    useEffect(() => {
        const getTermsConditions = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${baseURL}/api/get_terms_and_conditions`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (response.data.status === true) {
                    setTermsText(response.data.data.terms_and_conditions_content);
                } else {
                    notify('danger', 'Error', 'Failed to load Terms & Conditions content!');
                }
            } catch (error) {
                notify('danger', 'Error', 'Failed to load Terms & Conditions content!');
            } finally {
                setIsLoading(false);
            }
        };
        getTermsConditions();
    }, [baseURL]);

    const handleSubmit = async () => {
        if (termsText.trim() === '') {
            notify('danger', 'Error', 'Terms & Conditions content cannot be empty!');
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post(
                `${baseURL}/api/terms_and_conditions`,
                { terms_and_conditions_content: termsText },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );

            if (response.data.status === true) {
                notify('success', 'Success', 'Terms & Conditions updated successfully!');
            } else {
                notify('danger', 'Error', 'Failed to update Terms & Conditions content!');
            }
        } catch (error) {
            notify('danger', 'Error', 'Error updating Terms & Conditions content!');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <Navbar />
            <Header />
            <div className="container mt-4">
                <div className="row mb-3">
                    <div className="col">
                        <div
                            className="rounded p-lg-4 p-3 my-3"
                            style={{
                                boxShadow:
                                    'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                            }}
                        >
                            <div className="form-group clearfix">
                                <label>Terms & Conditions</label>
                                <textarea
                                    rows="5"
                                    cols="50"
                                    name="description"
                                    id="description"
                                    className="form-control h-auto"
                                    style={{ height: '200px' }}
                                    value={termsText}
                                    onChange={(e) => setTermsText(e.target.value)}
                                ></textarea>
                                <span className="pull-right f-12" id="charNum"></span>
                            </div>
                            <button className="btn btn-success" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default TermsCondition;
