import React, { useState } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import { Container, Row, Col, Button, ButtonGroup, ToggleButton, Form, Nav } from 'react-bootstrap';

const LoginManager = () => {
  const [selectedAction, setSelectedAction] = useState('create');
  const [viewBy, setViewBy] = useState('client');

  return (
    <>
      <Navbar />
      <Header />

      <Container className="mt-4">
        <Row className='mb-3'>
          <Col>
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              
              {/* Action Buttons */}
              <div className="mb-3">
                <ButtonGroup>
                  <Button
                    variant={selectedAction === 'create' ? 'dark' : 'light'}
                    onClick={() => setSelectedAction('create')}
                  >
                    Create Login
                  </Button>
                  <Button
                    variant={selectedAction === 'update' ? 'dark' : 'light'}
                    onClick={() => setSelectedAction('update')}
                  >
                    Update Login
                  </Button>
                  <Button
                    variant={selectedAction === 'delete' ? 'dark' : 'light'}
                    onClick={() => setSelectedAction('delete')}
                  >
                    Delete Login
                  </Button>
                </ButtonGroup>
              </div>

              {/* View By Section */}
              <div className="mb-3">
                <Nav fill variant="tabs" defaultActiveKey="client">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="client"
                      active={viewBy === 'client'}
                      onClick={() => setViewBy('client')}
                    >
                      Client
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="employee"
                      active={viewBy === 'employee'}
                      onClick={() => setViewBy('employee')}
                    >
                      Employee
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="family-head"
                      active={viewBy === 'family-head'}
                      onClick={() => setViewBy('family-head')}
                    >
                      Family Head
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              {/* Search Client Input */}
              <Form.Group controlId="searchClient">
                <Form.Label>Search Client</Form.Label>
                <Form.Control type="text" placeholder="Enter client name or ID" />
              </Form.Group>

            </div>
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

export default LoginManager;
