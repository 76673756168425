import React, { useState } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

const BirthdayAnniList = () => {
    const [selectedOption, setSelectedOption] = useState('birthday');
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
      };
  return (
    
    <>
      <Navbar />
      <Header />
      <Container className="mt-4">
        <Row className='mb-3'>
          <Col>
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
            <Form>
                <Form.Group controlId="familyHead">
                  <Row className='mb-3'>
                  
                    <Col md={6} sm={12}>
                    <Form.Label>Search Client</Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Label>Generate List For</Form.Label>
                      <div key={`default-radio`} className="mb-3 d-flex">
                        <Form.Check 
                          type='radio'
                          id='birthday-radio'
                          label='Birthday'
                          name='eventType'
                          value='birthday'
                          checked={selectedOption === 'birthday'}
                          onChange={handleOptionChange}
                          className='mx-3'
                        />
                        <Form.Check 
                          type='radio'
                          id='anniversary-radio'
                          label='Anniversary'
                          name='eventType'
                          value='anniversary'
                          checked={selectedOption === 'anniversary'}
                          onChange={handleOptionChange}
                          className='mx-3'
                        />
                        <Form.Check 
                          type='radio'
                          id='both-radio'
                          label='Both'
                          name='eventType'
                          value='both'
                          checked={selectedOption === 'both'}
                          onChange={handleOptionChange}
                          className='mx-3'
                        />
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
              <button className='btn btn-success'>Show</button>
            </div>
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

export default BirthdayAnniList;
