import React, { useState } from 'react';
import { FaGlobe, FaFileExcel } from 'react-icons/fa';
import Navbar from '../../../Navbar';
import Header from '../../../Header';
import Footer from '../../../Footer';

const PolicyTransiction = () => {
  const [selectedOutput, setSelectedOutput] = useState('web');

  const handleOutputSelect = (outputType) => {
    setSelectedOutput(outputType);
  };


  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div
              className="rounded p-lg-4 p-3 my-3"
              style={{
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              }}
            >
              <form>
                <div className="row align-items-center">
                  <div className='col-md-4'>
                  <label className="me-2">Select Client</label>
                  <select class="form-select">
                    <option selected>Select</option>
                  </select>
                  </div>
                  <div className='col-md-4'>
                  <label className="me-2">Select Policy</label>
                  <select class="form-select">
                    <option selected>Select</option>
                  </select>
                  </div>
                  <div className="col-md-2 d-flex flex-column">
                    <label className="me-2">Output In</label>
                    <div className="btn-group" role="group">
                      <button
                        type="button"
                        className={`btn ${selectedOutput === 'web' ? 'btn-info' : 'btn-light'}`}
                        onClick={() => handleOutputSelect('web')}
                      >
                        <FaGlobe size={20} />
                      </button>
                      <button
                        type="button"
                        className={`btn ${selectedOutput === 'excel' ? 'btn-info' : 'btn-light'}`}
                        onClick={() => handleOutputSelect('excel')}
                      >
                        <FaFileExcel size={20} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2">
                    <button type="submit" className="btn btn-success">
                      Show
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PolicyTransiction;
