
import React, { useState } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import { AgGridReact } from 'ag-grid-react';
import { FaRegTrashCan } from "react-icons/fa6";

const UpdateManager = () => {
    const [rowData] = useState([
        { name: 'abc', clientDesk: 'Yes', advisorDesk: 'Yes', additionDate: '2023-05-20 17:48:15' },
        { name: 'dddff', clientDesk: 'Yes', advisorDesk: 'Yes', additionDate: '2023-05-20 17:49:42' },
        { name: 'fff', clientDesk: 'Yes', advisorDesk: 'Yes', additionDate: '2023-05-20 17:52:31' },
        { name: 'Test', clientDesk: 'Yes', advisorDesk: 'Yes', additionDate: '2023-05-20 17:54:40' },
        { name: 'equity', clientDesk: 'Yes', advisorDesk: 'Yes', additionDate: '2024-02-09 12:00:10' },
        { name: 'abc', clientDesk: 'Yes', advisorDesk: 'Yes', additionDate: '2023-05-20 17:48:15' },
        { name: 'dddff', clientDesk: 'Yes', advisorDesk: 'Yes', additionDate: '2023-05-20 17:49:42' },
        { name: 'fff', clientDesk: 'Yes', advisorDesk: 'Yes', additionDate: '2023-05-20 17:52:31' },
        { name: 'Test', clientDesk: 'Yes', advisorDesk: 'Yes', additionDate: '2023-05-20 17:54:40' },
        { name: 'equity', clientDesk: 'Yes', advisorDesk: 'Yes', additionDate: '2024-02-09 12:00:10' },
      ]);
    
      const columnDefs =([
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'clientDesk', headerName: 'Client Desk', flex: 1 },
        { field: 'advisorDesk', headerName: 'Advisor Desk', flex: 1 },
        { field: 'additionDate', headerName: 'Addition date', flex: 1 },
        {
          headerName: 'Action',
          cellRenderer: () => (
            <button className="btn text-danger p-0">
             <FaRegTrashCan />
            </button>
          ),
        },
      ]);
  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
            <div className="card p-4">
                <h5 className="card-title mb-4">Upload files (will be available in Application form section)</h5>
                <div className="card-body p-0">
                    <form>
                    <div className="row mb-3">
                        <div className="col-md-4 mb-3 mb-md-0">
                        <label htmlFor="fileName" className="form-label">
                            File Name<span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="fileName"
                            placeholder="Full Name"
                        />
                        </div>
                        <div className="col-md-4 mb-3 mb-md-0">
                        <label htmlFor="whoCanDownload" className="form-label">
                            Who can download<span className="text-danger">*</span>
                        </label>
                        <select className="form-select" id="whoCanDownload">
                        <option selected>None</option>
                        <option value="Clients">Clients</option>
                        <option value="Advisors">Advisors</option>
                        <option value="Both">Both Client &amp; Advisor</option>
                        </select>
                        </div>
                        <div className="col-md-4">
                        <label htmlFor="section" className="form-label">
                            Section<span className="text-danger">*</span>
                        </label>
                         <select className="form-select" id="section">
                            <option value="GI">GI (General Insurance)</option>
                            <option value="PO">PO (Post Office)</option>
                            <option value="LI">LI (Life Insurance)</option>
                            <option value="FD">FD (Fixed Deposit)</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                    </div>
                    <div className="mb-3 col-md-6 p-0">
                        <label htmlFor="fileUpload" className="form-label">
                        Select file to upload<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                        <input
                            type="file"
                            className="form-control"
                            id="fileUpload"
                        />
                        </div>
                        <p className="text-danger small">
                        PDF, DOC, RAR, ZIP, XLS file format supported. Maximum file size 15MB
                       </p>
                    </div>
                    <button type="submit" className="btn btn-success">Upload</button>
                   
                    </form>
                </div>
                </div>
            </div>

            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
                <div className='d-flex justify-content-between'>
                <h5>File Details</h5>
                <select class="form-select col-3" id="section" name="section">
                    <option selected>Select Section</option>
                    <option value="GI">GI (General Insurance)</option>
                    <option value="PO">PO (Post Office)</option>
                    <option value="LI">LI (Life Insurance)</option>
                    <option value="FD">FD (Fixed Deposit)</option>
                    <option value="Others">Others</option>
                </select>
                </div>
                <div className="ag-theme-alpine my-3" style={{ height: "400px", width: '100%' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout='390px'
                        headerHeight={48}
                        rowHeight={48}
                    />
                    </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default UpdateManager;
