import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../../component/Loader'
import { useLocation } from 'react-router-dom';
import notify from '../../Notification';
import CustomButtonComponent from './VendorItemButton';
import { Modal, Button } from 'react-bootstrap'



const VendorItem = () => {
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [itemName, setItemName] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showTable, setShowTable] = useState(false);  
  const [vendorItem, setVendorItem] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);  
  const [editItemData, setEditItemData] = useState({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const location = useLocation();
  const baseURL = process.env.REACT_APP_BASEURL;

  //{ id: 1, item_name: 'item 1', item_price: '45' },

  const columnDefs = [
    { headerName: 'Sr. No.', field: 'id', resizable: true, width: "80px" },
    { headerName: 'Item Name', field: 'item_name', sortable: true, filter: true, resizable: true },
    { headerName: 'Item Price', field: 'item_price', sortable: true, filter: true, resizable: true },
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: (params) => (
        <CustomButtonComponent
          data={params.data}
          onEdit={() => handleEditVendorItems(params.data)}
          onDelete={() => handleDeleteVendorItems(params.data)}
        />
      ),
    },
  ];

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = (vendorItem || []).filter(item =>
    item.item_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.item_price.includes(searchQuery)
  );

  useEffect(() => {
    const fetchVendors = async () => {
      setLoading(true);
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/api/get_all_vendors`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });

        setLoading(false);
        setIsLoading(false);
        setVendors(response.data.vendors);
        console.log('Vendors:', response.data);
      } catch (error) {
        setIsLoading(false);
        setLoading(false);
        console.error('Error fetching vendors:', error);
      }
    };

    fetchVendors();
  }, [baseURL]);

  const handleAddItem = async () => {
    if (!selectedVendor) {
      notify('danger', 'Error', 'Please select a vendor');
      return;
    }
    if (!itemName) {
      notify('danger', 'Error', 'Please enter an item name');
      return;
    }
    if (!itemPrice) {
      notify('danger', 'Error', 'Please enter an item price');
      return;
    }

    try {
      setLoading(true);
      const selectedVendorData = vendors.find(vendor => vendor._id === selectedVendor);

      const newItem = {
        vendor_id: selectedVendor,
        vendor_name: selectedVendorData.vendor_name,
        item_name: itemName,
        item_price: itemPrice
      };

      const response = await axios.post(`${baseURL}/api/add_item_to_vendor`, newItem, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.data.status === true) {
        setItemName('');
        setItemPrice('');
        setSelectedVendor('');
        setLoading(false);
        notify('success', 'Success', 'Item added successfully');
        fetchVendorItems(selectedVendor);
        setLoading(false);
      } else {
        setLoading(false);
        notify('danger', 'Error', 'Failed to add item');
      }
    } catch (error) {
      setLoading(false);
      notify('danger', 'Error', 'Error adding item');
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const vendorIdFromUrl = queryParams.get('vendor');

    if (vendorIdFromUrl) {
      setSelectedVendor(vendorIdFromUrl);
    }
  }, [location.search, vendors]);

  const handleEditVendorItems = (item) => {
    console.log('Edit item:', item);
    setEditItemData(item)
    setEditModalVisible(true); 
  };
  const handleDeleteVendorItems = (item) => {
    setItemToDelete(item);
    setDeleteModalVisible(true);
  };

  const fetchVendorItems = async (vendorId) => {
    if (!vendorId) return;

    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/api/${vendorId}/get_all_items`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });

      setLoading(false);
      const items = response.data.data;
        const formattedData = items.map((item, index) => ({
          id: index + 1,
          item_name: item.item_name, 
          item_price: item.item_price,
          real_id: item._id, 
        }));
  
        setVendorItem(formattedData);
        setShowTable(true); 
    } catch (error) {
      setLoading(false);
      console.error('Error fetching vendor items:', error);
      notify('danger', 'Error', 'Error fetching vendor items');
    }
  };
  const handleVendorChange = (e) => {
    const vendorId = e.target.value;
    setSelectedVendor(vendorId);
    fetchVendorItems(vendorId);
  };


  const handleUpdateItem = async () => {
    if (!editItemData.item_name || !editItemData.item_price) {
      notify('danger', 'Error', 'Please fill in all fields');
      return;
    }
    try {
      setLoading(true);
      const response = await axios.put(`${baseURL}/api/update_item/${editItemData.real_id}`, {
        item_name: editItemData.item_name,
        item_price: editItemData.item_price
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });

      if (response.data.status === true) {
        notify('success', 'Success', 'Item updated successfully');
        fetchVendorItems(selectedVendor); 
        setEditModalVisible(false);  
      } else {
        notify('danger', 'Error', 'Failed to update item');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error updating item:', error);
      notify('danger', 'Error', 'Error updating item');
    }
  };

  const handleConfirmDelete = async () => {
    if (!itemToDelete) return;

    try {
      setLoading(true);
      const response = await axios.delete(`${baseURL}/api/delete_item/${itemToDelete.real_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.status === true) {
        notify('success', 'Success', 'Item deleted successfully');
        fetchVendorItems(selectedVendor); 
      } else {
        notify('danger', 'Error', 'Failed to delete item');
      }
      setLoading(false);
      setDeleteModalVisible(false);
      setItemToDelete(null); 
    } catch (error) {
      setLoading(false);
      notify('danger', 'Error', 'Error deleting item');
      console.error('Error deleting item:', error);
      setDeleteModalVisible(false);
    }
  };
  return (
    <>
      {Loading && <Loader />}
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="card p-3 mb-4" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
          <h6>Add Item of Vendor</h6>
          <div className="row">
            <div className="col-md-4 mt-3">
              <label className="form-label">Select Vendor Name</label>
              {isLoading ? (
                <p>Loading vendors...</p>
              ) : (
                <select
                  className="form-select"
                  value={selectedVendor}
                  // onChange={(e) => setSelectedVendor(e.target.value)}
                  onChange={handleVendorChange}
                >
                  <option value="" disabled selected>Select Vendor</option>
                  {vendors.map((vendor) => (
                    <option key={vendor._id} value={vendor._id}>
                      {vendor.vendor_name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <label className="form-label">Item Name</label>
              <input
                type="text"
                name="Item Name"
                className="form-control"
                placeholder="Item Name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label className="form-label">Item Price</label>
              <input
                type="text"
                name="Item Price"
                className="form-control"
                placeholder="Item Price"
                value={itemPrice}
                onChange={(e) => setItemPrice(e.target.value)}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3'>
              <button className="btn btn-success mt-3" onClick={handleAddItem}>
                Add Item
              </button>
            </div>
          </div>
        </div>
        {showTable && (  
          <div className="px-4 py-4 my-4" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
            <h4>Vendor List</h4>
            <div className="row">
              <div className='col-md-3 mb-2'>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search vendors..."
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="ag-theme-alpine " style={{ height: 'auto', width: '100%' }}>
              <AgGridReact
                rowData={filteredData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={7}
                domLayout="autoHeight"
                defaultColDef={{
                  flex: 1,
                  minWidth: 100,
                  resizable: true,
                }}
              />
            </div>
          </div>
        )}
       
      </div>
      <Footer />

      <Modal show={editModalVisible} onHide={() => setEditModalVisible(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label className="form-label">Vendor Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={vendors.find(vendor => vendor._id === selectedVendor)?.vendor_name || ''}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Item Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={editItemData.item_name || ''}
                  onChange={(e) => setEditItemData({ ...editItemData, item_name: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Item Price</label>
                <input
                  type="text"
                  className="form-control"
                  value={editItemData.item_price || ''}
                  onChange={(e) => setEditItemData({ ...editItemData, item_price: e.target.value })}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setEditModalVisible(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUpdateItem}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={deleteModalVisible} onHide={() => setDeleteModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModalVisible(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VendorItem;
