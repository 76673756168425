import React from 'react';
import Button from 'react-bootstrap/Button';

const CustomButtonComponent = ({ mainClient }) => {
  return (
    <div>
      <Button variant="primary" size='sm' onClick={mainClient} className="me-2">
      Main Client
      </Button>
    </div>
  );
};

export default CustomButtonComponent;
