import React from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';

const UpdateClient = () => {
  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              <form>
                <div className="mb-3">
                  <label htmlFor="familyHead" className="form-label">Search Client</label>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <input type="text" className="form-control" id="familyHead" placeholder="" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default UpdateClient;
