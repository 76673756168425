import React from 'react';
import '../assets/css/header.css';
import { Link, NavLink, useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);

    return (
        <>
            <div id='headerTagContainer' className='main-navigation'>
                <div className='navbar navbar-expand-lg navbar-light' id='redNav' style={{ overflow: 'inherit', backgroundColor: 'white' }}>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#rednavbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon text-dark"></span>
                    </button>
                    <div id='rednavbarResponsive' className='collapse navbar-collapse px-3 text-center justify-content-center'>
                        <ul id="sliderNav" className="navbar-nav nav-wrap">
                            <li className="nav-item collapsible-li-item d-none">
                                <span className="nav-link collapsible-menu-openner collapsible-menu-tigger">
                                    <i className="fa fa-bars" aria-hidden="true"></i>
                                </span>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    id="dashboard__NavItem"
                                    className={({ isActive }) => isActive ? 'nav-link show' : 'nav-link'}
                                    to="/dashboard"
                                >
                                    <i className="fa-solid fa-grip px-1"></i>
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    id="crm__NavItem"
                                    className={({ isActive }) => isActive ? 'nav-link show' : 'nav-link'}
                                    to="/UserManager/crm/crm-dashboard"
                                    replace
                                >
                                    <i className="fa-solid fa-grip px-1"></i>
                                    CRM
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink id="utilities__NavItem" 
                               className={({ isActive }) => isActive ? 'nav-link show' : 'nav-link'}
                                to="/UserManager/utility/utility-dashboard">
                                    <i className="fa-solid fa-wrench px-1"></i>
                                    Utilities
                                </NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink id="Insurance__NavItem"
                                     className={pathnames.includes('Insurance') ? 'nav-link dropdown-toggle show' : 'nav-link dropdown-toggle'} to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-access="true">
                                    <i className="fa-solid fa-newspaper px-1"></i>
                                    <span>Insurance</span>
                                </NavLink>
                                <div className="dropdown-menu li-dropdown-menu" aria-labelledby="Insurance__NavItem">
                                    <NavLink className="dropdown-item" to="/Insurance/li/li-dashboard" data-access="true">
                                        <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>Life Insurance</span>
                                    </NavLink>
                                    <NavLink className="dropdown-item" to="/Insurance/general/general-dashboard" data-access="true">
                                        <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>General Insurance</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink id="Assets__NavItem" 
                                className={pathnames.includes('Assets') ? 'nav-link dropdown-toggle show' : 'nav-link dropdown-toggle'} to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-access="true">
                                    <i className="fa-solid fa-newspaper px-1"></i>
                                    <span>Assets</span>
                                </NavLink>
                                <div className="dropdown-menu li-dropdown-menu" aria-labelledby="Assets__NavItem">
                                    <NavLink className="dropdown-item" to="/Assets/pms/pms-dashboard" data-access="true">
                                        <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>PMS and Alt Invst</span>
                                    </NavLink>
                                    <NavLink className="dropdown-item" to="/Assets/fd/fd-bond-dashboard" data-access="true">
                                    <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>FD/Bonds</span>
                                    </NavLink>
                                    <NavLink className="dropdown-item" to="/Assets/postal/postal-dashboard" data-access="true">
                                    <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>Postal</span>
                                    </NavLink>
                                    <NavLink className="dropdown-item" to="/Assets/equity/equity-dashboard" data-access="true">
                                    <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>Equity</span>
                                    </NavLink>
                                    <NavLink className="dropdown-item" to="#" data-access="true">
                                    <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>Commodity</span>
                                    </NavLink>
                                    <NavLink className="dropdown-item" to="#" data-access="true">
                                    <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>Real Estate</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li className="nav-item">
                                <NavLink id="dashboard__NavItem" className={({ isActive }) => isActive ? 'nav-link show' : 'nav-link'} to="/MutualFund/mutual/dashboard">
                                    <i className="fa-solid fa-chart-line px-1"></i>
                                    Mutual
                                </NavLink>
                            </li>
                            <li  className="nav-item">
                                <a id="dashboard__NavItem" className="nav-link" href="#">
                                    <i className="fa-regular fa-file-lines px-1"></i>
                                    CAS
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a id="Research__NavItem" className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-access="true">
                                    <i className="fa-brands fa-searchengin px-1"></i>
                                    <span>Research</span>
                                </a>
                                <div className="dropdown-menu li-dropdown-menu" aria-labelledby="Research__NavItem">
                                    <a className="dropdown-item" href="#" data-access="true">
                                        <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>MF Research</span>
                                    </a>
                                    <a className="dropdown-item" href="#" data-access="true">
                                        <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>MF Tools</span>
                                    </a>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a id="dashboard__NavItem" className="nav-link" href="#">
                                    <i className="fa-solid fa-sack-dollar px-1"></i>
                                    Transact Online
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="dashboard__NavItem" className="nav-link" href="#">
                                    <i className="fa-solid fa-bullseye px-1"></i>
                                    Goal GPS
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="dashboard__NavItem" className="nav-link" href="#">
                                    <i className="fa-solid fa-coins px-1"></i>
                                    Financial Planning
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="dashboard__NavItem" className="nav-link" href="#">
                                    <i className="fa-solid fa-chart-pie px-1"></i>
                                    Wealth Report
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a id="Other__NavItem" className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-access="true">
                                    <i className="fa-solid fa-ellipsis-vertical px-1"></i>
                                    <span>Other</span>
                                </a>
                                <div className="dropdown-menu li-dropdown-menu" aria-labelledby="Other__NavItem">
                                    <a className="dropdown-item" href="#" data-access="true">
                                        <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>MF Research</span>
                                    </a>
                                    <a className="dropdown-item" href="#" data-access="true">
                                        <i className="fa-solid fa-angle-right pr-3"></i>
                                        <span>MF Tools</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className="content-wrapper breadcrumb-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-4 col-md-4 pr-2">
                                {/* <div className="mr-auto top-pagination nc-pagination">
                                    <ol className="breadcrumb" id="pageBreakcrum">
                                        <li className="breadcrumb-item">
                                            <NavLink to="/">Dashboard</NavLink>
                                        </li>
                                        {pathnames.map((value, index) => {
                                            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                                            const isLast = index === pathnames.length - 1;
                                            return isLast ? (
                                                <li className="breadcrumb-item active" key={to}>
                                                    {value}
                                                </li>
                                            ) : (
                                                <li className="breadcrumb-item" key={to}>
                                                    <NavLink to={to}>{value}</NavLink>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </div>*/}
                            </div>
                            <div className="col-12 col-lg-4 col-md-4 text-center text-danger d-flex justify-content-center flex-column">
                                <p className="wel-desk f-11 mb-0" style={{ lineHeight: "14px" }}>
                                    Free mail service has ended, your emails are at risk. To ensure
                                    your emails get through, either set up SMTP (Simple Mail
                                    Transfer Protocol) for sending emails or consider upgrading to a
                                    paid mail service.
                                    <a href="/UserSetting/settings/smtp-config" target="_blank">
                                        Click Here
                                    </a>
                                </p>
                            </div>
                            <div
                                id="bc-r-ele"
                                className="col-12 col-lg-4 col-md-4 text-right nc-desk-title pl-2 mt-2 mt-md-0 d-flex justify-content-center flex-column"
                            >
                                <ol className="breadcrumb" id="">
                                    <li className="breadcrumb-item w-100 text-right d-flex flex-column">
                                        <p className="wel-desk">
                                            Welcome RedVision Technologies (Distributor(MFD))
                                        </p>
                                        <span>AMFI-Registered Mutual Fund Distributor</span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
