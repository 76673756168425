import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip, Table, Button, Modal } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import Loader from '../../../component/Loader';
import notify from '../../Notification';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';

const searchOptions = [
  { label: 'Name', placeholder: 'Search by Name' },
  { label: 'Email', placeholder: 'Search by Email' },
  { label: 'PAN No.', placeholder: 'Search by PAN No.' },
  { label: 'Mobile No.', placeholder: 'Search by Mobile No.' },
  { label: 'Address', placeholder: 'Search by Address' },
];


const UpdateFamily = () => {
  const baseURL = process.env.REACT_APP_BASEURL;

  const [isLoading, setIsLoading] = useState(false);
  const [clientSearch, setClientSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [familyHead, setFamilyHead] = useState(null);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [AddNewMembers, setAddNewMembers] = useState(false);
  const [activeSearchOptionStep2, setActiveSearchOptionStep2] = useState(searchOptions[0]);
  const [searchQueryStep2, setSearchQueryStep2] = useState('');
  const [rowDataStep2, setRowDataStep2] = useState([]);
  const debounceTimeoutRefStep2 = useRef(null);
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([]);
  const [familyMemberRelations, setFamilyMemberRelations] = useState({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteMember, setDeleteMember] = useState(null);




  const mapSearchByStep2 = (activeSearchOptionStep2) => {
    switch (activeSearchOptionStep2.label) {
      case 'Name':
        return 'first_name';
      case 'Email':
        return 'email';
      case 'PAN No.':
        return 'pan_number';
      case 'Mobile No.':
        return 'mobile_number';
      case 'Address':
        return 'address';
      default:
        return 'first_name';
    }
  };



  useEffect(() => {
    const fetchResults = async () => {
      if (clientSearch.length > 2) {
        setIsLoading(true);
        try {
          const response = await axios.get(`${baseURL}/api/search_client?q=${clientSearch}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          setSearchResults(response.data);
          setShowDropdown(true);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false)
          console.error('Error fetching search results', error);
          notify('danger', 'Error', 'Failed to load search results');
        }
      } else {
        setIsLoading(false);
        setShowDropdown(false);
      }
    };

    const debounceFetchResults = setTimeout(() => {
      fetchResults();
    }, 300);

    return () => clearTimeout(debounceFetchResults);
  }, [clientSearch, baseURL]);

  const handleResultClick = (result) => {
    setClientSearch(`${result.user_details.first_name} ${result.user_details.last_name}`);
    setSelectedClientId(result._id);
    setFamilyHead(result);
    setShowDropdown(false);
    fetchFamilyMembers(result._id);
  };

  const fetchFamilyMembers = async (clientId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseURL}/api/get_family_members_by_client/${clientId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setFamilyMembers(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching family members', error);
      notify('danger', 'Error', 'Failed to load family members');
      setIsLoading(false);
    }
  };

  const columnDefs = [
    { headerName: 'Sr. No.', field: 'srNo', width: 110 },
    { headerName: 'Family Head/Members', field: 'name', width: 300, cellStyle: params => params.data.isHead ? { fontWeight: 'bold', color: 'green' } : {} },
    { headerName: 'Client ID', field: 'clientId', width: 200 },
    { headerName: 'PAN', field: 'pan', width: 200 },
    { headerName: 'Mobile No', field: 'mobile', width: 150 },
    { headerName: 'Relation', field: 'relation', width: 160 },
    {
      headerName: 'Action',
      field: 'actions',
      width: 170,
      cellRenderer: (params) => {
        if (params.data.isHead) {
          return null;
        }
        return (
          <div>
            <button
              className='btn btn-danger btn-sm'
              onClick={() => SetDataDeleteMember(params.data)}
              type='button'
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const SetDataDeleteMember = (data) => {
    setDeleteModalVisible(true);
    setDeleteMember(data);
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${baseURL}/api/delete_family_member`, {
        headMemberId: selectedClientId,
        deleteMemberId: deleteMember.clientId,
      },{
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (response.data.status) {
        notify('success', 'Success', `Family member has been successfully deleted!`);
        setFamilyMembers((prevMembers) => prevMembers.filter((member) => member._id !== deleteMember.clientId));
        setDeleteModalVisible(false);
      } else {
        notify('danger', 'Error', 'Failed to delete the family member!');
      }
    } catch (error) {
      console.error('Error deleting family member', error);
      notify('danger', 'Error', 'Failed to delete the family member!');
    } finally {
      setIsLoading(false);
    }
  };
  

  const rowData = familyHead ? [
    {
      srNo: 1,
      name: `${familyHead.user_details.first_name} ${familyHead.user_details.last_name}`,
      clientId: familyHead._id,
      pan: familyHead.user_details.pan_number,
      mobile: familyHead.user_details.mobile_number,
      relation: 'Family Head',
      isHead: true
    },
    ...familyMembers.map((member, index) => ({
      srNo: index + 2,
      name: `${member.user_details.first_name} ${member.user_details.last_name}`,
      clientId: member._id,
      pan: member.user_details.pan_number,
      mobile: member.user_details.mobile_number,
      relation: member.relation_ship_with_head || 'N/A',
      isHead: false
    }))
  ] : [];

  const fetchSearchResultsStep2 = async (query) => {
    if (query.length > 2) {
      setIsLoading(true);
      try {
        const transformedSearchBy = mapSearchByStep2(activeSearchOptionStep2);
        const response = await axios.post(`${baseURL}/api/search_client_by_type`, {
          searchQuery: query,
          searchBy: transformedSearchBy,
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        const data = response.data;

        if (data && data.length) {
          setRowDataStep2(data);
        } else {
          notify('danger', 'Error', 'No family members found!');
          setRowDataStep2([]);
        }
      } catch (error) {
        notify('danger', 'Error', 'Failed to search!');
        setRowDataStep2([]);
      } finally {
        setIsLoading(false);
      }
    }
  };


  const handleSearchInputStep2 = (e) => {
    const query = e.target.value;
    setSearchQueryStep2(query);

    if (debounceTimeoutRefStep2.current) {
      clearTimeout(debounceTimeoutRefStep2.current);
    }

    debounceTimeoutRefStep2.current = setTimeout(() => {
      fetchSearchResultsStep2(query);
    }, 500);
  };
  const columnDefsStep2 = [
    { headerName: 'Sr. No.', field: 'srNo', valueGetter: 'node.rowIndex + 1', width: 50 },
    {
      headerName: 'Select Member',
      field: 'actions',
      width: 130,
      cellRenderer: (params) => (
        <div>
          <input
            type="checkbox"
            checked={selectedFamilyMembers.some((member) => member._id === params.data._id)}
            onChange={() => handleFamilyMemberSelection(params.data)}
          />
        </div>
      ),
    },
    { headerName: 'Family Member Name', field: 'memberName', valueGetter: (params) => `${params.data.user_details.first_name} ${params.data.user_details.last_name}` },
    { headerName: 'Family Member ID', field: '_id', width: 220 },
    { headerName: 'Mobile', field: 'mobile_number', width: 150, valueGetter: (params) => params.data.user_details.mobile_number },
    { headerName: 'Email', field: 'email', width: 240, valueGetter: (params) => params.data.user_details.email },
    {
      headerName: 'Relation with Head',
      field: 'relation',
      width: 250,
      cellRenderer: (params) => (
        <div>
          <select
            value={familyMemberRelations[params.data._id] || ''}
            onChange={(e) => handleRelationChange(params.data._id, e.target.value)}
          >
            <option value="">Select</option>
            <option value="father">Father</option>
            <option value="mother">Mother</option>
            <option value="brother">Brother</option>
            <option value="sister">Sister</option>
            <option value="son">Son</option>
            <option value="daughter">Daughter</option>
            <option value="grand_son">Grand Son</option>
            <option value="grand_daughter">Grand Daughter</option>
            <option value="spouse">Spouse</option>
            <option value="self">Self</option>
            <option value="other">Other</option>
          </select>
        </div>
      ),
    },
  ];
  const handleRelationChange = (memberId, relation) => {
    setFamilyMemberRelations((prevRelations) => ({
      ...prevRelations,
      [memberId]: relation,
    }));
  };
  const handleFamilyMemberSelection = (data) => {
    setSelectedFamilyMembers((prevSelected) => {
      if (prevSelected.some((member) => member._id === data._id)) {
        return prevSelected.filter((member) => member._id !== data._id);
      } else {
        return [...prevSelected, data];
      }
    });
  };
  const handelCreateFamily = async () => {
  
    if (selectedFamilyMembers.length === 0) {
      notify('danger', 'Error', 'Please select at least one family member.');
      return;
    }
  
    const familyData = {
      headClientId:selectedClientId,  
      clientIdList: selectedFamilyMembers.map((member) => member._id), 
    };
  
    setIsLoading(true);
  
    try {
      const response = await axios.post(`${baseURL}/api/update_family`, familyData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      })
  
      if (response.data.status) {
        notify('success', 'Success', `${response.data.data}`);
      }else if(!response.data.status){
        notify('danger', 'Error', `${response.data.error}`);
      } else {
        throw new Error('Failed to create family');
      }
    } catch (error) {
      notify('danger', 'Error', 'Failed to create family. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <Header />

      <Container className="mt-4">
        <Row className="mb-3">
          <Col>
            <div
              className="rounded p-lg-4 p-3 my-3"
              style={{
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              }}
            >
              <h5 className="mb-3 d-flex align-items-center">
                <span className="badge bg-secondary me-2">Step 1</span>
                Select Family Head
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Info about this step</Tooltip>}
                >
                  <span className="ms-auto">
                    <FaInfoCircle color="blue" />
                  </span>
                </OverlayTrigger>
              </h5>
              <Form>
                <Form.Group controlId="familyHead">
                  <Form.Label>Family Head:</Form.Label>
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Control
                        type="text"
                        placeholder="Search client"
                        value={clientSearch}
                        onChange={(e) => setClientSearch(e.target.value)}
                      />
                      {showDropdown && searchResults.length > 0 && (
                        <ul
                          className="dropdown-menu show mx-3"
                          style={{ width: '90%', maxHeight: '200px', overflowY: 'auto' }}
                        >
                          {searchResults.map((result, index) => (
                            <li
                              key={index}
                              className="dropdown-item"
                              onClick={() => handleResultClick(result)}
                            >
                              {`${result.user_details.first_name} ${result.user_details.last_name} [${result._id}] [${result.user_details.pan_number}]`}
                            </li>
                          ))}
                        </ul>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>

        {familyHead && (
          <Row>
            <Col md={12} sm={12}>
              <div
                className="ag-theme-alpine my-4"
                style={{ width: '100%' }}

              >
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={rowData}
                  defaultColDef={{ resizable: true, sortable: true, }}
                  domLayout="autoHeight"
                />
              </div>
            </Col>

            <Col>
              <div><strong>Do you want to add new members <Link to='#' onClick={() => setAddNewMembers(true)} className='text-danger'>Clink here</Link></strong></div>
            </Col>
          </Row>
        )}
        {AddNewMembers && (
          <>
            <Row className="mb-3">
              <Col>
                <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
                  <h5 className="mb-3">
                    <span className="badge bg-secondary me-2">Step 2</span>
                    Select Family Member
                  </h5>
                  <Form>
                    <Row>
                      <Col lg={4} md={6}>
                        <Form.Select value={activeSearchOptionStep2.label} onChange={(e) => setActiveSearchOptionStep2(searchOptions.find(option => option.label === e.target.value))}>
                          {searchOptions.map((option, index) => (
                            <option key={index} value={option.label}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Control type="text" placeholder={activeSearchOptionStep2.placeholder} value={searchQueryStep2} onChange={handleSearchInputStep2} />
                      </Col>
                    </Row>
                  </Form>
                  {rowDataStep2.length > 0 && (
                    <div className="ag-theme-alpine mt-3" style={{ width: '100%' }}>
                      <AgGridReact rowData={rowDataStep2} columnDefs={columnDefsStep2} domLayout="autoHeight" />
                    </div>
                  )}
                  <div class="col-4 mb-3 mt-3 p-0">
                    <div class="form-group">
                      <label>Family Label</label>
                      <input type="text" class="form-control" id="familyLabel" name="familyLabel" />
                    </div>
                  </div>
                  <Row className='mt-3'>
                    <Col>
                      <div>
                        <h5>Selected Members</h5>
                        <Table striped bordered hover className='w-75'>
                          <thead >
                            <tr>
                              <th className='text-center bg-success text-white'>Sr. No.</th>
                              <th className='text-center bg-success text-white'>Member Name</th>
                              <th className='text-center bg-success text-white'>Member ID</th>
                              <th className='text-center bg-success text-white'>Relation with head</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedFamilyMembers.map((member, index) => (
                              <tr key={member._id}>
                                <td>{index + 1}</td>
                                <td>{`${member.user_details.first_name} ${member.user_details.last_name}`}</td>
                                <td>{member._id}</td>
                                <td>{familyMemberRelations[member._id] || 'Not selected'}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <button className='btn btn-success my-2' onClick={handelCreateFamily}>Update Family</button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        )}
        <Footer />
      </Container>

      <Modal show={deleteModalVisible} onHide={() => setDeleteModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModalVisible(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateFamily;
