import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import '../../../assets/css/Calender.css'


const HolidayCalendar = () => {
  const holidays = [
    { title: 'New Year\'s Day', date: '2024-01-01' },
    { title: 'Independence Day', date: '2024-08-15' },
    { title: 'Christmas Day', date: '2024-12-25' },
    // Add more holidays here
  ];

  return (
    <>
    <Navbar/>
    <Header/>
    <div className='mt-3'>
      <div style={{ width: '60%', margin: '0 auto' }}> 
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={holidays}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'today'
          }}
        />
      </div>
    </div>
    </>
  );
};

export default HolidayCalendar;
