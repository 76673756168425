import React, { useState, useEffect } from 'react';
import { MdEmail } from "react-icons/md";
import { FaGlobe, FaWhatsapp, FaPrint } from 'react-icons/fa';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../../component/Loader';
import notify from '../../Notification';
import axios from 'axios';
import { FaFilePdf, FaFileExcel } from 'react-icons/fa6';
import { AgGridReact } from 'ag-grid-react';




const PandLValuation = () => {
  const baseURL = process.env.REACT_APP_BASEURL;

  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState('2024-09-17');
  const [viewBy, setViewBy] = useState('');
  const [clientSearch, setClientSearch] = useState('');
  const [reportType, setReportType] = useState('liveFunds');
  const [viewFunds, setViewFunds] = useState('allFunds');
  const [showSummary, setShowSummary] = useState(false);
  const [showGraphs, setShowGraphs] = useState(false);
  const [showWhatIfScenario, setShowWhatIfScenario] = useState(false);
  const [viewPL, setViewPL] = useState({ fundTypeWise: false, folioWise: false });
  const [selectedOutput, setSelectedOutput] = useState('web');
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedClientData, setSelectedClientData] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [rowData, setRowData] = useState([]);






  const handleOutputSelect = (outputType) => {
    setSelectedOutput(outputType);
  };
  useEffect(() => {
    const fetchResults = async () => {
      if (clientSearch.length > 2) {
        setIsLoading(true);
        try {
          const response = await axios.get(`${baseURL}/api/search_client?q=${clientSearch}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          setSearchResults(response.data);
          setShowDropdown(true);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false)
          notify('danger', 'Error', 'Failed to load search results');
        }
      } else {
        setIsLoading(false);
        setShowDropdown(false);
      }
    };

    const debounceFetchResults = setTimeout(() => {
      fetchResults();
    }, 300);

    return () => clearTimeout(debounceFetchResults);
  }, [clientSearch, baseURL]);

  const handleResultClick = (result) => {
    setClientSearch(`${result.user_details.first_name} ${result.user_details.last_name}`);
    setSelectedClientId(result._id);
    setSelectedClientData(result.user_details);
    setShowDropdown(false);
  };

  const get_mutual_fund_details = async () => {
    if (clientSearch.length > 2) {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/api/get_mutual_fund_details/${selectedClientId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setRowData(response.data.investments);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false)
        notify('danger', 'Error', 'Failed to load search results');
      }
    } else {
      setIsLoading(false);
    }
  };

  const currentDate = new Date().toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });




  const columnDefs = [
    {
      headerName: "Scheme",
      field: "scheme_name",
      cellRenderer: (params) => (
        <span 
          style={{ cursor: 'pointer', color: 'blue' }} 
          onClick={()=>handleShowTransaction(params.data._id)}
        >
          {params.value}
        </span>
      )
    },
    { headerName: "Scheme Type", field: "scheme_type" },
    { headerName: "Scheme Sub-Type", field: "scheme_sub_type" },
    { headerName: "Folio Number", field: "folio_number" },
    { headerName: "Investment Since", field: "inv_since", valueFormatter: (params) => new Date(params.value).toLocaleDateString() },
    { headerName: "Purchase", field: "purchase" },
    { headerName: "Redemption", field: "redemption" },
    { headerName: "Current Value", field: "cur_value" },
    { headerName: "Current Units", field: "cur_units" },
    { headerName: "Current NAV", field: "cur_nav" },
    { headerName: "Gain/Loss", field: "gain_loss" },
    { headerName: "Absolute Return", field: "abs_rtn" },
    { headerName: "XIRR", field: "xirr" }
  ];

  const handelShowData = (e) => {
    e.preventDefault()

    if (!viewBy) {
      notify('danger', 'Error', 'Please select an option to view by');
      return;
    }
    if (!selectedClientId) {
      notify('danger', 'Error', 'Please select client')
      return
    }
    if (viewBy === 'client') {
      get_mutual_fund_details();
      // get_client_investment_fund_list()
    }

    setShowDetails(true)
  };

  const handleShowTransaction = async(data) => {

  }

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-lg-4 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              <div className="container mt-4">
                <div className="row">
                  <div className="col-12">
                    <div className="card border-0">
                      <div className="card-body">
                        <div className="row g-3">
                          <div className="col-md-4">
                            <label className="form-label">P&L As On</label>
                            <div className="input-group">
                              <input
                                type="date"
                                className="form-control"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">Select View By</label>
                            <select
                              className="form-select"
                              value={viewBy}
                              onChange={(e) => setViewBy(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="client">Client</option>
                              <option value="family">Family</option>
                            </select>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">Search By Name / PAN / Folio No.</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search client"
                              value={clientSearch}
                              onChange={(e) => setClientSearch(e.target.value)}
                            />
                            {showDropdown && searchResults.length > 0 && (
                              <ul
                                className="dropdown-menu show mx-2 border-bottom"
                                style={{ width: '90%', maxHeight: '200px', overflowY: 'auto' }}
                              >
                                {searchResults.map((result, index) => (
                                  <li
                                    key={index}
                                    className="dropdown-item"
                                    onClick={() => handleResultClick(result)}
                                  >
                                    {`${result.user_details.first_name} ${result.user_details.last_name} [${result._id}]`}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-md-4">
                            <label className="form-label">Report Type</label>
                            <div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="reportType"
                                  id="allFunds"
                                  value="allFunds"
                                  checked={reportType === 'allFunds'}
                                  onChange={() => setReportType('allFunds')}
                                />
                                <label className="form-check-label" htmlFor="allFunds">All Funds</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="reportType"
                                  id="liveFunds"
                                  value="liveFunds"
                                  checked={reportType === 'liveFunds'}
                                  onChange={() => setReportType('liveFunds')}
                                />
                                <label className="form-check-label" htmlFor="liveFunds">Live Funds</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="reportType"
                                  id="realisedFunds"
                                  value="realisedFunds"
                                  checked={reportType === 'realisedFunds'}
                                  onChange={() => setReportType('realisedFunds')}
                                />
                                <label className="form-check-label" htmlFor="realisedFunds">Realised Funds</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">View Funds</label>
                            <div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="viewFunds"
                                  id="allFundsView"
                                  value="allFunds"
                                  checked={viewFunds === 'allFunds'}
                                  onChange={() => setViewFunds('allFunds')}
                                />
                                <label className="form-check-label" htmlFor="allFundsView">All Funds</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="viewFunds"
                                  id="selectedFunds"
                                  value="selectedFunds"
                                  checked={viewFunds === 'selectedFunds'}
                                  onChange={() => setViewFunds('selectedFunds')}
                                />
                                <label className="form-check-label" htmlFor="selectedFunds">Selected Funds</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">View P&L</label>
                            <div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="fundTypeWise"
                                  checked={viewPL.fundTypeWise}
                                  onChange={() => setViewPL(prev => ({ ...prev, fundTypeWise: !prev.fundTypeWise }))}
                                />
                                <label className="form-check-label" htmlFor="fundTypeWise">Fund Type Wise</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="folioWise"
                                  checked={viewPL.folioWise}
                                  onChange={() => setViewPL(prev => ({ ...prev, folioWise: !prev.folioWise }))}
                                />
                                <label className="form-check-label" htmlFor="folioWise">Folio Wise</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-md-4">
                            <label className="form-label">Show P&L Report With</label>
                            <div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="summary"
                                  checked={showSummary}
                                  onChange={() => setShowSummary(!showSummary)}
                                />
                                <label className="form-check-label" htmlFor="summary">Summary</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="graphs"
                                  checked={showGraphs}
                                  onChange={() => setShowGraphs(!showGraphs)}
                                />
                                <label className="form-check-label" htmlFor="graphs">Graphs</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">What If Scenario :</label>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="whatIfScenario"
                                checked={showWhatIfScenario}
                                onChange={() => setShowWhatIfScenario(!showWhatIfScenario)}
                              />
                              <label className="form-check-label" htmlFor="whatIfScenario">What If Scenario</label>
                            </div>
                          </div>
                          <div className="col-md-4 d-flex flex-column">
                            <label className="form-label">Out Put In</label>
                            <div className="btn-group" role="group">
                              <button
                                type="button"
                                className={`btn ${selectedOutput === 'web' ? 'btn-info' : 'btn-light'} px-4`}
                                onClick={() => handleOutputSelect('web')}
                              >
                                <FaGlobe size={20} />
                              </button>
                              <button
                                type="button"
                                className={`btn ${selectedOutput === 'mail' ? 'btn-info' : 'btn-light'}  px-4`}
                                onClick={() => handleOutputSelect('mail')}
                              >
                                <MdEmail size={20} />
                              </button>
                              <button
                                type="button"
                                className={`btn ${selectedOutput === 'whatsApp' ? 'btn-info' : 'btn-light'}  px-4`}
                                onClick={() => handleOutputSelect('whatsApp')}
                              >
                                <FaWhatsapp size={20} />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col">
                            <button className="btn btn-success" onClick={handelShowData}>Show</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showDetails && (
              <>
                <div
                  className="rounded p-lg-4 p-3 my-3"
                  style={{
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                  }}
                >
                  <div className="container-fluid mb-4">
                    <div className="row">
                      <div className="col-md-4">
                        <p className="mb-0"><strong>Client Name :</strong></p>
                        <p className="mb-0">{`${selectedClientData.first_name} ${selectedClientData.last_name}`}</p>
                        <p className="mb-0 text-muted small">{`[PAN: ${selectedClientData.pan_number}]`}</p>
                        <p className="mb-0 text-muted small">{`[Email: ${selectedClientData.email}]`}</p>
                        <p className="mb-0 text-muted small">{`[DOB: ${selectedClientData.date_of_birth}]`}</p>
                      </div>
                      <div className="col-md-4">
                        <p className="mb-0"><strong>Address :</strong></p>
                        <p className="mb-0">76 INDRALOK COLONY SUDAMANAGAR NEAR</p>
                        <p className="mb-0">ASHIRWAD BHAWANINDOREINDORE452009</p>
                        <p className="mb-0 mt-2"><strong>Contact No. :</strong></p>
                        <p className="mb-0">{selectedClientData.mobile_number}</p>
                      </div>
                      <div className="col-md-4">
                        <p className="mb-0"><strong>Current Sensex :</strong></p>
                        <p className="mb-0 text-success fw-bold">83462.61 ↑ 514.38 (0.62%)</p>
                        <p className="mb-0 mt-2"><strong>Export In:</strong></p>
                        <div className="btn-group mt-1" role="group" aria-label="Export options">
                          <button type="button" className="btn btn-outline-primary btn-sm"><FaPrint /></button>
                          <button type="button" className="btn btn-outline-danger btn-sm"><FaFilePdf /></button>
                          <button type="button" className="btn btn-outline-success btn-sm"><FaFileExcel /></button>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <p className="mb-0"><strong>Valuation Report as on Date</strong></p>
                        <p className="mb-0">{currentDate}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ag-theme-alpine my-4"
                    style={{ width: '100%' }}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={rowData}
                      domLayout="autoHeight"
                    />
                  </div>
                </div>

              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default PandLValuation;