import React from 'react'
import Navbar from '../Navbar'
import Header from '../Header'
import Footer from '../Footer'
import '../../assets/css/Pages css/Utilities.css'

import mergeClient from '../../assets/Images/Utility/mergeClient.png'
import familyMaster from '../../assets/Images/Utility/familyMaster.png'
import loginManagement from '../../assets/Images/Utility/loginManagement.png'

import clientMaster from '../../assets/Images/Utility/clientMaster.png'
import employeeMaster from '../../assets/Images/Utility/employeeMaster.png'
import uploadMaster from '../../assets/Images/Utility/uploadMaster.png'
import employeeMeeting from '../../assets/Images/Utility/employeeMaking.png'

import targetBased from '../../assets/Images/Utility/targetAlerts.png'
import scheduleBased from '../../assets/Images/Utility/scheduleAlerts.png'
import clienDesk from '../../assets/Images/Utility/clientDesk.png'
import outGoing from '../../assets/Images/Utility/outGoing.png'
import ClientList from '../../assets/Images/Utility/clientList.png'
import { Link } from 'react-router-dom'




const Utilities = () => {
    const cardData = [
        {
            title: "Step 1",
            icon: mergeClient,
            content: "Merge Client",
            link: '/UserManager/utility/merge-client-dashboard'
        },
        {
            title: "Step 2",
            icon: familyMaster,
            content: "Family Master",
            link:'/UserManager/utility/family-master-dashboard'
        },
        {
            title: "Step 3",
            icon: loginManagement,
            content: "Login Management",
            link: '/UserManager/utility/login-action'
        }
    ];

    const cardData2 =[
        {
            title: "Client",
            icon: clientMaster,
            content: "Master",
            link:'/UserManager/utility/client-master-dashboard'
        },
        {
            title: "Employee",
            icon: employeeMaster,
            content: "Master",
            link:'/UserManager/utility/emp-master-dashboard'
        },
        {
            title: "Upload",
            icon: uploadMaster,
            content: "Master",
            link:'/UserManager/utility/upload-manager'
        },
        {
            title: "Employee Wise",
            icon: employeeMeeting,
            content: "Mapping",
            link:'/UserManager/utility/employee-wise-mapping-dashboard'
        }
    ]

    const cardData3 = [
        {
            title: "Target Based",
            icon: targetBased,
            content: "Alerts"
        },
        {
            title: "Schedule",
            icon: scheduleBased,
            content: "Alerts"
        },
        {
            title: "Client Desk",
            icon: clienDesk,
            content: "Pop Up"
        },
        {
            title: "Out Going",
            icon: outGoing,
            content: "Communication"
        },
        {
            title: "Client List",
            icon: ClientList,
        }
    ]
    return (
        <>
            <Navbar />
            <Header />
            <div className='content-wrapper position-relative'>
                <div id='changesWidth' className='container'>
                    <div id='main-content-wrapper' className='container-fluid'>
                        <div className='categories-heading'>
                            <div className='category-container'>
                                <div className='category-title'>
                                Client Login Creation Steps
                                </div>
                                <span class="category-line"></span>
                            </div>
                        </div>
                        <div className='row nc-dash-padd-lr'>
                            {cardData.map((card, index) => (
                                <div key={index} className="col-md-3 mf-bx-sm CRM_card">
                                    <Link to={card.link} className="mf-dashbox nc-mf-dashbox">
                                        <div className="icon">
                                            <img className="item-icon" src={card.icon} alt="" />
                                        </div>
                                        <div className="card-menu-content">
                                            <h4>{card.title}</h4>
                                            <p>{card.content}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        <div className='categories-heading'>
                            <div className='category-container'>
                                <div className='category-title'>
                                User Master
                                </div>
                                <span class="category-line"></span>
                            </div>
                        </div>
                        <div className='row nc-dash-padd-lr'>
                            {cardData2.map((card, index) => (
                                <div key={index} className="col-md-3 mf-bx-sm CRM_card">
                                    <Link to={card.link} className="mf-dashbox nc-mf-dashbox">
                                        <div className="icon">
                                            <img className="item-icon" src={card.icon} alt="" />
                                        </div>
                                        <div className="card-menu-content">
                                            <h4>{card.title}</h4>
                                            <p>{card.content}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        <div className='categories-heading'>
                            <div className='category-container'>
                                <div className='category-title'>
                                Communication
                                </div>
                                <span class="category-line"></span>
                            </div>
                        </div>
                        <div className='row nc-dash-padd-lr'>
                            {cardData3.map((card, index) => (
                                <div key={index} className="col-md-3 mf-bx-sm CRM_card">
                                    <a href="#" className="mf-dashbox nc-mf-dashbox">
                                        <div className="icon">
                                            <img className="item-icon" src={card.icon} alt="" />
                                        </div>
                                        <div className="card-menu-content">
                                            <h4>{card.title}</h4>
                                            <p>{card.content}</p>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>  
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Utilities
