import React from 'react';
import Navbar from '../../../Navbar';
import Header from '../../../Header';
import Footer from '../../../Footer';

const EnrerNewPolicy = () => {
  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-lg-4 p-3 my-3 bg-light" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'}}>
              <form>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="searchClient">Search Client / PAN</label>
                      <input type="text" className="form-control" id="searchClient" placeholder="Search client" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="proposerName">Name of Proposer</label>
                      <input type="text" className="form-control" id="proposerName" placeholder="Enter name" />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="policyNumber">Policy Number <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="policyNumber" placeholder="Enter policy number" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="insuranceCompany">Select Insurance Company <span className="text-danger">*</span></label>
                      <select className="form-control" id="insuranceCompany" required>
                        <option>Select</option>
                        <option>Company 1</option>
                        <option>Company 2</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="schemeName">Select Scheme Name <span className="text-danger">*</span></label>
                      <select className="form-control" id="schemeName" required>
                        <option>Select</option>
                        <option>Scheme 1</option>
                        <option>Scheme 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="schemeSubType">Select Scheme Sub-Type <span className="text-danger">*</span></label>
                      <select className="form-control" id="schemeSubType" required>
                        <option>Children Plan</option>
                        <option>Plan 1</option>
                        <option>Plan 2</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="doc">DOC <span className="text-danger">*</span></label>
                      <input type="date" className="form-control" id="doc" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="policyStartDate">Policy Start Date <span className="text-danger">*</span></label>
                      <input type="date" className="form-control" id="policyStartDate" required />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="riskCoverage">Risk Coverage <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="riskCoverage" placeholder="Enter amount" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="premiumAmount">Premium Amount <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="premiumAmount" placeholder="Enter premium amount" required />
                    </div>
                  </div>
                  <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="searchClient">Search Client / PAN</label>
                      <input type="text" className="form-control" id="searchClient" placeholder="Search client" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="proposerName">Name of Proposer</label>
                      <input type="text" className="form-control" id="proposerName" placeholder="Enter name" />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="policyNumber">Policy Number <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="policyNumber" placeholder="Enter policy number" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="insuranceCompany">Select Insurance Company <span className="text-danger">*</span></label>
                      <select className="form-control" id="insuranceCompany" required>
                        <option>Select</option>
                        <option>Company 1</option>
                        <option>Company 2</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="schemeName">Select Scheme Name <span className="text-danger">*</span></label>
                      <select className="form-control" id="schemeName" required>
                        <option>Select</option>
                        <option>Scheme 1</option>
                        <option>Scheme 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="schemeSubType">Select Scheme Sub-Type <span className="text-danger">*</span></label>
                      <select className="form-control" id="schemeSubType" required>
                        <option>Children Plan</option>
                        <option>Plan 1</option>
                        <option>Plan 2</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="doc">DOC <span className="text-danger">*</span></label>
                      <input type="date" className="form-control" id="doc" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="policyStartDate">Policy Start Date <span className="text-danger">*</span></label>
                      <input type="date" className="form-control" id="policyStartDate" required />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="riskCoverage">Risk Coverage <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="riskCoverage" placeholder="Enter amount" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="premiumAmount">Premium Amount <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="premiumAmount" placeholder="Enter premium amount" required />
                    </div>
                  </div>
                </div>

                {/* New fields */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="premiumPayingMode">Premium Paying Mode <span className="text-danger">*</span></label>
                      <select className="form-control" id="premiumPayingMode" required>
                        <option>One Time</option>
                        <option>Monthly</option>
                        <option>Yearly</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="premiumPayingTerm">Premium Paying Term (In Years) <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="premiumPayingTerm" required />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="policyTerm">Policy Term (In Years) <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="policyTerm" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="chequeNumber">Cheque Number</label>
                      <input type="text" className="form-control" id="chequeNumber" />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="proposalNumber">Proposal Number (Same as policy number) <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="proposalNumber" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="dob">Date of Birth <span className="text-danger">*</span></label>
                      <input type="date" className="form-control" id="dob" required />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="nomineeName">Nominee Name</label>
                      <input type="text" className="form-control" id="nomineeName" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="relation">Relation</label>
                      <select className="form-control" id="relation">
                        <option>Select Relation</option>
                        <option>Father</option>
                        <option>Mother</option>
                        <option>Spouse</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="remarks">Remarks</label>
                      <textarea className="form-control" id="remarks" rows="3" style={{ resize: "none" }}></textarea>
                    </div>
                  </div>
                </div>

                <div className="form-check mb-3">
                  <input className="form-check-input" type="checkbox" id="autoPay" />
                  <label className="form-check-label" htmlFor="autoPay">
                    Auto Pay All Premium Till Date
                  </label>
                </div>

                
                <div className="row">
                  <div className="col-md-2">
                    <button type="submit" className="btn btn-success w-100">Add Policy</button>
                  </div>
                  <div className="col-md-2">
                    <button type="reset" className="btn btn-secondary w-100">Reset</button>
                  </div>
                 
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default EnrerNewPolicy;
