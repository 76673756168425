import React from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';

const EmployeeClientMap = () => {
  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              <div className="row">
                <div className="col-lg-10">
                  <div className="p-3">
                    <div className="">
                      <label for="selectEmployee" class="form-label">Select Employee</label>
                      <div className='d-flex gap-4'>
                      <select class="form-select col-4" id='selectEmployee' aria-label="Default select example">
                        <option selected>Select Employee</option>
                      </select>
                      <button className="btn btn-success">Get Client</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default EmployeeClientMap;
