import React from 'react';
import { FaSearch, FaCalendarAlt } from 'react-icons/fa';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';

const RealiasedFundXIRR = () => {
  return (
    
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              <div className="row g-3 align-items-center">
                <div className="col-md-3">
                  <label htmlFor="viewBy" className="form-label">Select View By</label>
                  <select id="viewBy" className="form-select">
                    <option>Select</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <label htmlFor="search" className="form-label">Search By Name / PAN / Folio No.</label>
                  <div className="input-group">
                    <input type="text" id="search" className="form-control" />
                   
                  </div>
                </div>
                <div className="col-md-2">
                  <label htmlFor="assetType" className="form-label">Asset Type</label>
                  <select id="assetType" className="form-select">
                    <option>Both</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center h-100">
                    <div className="form-check me-3">
                      <input className="form-check-input" type="radio" name="dateType" id="finYear" checked />
                      <label className="form-check-label" htmlFor="finYear">
                        Fin. Year
                      </label>
                    </div>
                    <div className="form-check me-3">
                      <input className="form-check-input" type="radio" name="dateType" id="betweenDate" />
                      <label className="form-check-label" htmlFor="betweenDate">
                        Between Date
                      </label>
                    </div>
                    <select className="form-select">
                      <option>Select Year</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="selectedFunds" />
                    <label className="form-check-label" htmlFor="selectedFunds">
                      Selected Funds
                    </label>
                  </div>
                </div>
                <div className="col-md-3 offset-md-6">
                  <button className="btn btn-success w-100">Show</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default RealiasedFundXIRR;
