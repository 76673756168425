import React, { useState } from 'react';
import Navbar from '../../../Navbar';
import Header from '../../../Header';
import Footer from '../../../Footer';
import notify from '../../../Notification';
import { FaFileExcel } from 'react-icons/fa';


const PMSnewBusiness = () => {
  const [activeTab, setActiveTab] = useState('single');
  const [client, setClient] = useState(''); 

  const handleAssetTypeChange = (e) => {
    if (client.trim() === '') {
      notify('danger', 'Error', 'Please Select a client before selecting an asset type.');
      e.target.value = 'Select'; 
    } 
  };

  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
          <div className="tabs">
                <button
                  className={`btn ${activeTab === 'single' ? 'btn-secondary' : 'btn-light'} me-2`}
                  onClick={() => setActiveTab('single')}
                >
                  SINGLE ASSET ENTRY
                </button>
                <button
                  className={`btn ${activeTab === 'bulk' ? 'btn-secondary' : 'btn-light'}`}
                  onClick={() => setActiveTab('bulk')}
                >
                  BULK ENTRY
                </button>
              </div>
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              <div className="tab-content mt-4">
                {activeTab === 'single' && (
                  <form>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="searchClient">Search Client / PAN...</label>
                          <input
                            type="text"
                            className="form-control"
                            id="searchClient"
                            placeholder="Search client"
                            value={client}
                            onChange={(e) => setClient(e.target.value)} 
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="assetType">Asset Type</label>
                          <select className="form-select" id="assetType" onChange={handleAssetTypeChange}>
                            <option value="Select">Select</option>
                            <option value="PMS">PMS</option>
                            <option value="VC_Funds">VC Funds</option>
                            <option value="AIF">AIF</option>
                            <option value="Private_Equity">Private Equity</option>
                            <option value="Unlisted_Equity">Unlisted Equity</option>
                            <option value="Antiques">Antiques</option>
                            <option value="Art_and_Painting">Art & Painting</option>
                            <option value="REITs_INVITs">REITs / INVITs</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="transactionType">Transaction Type</label>
                          <select className="form-select" id="transactionType">
                            <option>Buy</option>
                            
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}

                {activeTab === 'bulk' && (
                  <div>
                <div className="row">
                <div className="col-md-6 d-flex flex-column align-items-center bg-light rounded p-3">
                  <FaFileExcel size={80} color="#28a745" className="mb-3" />
                  <button type="button" className="btn btn-success">Download Template Excel File</button>
                  <p className="mt-2">
                    <small className="text-muted">Note: Excel File Must Be In <span className="text-danger">".xls"</span> Format</small>
                  </p>
                </div>
                <div className="col-md-6 d-flex flex-column bg-light rounded p-3">
                  <p>Please Select A File And Click Upload Button</p>
                  <div className="mb-3">
                    <input type="file" className="form-control" />
                  </div>
                  <button type="button" className="btn btn-success col-3">Upload</button>
                </div>
              </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PMSnewBusiness;
