import React, { useState } from "react";
import "../assets/css/content.css";
import carouselbg from "../assets/Images/Water_Wave.jpg";
import androidDownload from "../assets/Images/android-btn.webp"
import iosDownload from "../assets/Images/apple-btn.webp"
import Highcharts, { Tick, chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Footer from "./Footer";

const Content = () => {

    const [isChartVisible, setIsChartVisible] = useState(false);
    const [isSIPVisible, setIsSIPVisible] = useState(false);

    const options = {
        chart: {
            type: "area",
        },
        title: {
            text: "",
        },
        xAxis: {
            categories: ["Mar", "Apr", "May"],
        },
        yAxis: {
            title: {
                text: "",
            },
            labels: {
                formatter: function () {
                    return this.value + " Cr";
                },
            },
        },
        tooltip: {
            shared: true,
            valueSuffix: " Cr",
        },
        plotOptions: {
            area: {
                stacking: "normal",
                lineColor: "#666666",
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: "#666666",
                },
            },
        },
        series: [
            {
                name: "Cash",
                data: [0, 0, 0],
                color: "#2b908f",
            },
            {
                name: "Debt",
                data: [22.12, 23.12, 23.35],
                color: "#90ee7e",
            },
            {
                name: "Equity",
                data: [21, 22, 23],
                color: "#7cb5ec",
            },
        ],
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };

    const optionsSIP = {
        chart: {
            type: 'line',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: ['Mar', 'Apr', 'May'],
        },
        yAxis: {
            title: {
                text: '',
            },
            labels: {
                formatter: function () {
                    return this.value + ' Lakh';
                },
            },
        },
        tooltip: {
            shared: true,
            valueSuffix: ' Lakh',
        },
        series: [
            {
                name: 'SIP Value',
                data: [0, 0, 0],
                color: '#7cb5ec',
            },
        ],
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };

    const handleToggle = () => {
        setIsChartVisible(!isChartVisible);
    };
    const handleToggleSIP = () => {
        setIsSIPVisible(!isSIPVisible);
    };

    const [chartData, setChartData] = useState({
        online: 41,
        new: 55,
        active: 719,
        inactive: 3180,
    });
    const [activeMonth, setActiveMonth] = useState('Mar');

    const handleMonthClick = (month) => {
        // Example data for different months
        setActiveMonth(month);
        const data = {
            Mar: { online: 30, new: 45, active: 650, inactive: 3100 },
            Apr: { online: 41, new: 55, active: 719, inactive: 3180 },
            May: { online: 50, new: 65, active: 780, inactive: 3250 },
            Jun: { online: 35, new: 60, active: 700, inactive: 3150 },
        };

        setChartData(data[month]);
    };

    const chartOptions = {
        chart: {
            type: 'pie',
            height: '65%',
        },
        title: {
            text: '',
        },
        tooltip: {
            useHTML: true,
            pointFormat: '<b>{point.name}:</b> {point.y}'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y}',
                    style: {
                        color: 'black',
                        textOutline: 'none',
                        fontWeight: '500',
                        fontSize: '12px'
                    }
                }
            }
        },
        series: [{
            name: 'Clients',
            colorByPoint: true,
            data: [
                { name: 'Online', y: chartData.online },
                { name: 'New', y: chartData.new },
                { name: 'Active', y: chartData.active },
                { name: 'Inactive', y: chartData.inactive },
            ]
        }],
        credits: {
            enabled: false
        }
    };

    const businessChartOptions = {
        chart: {
            type: 'area',
            height: '80%',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: ['Mar', 'Apr', 'May', 'Jun'],
        },
        yAxis: [{
            title: {
                text: '',
            },
            tickPositions: [0.8, 1.6, 2.4, 3.2],
            min: 0.8,
            max: 3.2,
        },
        {
            opposite: true,
            title: {
                text: '',
            },
            tickPositions: [0, 1, 2, 3],
            min: 0,
            max: 3,
        }
        ],
        series: [{
            name: 'closed',
            data: [0, 1.9, 3, 1],
            color: 'rgb(255, 0, 0, 0.5)',
            fillOpacity: 0.5,
            lineColor: 'red'
        }],
        legend: {
            enabled: false,
            itemStyle: {
                fontWeight: 'bold'
            }
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                }
            }

        },
        credits: {
            enabled: false
        }
    }

    const optionsMonthlyMIS = {
        chart: {
            type: 'line',
            height: '80%',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: ['Mar', 'Apr', 'May'],
        },
        yAxis: {
            title: {
                text: '',
            },
            labels: {
                formatter: function () {
                    return this.value + ' Cr';
                },
            },
        },
        tooltip: {
            shared: true,
            valueSuffix: ' Cr',
        },
        series: [
            {
                name: 'SIP Value',
                data: [0, 0, 0],
                color: '#3579f6',
            },
        ],
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <>
        <div className="container">
            <div className="row mb-3 mt-4">
                <div className="col-xl-6 pr-2">
                    <div className="trand-box nc-tt" id="currentAumCard">
                        <a href="#" target="_blank" className="btn btn-db-vr">
                            View Report
                        </a>
                        <div>
                            <p>Current</p>
                            <h1>
                                AUM 57.73<span>Cr</span>
                            </h1>
                            <i className="fa-solid fa-caret-up"></i>
                            <span className="tend-mon">
                                <span>
                                    <span className="mr-1"></span>34.64 Lakh
                                </span>
                                (<span></span>0.6%) MoM
                            </span>
                        </div>
                        <a
                            data-toggle="collapse"
                            role="button"
                            className="trand-popup-btn font-weight-bold text-success"
                            href="#"
                            onClick={handleToggle}
                            id="left_btn"
                        >
                            View Trend
                        </a>

                        <div
                            className={`collapse trand-view-panel w-100 ${isChartVisible ? "show" : ""
                                }`}
                            id="collapseExample"
                        >
                            {isChartVisible && (
                                <>
                                    <div className="card card-body">
                                        <div className="trad-box-popuo-content">
                                            <div className="row">
                                                <div class="col-xl-3" id="aumTrendSideVal">
                                                    <div class="trad-box-popuo-content-mini">
                                                        <p>Equity</p> <h2>21.15 Cr</h2>
                                                    </div>
                                                    <div class="trad-box-popuo-content-mini">

                                                        <p>Debt</p> <h2>22.98 Cr</h2>
                                                    </div>
                                                    <div class="trad-box-popuo-content-mini">

                                                        <p>Cash</p> <h2>0.00 Cr</h2>
                                                    </div>
                                                </div>
                                                <div className="col-xl-9 p-0">
                                                    <HighchartsReact highcharts={Highcharts} options={options} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 pl-2">
                    <div className="trand-box nc-tt" id="currentSipCard">
                        <a href="#" target="_blank" class="btn btn-db-vr">View Report</a>
                        <div class="">
                            <p>Current</p>
                            <h1>SIP 0.00</h1>
                            <i className="fa-solid fa-caret-up"></i>
                            <span class="tend-mon">0% MoM</span>
                        </div>
                        <a data-toggle="collapse" onClick={handleToggleSIP} role="button" class="trand-popup-btn font-weight-bold text-success" href="#">View Trend </a>
                        <div
                            className={`collapse trand-view-panel w-100 ${isSIPVisible ? "show" : ""
                                }`}
                            id="collapseExample"
                        >
                            {isSIPVisible && (
                                <>
                                    <div className="card card-body">
                                        <div className="trad-box-popuo-content">
                                            <div className="row">
                                                <div class="col-xl-3" >
                                                    <div class="trad-box-popuo-content-mini" id="sipTrendSideVal">
                                                        <p>SIP Value</p> <h2>0.0 lakhs</h2>
                                                    </div>
                                                </div>
                                                <div className="col-xl-9 p-0">
                                                    <HighchartsReact highcharts={Highcharts} options={optionsSIP} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <div className="row mb-3">
                <div className="col-xl-12">
                    <div id="dayWiseTransactionCard" class="trand-tabs-box nc-tt">
                        <div className="row">
                            <div className="col nc-tt">
                                <ul class="nav nav-pills mb-2" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">3 Days</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">7 Days</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">10 Days</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-thirteen-tab" data-toggle="pill" href="#pills-thirteen" role="tab" aria-controls="pills-thirteen" aria-selected="false">30 Days</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col" style={{ minWidth: '31.8%' }}></div>
                            <div className="col nc-tt rb text-right">
                                <a href="#" target="_blank" class="btn btn-tdays-vr">View Report</a>
                            </div>
                            <div class="col nc-tt text-right">
                                <a href="#" id="newSipReportLink" target="_blank" class="btn btn-tdays-vr">View Report</a>
                            </div>
                        </div>
                        <div className="tab-content p-0" id="pills-tabContent">
                            <div class="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div class="row dashboard-trends">
                                    <div className="col rb">
                                        <div className="trad-box-tabs-content">
                                            <div className="media">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="74.296" height="80" viewBox="0 0 74.296 80">
                                                    <g id="Group_4072" data-name="Group 4072" transform="translate(-130 -356)">
                                                        <g id="Group_51" data-name="Group 51" transform="translate(160.865 356)">
                                                            <path id="Path_25" data-name="Path 25" d="M62.647,2.5v0a5.45,5.45,0,0,0-5.577,5.45V45.6a.379.379,0,0,1,.167.059L59,46.85a.385.385,0,0,0,.432,0l1.763-1.194a.386.386,0,0,1,.432,0l1.763,1.194a.386.386,0,0,0,.432,0l1.763-1.194a.385.385,0,0,1,.432,0L67.78,46.85a.385.385,0,0,0,.432,0l1.763-1.194a.385.385,0,0,1,.432,0L72.17,46.85a.385.385,0,0,0,.432,0l1.763-1.194a.386.386,0,0,1,.432,0l1.81,1.226c.106.072,2.249-1.413,2.249-1.285l1.831,1.628c.48.24,1.539-2.363,3.068-1.628s4.108,2.585,4.057.059c0-6.357,0-32.112,0-37.7A5.452,5.452,0,0,1,93.385,2.5H62.647Z" transform="translate(-57.07 -1.957)" fill="rgba(240,38,0,0.3)" stroke="#000" stroke-width="0.8"></path>
                                                            <path id="Path_27" data-name="Path 27" d="M204.139,2.5h-.023a5.452,5.452,0,0,0-5.577,5.453v5.966a.382.382,0,0,0,.35-.039l1.763-1.195a.385.385,0,0,1,.432,0l1.764,1.195a.385.385,0,0,0,.432,0l1.763-1.195a.385.385,0,0,1,.432,0l1.765,1.195a.385.385,0,0,0,.432,0l1.812-1.226a.385.385,0,0,0,.169-.319V8.017A5.515,5.515,0,0,0,204.139,2.5Z" transform="translate(-167.802 -1.957)" fill="#  " stroke="#707070" stroke-width="0.8" opacity="0.5"></path>
                                                            <path id="Path_30" data-name="Path 30" d="M153.9,180.715h-5.082a.543.543,0,1,1,0-1.086H153.9a.543.543,0,1,1,0,1.086Z" transform="translate(-128.46 -140.601)"></path>
                                                            <path id="Path_31" data-name="Path 31" d="M153.9,131.748h-5.082a.543.543,0,0,1,0-1.086H153.9a.543.543,0,0,1,0,1.086Z" transform="translate(-128.46 -102.273)"></path>
                                                            <path id="Path_32" data-name="Path 32" d="M104.438,35.1H88.279a.543.543,0,0,1,0-1.086h16.159a.543.543,0,1,1,0,1.086Z" transform="translate(-83.463 -9.674)"></path>
                                                            <path id="Path_33" data-name="Path 33" d="M153.9,148.07h-5.082a.543.543,0,0,1,0-1.086H153.9a.543.543,0,0,1,0,1.086Z" transform="translate(-128.46 -115.049)"></path>
                                                            <path id="Path_34" data-name="Path 34" d="M153.9,164.393h-5.082a.543.543,0,1,1,0-1.086H153.9a.543.543,0,1,1,0,1.086Z" transform="translate(-128.46 -127.825)"></path>
                                                            <path id="Path_35" data-name="Path 35" d="M112.451,180.715h-6.182a.543.543,0,0,1,0-1.086h6.182a.543.543,0,0,1,0,1.086Z" transform="translate(-95.154 -140.601)"></path>
                                                            <path id="Path_36" data-name="Path 36" d="M89.584,131.748H77.054a.543.543,0,0,1,0-1.086h12.53a.543.543,0,0,1,0,1.086Z" transform="translate(-72.287 -102.273)"></path>
                                                            <path id="Path_37" data-name="Path 37" d="M89.584,148.07H77.054a.543.543,0,0,1,0-1.086h12.53a.543.543,0,0,1,0,1.086Z" transform="translate(-72.287 -115.049)"></path>
                                                            <path id="Path_38" data-name="Path 38" d="M89.584,164.393H77.054a.543.543,0,1,1,0-1.086h12.53a.543.543,0,1,1,0,1.086Z" transform="translate(-72.287 -127.825)"></path>
                                                            <path id="Path_39" data-name="Path 39" d="M205.5,12.535a.928.928,0,0,1-.52-.16L203.3,11.24l-1.675,1.135a.923.923,0,0,1-1.042,0l-1.675-1.135-1.675,1.135a.928.928,0,0,1-.845.1.543.543,0,0,1-.353-.509V6a6,6,0,0,1,6.133-6h.011a6.066,6.066,0,0,1,6.059,6.059v4.319a.927.927,0,0,1-.408.769l-1.811,1.226a.928.928,0,0,1-.521.16Zm-2.2-2.415a.927.927,0,0,1,.521.161l1.676,1.135,1.654-1.12V6.06a4.978,4.978,0,0,0-4.972-4.972h-.023A4.909,4.909,0,0,0,197.125,6v5.14l1.266-.857a.924.924,0,0,1,1.041,0l1.676,1.135,1.675-1.135A.927.927,0,0,1,203.3,10.12Z" transform="translate(-165.845 0)"></path>
                                                        </g>
                                                        <g id="Group_70" data-name="Group 70" transform="translate(130 404.711)">
                                                            <g id="Group_63" data-name="Group 63" transform="translate(9.018 3.832)">
                                                                <path id="Path_49" data-name="Path 49" d="M94.119,160.426q-.2.032-.391.076l-16.52,3.737a5.43,5.43,0,0,0-5.423-5.207H65.233a1.259,1.259,0,0,1-1.2-.86,8.04,8.04,0,0,0-7.639-5.533H52.01a8.005,8.005,0,0,0-5.445,2.128,2.534,2.534,0,0,1-1.719.673H33.1v18.024H43.972l24.693,2.688a8.765,8.765,0,0,0,4.18-.566l22.866-9.064c3.481-1.38,2.1-6.7-1.592-6.1Z" transform="translate(-33.103 -152.639)" fill="#fff"></path>
                                                            </g>
                                                            <g id="Group_64" data-name="Group 64" transform="translate(8.353 3.167)">
                                                                <path id="Path_50" data-name="Path 50" d="M68.274,175.533a9.391,9.391,0,0,1-1.014-.055L42.6,172.795H31.769a.666.666,0,0,1-.665-.665V154.105a.666.666,0,0,1,.665-.665H43.513a1.865,1.865,0,0,0,1.267-.5,8.674,8.674,0,0,1,5.9-2.3H55.06a8.685,8.685,0,0,1,8.272,5.992.591.591,0,0,0,.567.4H70.45a6.079,6.079,0,0,1,6.005,5.058l15.791-3.572q.216-.049.431-.084a3.681,3.681,0,0,1,4.222,2.733,3.78,3.78,0,0,1-2.278,4.638l-22.866,9.064A9.476,9.476,0,0,1,68.274,175.533Zm-35.839-4.07h10.2a.648.648,0,0,1,.072,0L67.4,174.155a8.126,8.126,0,0,0,3.862-.523l22.866-9.064a2.451,2.451,0,0,0,1.486-3.047,2.329,2.329,0,0,0-2.725-1.773q-.176.028-.352.069l-16.519,3.736a.665.665,0,0,1-.812-.622,4.753,4.753,0,0,0-4.758-4.569H63.9a1.919,1.919,0,0,1-1.832-1.318,7.356,7.356,0,0,0-7.007-5.075H50.676a7.346,7.346,0,0,0-4.993,1.951,3.193,3.193,0,0,1-2.17.849H32.435Z" transform="translate(-31.104 -150.639)"></path>
                                                            </g>
                                                            <g id="Group_65" data-name="Group 65" transform="translate(36.668 14.767)">
                                                                <path id="Path_51" data-name="Path 51" d="M130.886,188.843h-14.02a.665.665,0,1,1,0-1.331h14.02A1.117,1.117,0,0,0,132,186.4l-.01-.195a.665.665,0,1,1,1.329-.066l.011.228a2.456,2.456,0,0,1-2.446,2.48Z" transform="translate(-116.2 -185.504)"></path>
                                                            </g>
                                                            <g id="Group_66" data-name="Group 66" transform="translate(9.018 2.493)">
                                                                <path id="Path_52" data-name="Path 52" d="M41.993,174.918H34.056a.952.952,0,0,1-.952-.952v-24.4a.952.952,0,0,1,.952-.952h7.936a1.269,1.269,0,0,1,1.269,1.269v23.766A1.269,1.269,0,0,1,41.993,174.918Z" transform="translate(-33.104 -148.613)" fill="#fbbdb2"></path>
                                                            </g>
                                                            <g id="Group_67" data-name="Group 67" transform="translate(8.353 1.827)">
                                                                <path id="Path_53" data-name="Path 53" d="M40.658,174.249H32.721a1.619,1.619,0,0,1-1.617-1.617v-24.4a1.619,1.619,0,0,1,1.617-1.617h7.936a1.937,1.937,0,0,1,1.935,1.935v23.766A1.937,1.937,0,0,1,40.658,174.249Zm-7.936-26.305a.287.287,0,0,0-.286.286v24.4a.287.287,0,0,0,.286.286h7.936a.6.6,0,0,0,.6-.6V148.547a.6.6,0,0,0-.6-.6H32.721Z" transform="translate(-31.104 -146.613)"></path>
                                                            </g>
                                                            <g id="Group_68" data-name="Group 68" transform="translate(0.665 0.665)">
                                                                <path id="Path_54" data-name="Path 54" d="M9.269,173.081H20.735A1.269,1.269,0,0,0,22,171.811v-27.42a1.269,1.269,0,0,0-1.269-1.269H9.269A1.269,1.269,0,0,0,8,144.391v27.42a1.269,1.269,0,0,0,1.269,1.269Z" transform="translate(-8 -143.122)" fill="#fbbdb2"></path>
                                                                <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(4.335 21.624)" fill="#fff" stroke="#000" stroke-width="0.6">
                                                                    <circle cx="2.5" cy="2.5" r="2.5" stroke="none"></circle>
                                                                    <circle cx="2.5" cy="2.5" r="2.2" fill="none"></circle>
                                                                </g>
                                                            </g>
                                                            <g id="Group_69" data-name="Group 69" transform="translate(0)">
                                                                <path id="Path_55" data-name="Path 55" d="M19.4,172.411H7.935A1.937,1.937,0,0,1,6,170.477v-27.42a1.937,1.937,0,0,1,1.935-1.935H19.4a1.937,1.937,0,0,1,1.935,1.935v27.42A1.937,1.937,0,0,1,19.4,172.411ZM7.935,142.453a.6.6,0,0,0-.6.6v27.42a.6.6,0,0,0,.6.6H19.4a.6.6,0,0,0,.6-.6v-27.42a.6.6,0,0,0-.6-.6Z" transform="translate(-6 -141.122)"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <div class="media-body">
                                                    <h5 class="mt-0">0<span data-bs-toggle="tooltip" data-placement="top" title="" data-bs-original-title="Purchase+TransferIn"> Purchases</span></h5>
                                                    <p>0.00 INR</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col rb">
                                        <div class="trad-box-tabs-content">
                                            <div class="media">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="78.121" height="82" viewBox="0 0 78.121 82">
                                                    <g id="Group_4070" data-name="Group 4070" transform="translate(-477 -344.766)">
                                                        <g id="Group_78" data-name="Group 78" transform="translate(487.831 400.162)">
                                                            <path id="Path_63" data-name="Path 63" d="M44,170.838v24.251a1.68,1.68,0,0,0,1.68,1.68h53.1a1.68,1.68,0,0,0,1.68-1.68V170.838Z" transform="translate(-44 -170.838)" fill="rgba(240,38,0,0.3)"></path>
                                                        </g>
                                                        <g id="Group_80" data-name="Group 80" transform="translate(501.881 345.438)">
                                                            <path id="Path_65" data-name="Path 65" d="M114.166,22.18A14.18,14.18,0,1,0,96.552,35.938l2.842,5.307a.672.672,0,0,0,1.185,0l2.842-5.307A14.184,14.184,0,0,0,114.166,22.18Z" transform="translate(-85.807 -8)" fill="#fff"></path>
                                                        </g>
                                                        <g id="Group_81" data-name="Group 81" transform="translate(484.842 388.79)">
                                                            <path id="Path_66" data-name="Path 66" d="M96.217,137.578a.672.672,0,0,0-.665-.578H37.1a.672.672,0,0,0-.665.578l-1.312,9.261a1.344,1.344,0,0,0,1.331,1.533H96.2a1.344,1.344,0,0,0,1.331-1.533Z" transform="translate(-35.106 -137)" fill="rgba(240,38,0,0.3)"></path>
                                                        </g>
                                                        <g id="Group_82" data-name="Group 82" transform="translate(484.17 388.118)">
                                                            <path id="Path_67" data-name="Path 67" d="M94.871,147.717H35.124a2.016,2.016,0,0,1-2-2.3l1.312-9.261A1.35,1.35,0,0,1,35.771,135H94.225a1.35,1.35,0,0,1,1.331,1.155l1.312,9.261a2.016,2.016,0,0,1-2,2.3Zm-59.1-11.372-1.312,9.261a.672.672,0,0,0,.665.767H94.871a.672.672,0,0,0,.665-.767l-1.312-9.261H35.77Z" transform="translate(-33.107 -135.001)" fill="#080808"></path>
                                                        </g>
                                                        <g id="Group_83" data-name="Group 83" transform="translate(487.159 399.49)">
                                                            <path id="Path_68" data-name="Path 68" d="M97.451,196.114h-53.1A2.355,2.355,0,0,1,42,193.761V169.51a.672.672,0,0,1,.672-.672H99.131a.672.672,0,0,1,.672.672v24.251A2.355,2.355,0,0,1,97.451,196.114ZM43.344,170.182v23.579a1.009,1.009,0,0,0,1.008,1.008h53.1a1.009,1.009,0,0,0,1.008-1.008V170.182Z" transform="translate(-42 -168.838)" fill="#080808"></path>
                                                        </g>
                                                        <g id="Group_85" data-name="Group 85" transform="translate(493.561 378.636)">
                                                            <path id="Path_70" data-name="Path 70" d="M65.292,114.311a.673.673,0,0,1-.583-.336l-3.568-6.181a.672.672,0,1,1,1.164-.672l3.568,6.181a.672.672,0,0,1-.581,1.008Z" transform="translate(-61.051 -106.786)" fill="#080808"></path>
                                                        </g>
                                                        <g id="Group_86" data-name="Group 86" transform="translate(496.444 378.912)">
                                                            <path id="Path_71" data-name="Path 71" d="M73.03,113.676a.673.673,0,0,1-.583-.336l-2.728-4.725a.672.672,0,1,1,1.164-.672l2.728,4.725a.672.672,0,0,1-.581,1.008Z" transform="translate(-69.629 -107.606)" fill="#080808"></path>
                                                        </g>
                                                        <g id="Group_88" data-name="Group 88" transform="translate(533.648 377.964)">
                                                            <path id="Path_73" data-name="Path 73" d="M181,112.311a.672.672,0,0,1-.581-1.008l3.568-6.181a.672.672,0,0,1,1.164.672l-3.568,6.181A.673.673,0,0,1,181,112.311Z" transform="translate(-180.332 -104.786)" fill="#080808"></path>
                                                        </g>
                                                        <g id="Group_89" data-name="Group 89" transform="translate(531.605 378.24)">
                                                            <path id="Path_74" data-name="Path 74" d="M174.925,111.676a.672.672,0,0,1-.581-1.008l2.728-4.725a.672.672,0,1,1,1.164.672l-2.728,4.725A.673.673,0,0,1,174.925,111.676Z" transform="translate(-174.254 -105.606)" fill="#080808"></path>
                                                        </g>
                                                        <g id="Group_90" data-name="Group 90" transform="translate(477 366.434)">
                                                            <path id="Path_75" data-name="Path 75" d="M23.537,91.079a.84.84,0,0,1-.728-.42L11.884,71.736a.84.84,0,0,1,1.455-.84L24.264,89.819a.841.841,0,0,1-.727,1.261Z" transform="translate(-11.771 -70.476)" fill="rgba(240,38,0,0.3)"></path>
                                                        </g>
                                                        <g id="Group_91" data-name="Group 91" transform="translate(542.516 366.434)">
                                                            <path id="Path_76" data-name="Path 76" d="M207.56,91.08a.84.84,0,0,1-.727-1.26L217.758,70.9a.84.84,0,1,1,1.455.84L208.289,90.66A.841.841,0,0,1,207.56,91.08Z" transform="translate(-206.721 -70.477)" fill="rgba(240,38,0,0.3)"></path>
                                                        </g>
                                                        <g id="Group_94" data-name="Group 94" transform="translate(501.209 344.766)">
                                                            <g id="Group_93" data-name="Group 93">
                                                                <path id="Path_78" data-name="Path 78" d="M98.659,40.944a1.342,1.342,0,0,1-1.185-.709l-2.7-5.047a14.852,14.852,0,1,1,7.776,0l-2.7,5.047A1.342,1.342,0,0,1,98.659,40.944Zm0-33.6a13.508,13.508,0,0,0-3.272,26.614.673.673,0,0,1,.43.335L98.659,39.6l2.842-5.307a.673.673,0,0,1,.43-.335A13.508,13.508,0,0,0,98.659,7.344Z" transform="translate(-83.807 -6)" fill="#080808"></path>
                                                            </g>
                                                        </g>
                                                        <g id="flash" transform="translate(512.05 352.156)">
                                                            <g id="Group_95" data-name="Group 95" transform="translate(0)">
                                                                <path id="Path_104" data-name="Path 104" d="M119.6,5.261a.256.256,0,0,0-.232-.148H116.34L119.33.392A.256.256,0,0,0,119.114,0h-4.09a.256.256,0,0,0-.229.141L110.96,7.811a.256.256,0,0,0,.229.37h2.629l-2.865,6.8a.256.256,0,0,0,.431.264l8.181-9.715A.256.256,0,0,0,119.6,5.261Z" transform="translate(-110.933)" fill="#f02600"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <div class="media-body">
                                                    <h5 class="mt-0">0<span data-toggle="tooltip" data-placement="top" title="" data-original-title="Redemption+TransferOut"> Redemptions</span></h5>
                                                    <p>0.00 INR</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col rb">
                                        <div class="trad-box-tabs-content">
                                            <div class="media">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="91" height="80" viewBox="0 0 91 80">
                                                    <g id="Group_4071" data-name="Group 4071" transform="translate(-828.946 -349)">
                                                        <g id="Group_96" data-name="Group 96" transform="translate(840.08 349.7)">
                                                            <path id="Path_105" data-name="Path 105" d="M120.014,188.4H41.685a.7.7,0,1,1,0-1.4h78.329a.7.7,0,1,1,0,1.4Z" transform="translate(-40.917 -131.036)" fill="#a2a2a2" opacity="0.331"></path>
                                                            <path id="Path_106" data-name="Path 106" d="M120.014,148.4H41.685a.7.7,0,1,1,0-1.4h78.329a.7.7,0,1,1,0,1.4Z" transform="translate(-40.917 -105.027)" fill="#a2a2a2" opacity="0.331"></path>
                                                            <path id="Path_107" data-name="Path 107" d="M120.014,108.4H41.685a.7.7,0,1,1,0-1.4h78.329a.7.7,0,1,1,0,1.4Z" transform="translate(-40.917 -79.018)" fill="#a2a2a2" opacity="0.331"></path>
                                                            <path id="Path_108" data-name="Path 108" d="M120.014,68.4H41.685a.7.7,0,1,1,0-1.4h78.329a.7.7,0,1,1,0,1.4Z" transform="translate(-40.917 -53.009)" fill="#a2a2a2" opacity="0.331"></path>
                                                            <path id="Path_109" data-name="Path 109" d="M120.014,28.4H41.685a.7.7,0,1,1,0-1.4h78.329a.7.7,0,1,1,0,1.4Z" transform="translate(-40.917 -27)" fill="#a2a2a2" opacity="0.331"></path>
                                                        </g>
                                                        <path id="Path_110" data-name="Path 110" d="M16.079,67.8h-5.6a1.4,1.4,0,0,1,0-2.8h5.6a1.4,1.4,0,1,1,0,2.8Z" transform="translate(819.863 299.596)" fill="#a2a2a2" opacity="0.338"></path>
                                                        <path id="Path_111" data-name="Path 111" d="M16.079,107.8h-5.6a1.4,1.4,0,0,1,0-2.8h5.6a1.4,1.4,0,1,1,0,2.8Z" transform="translate(819.863 275.193)" fill="#a2a2a2" opacity="0.338"></path>
                                                        <path id="Path_112" data-name="Path 112" d="M16.079,147.8h-5.6a1.4,1.4,0,1,1,0-2.8h5.6a1.4,1.4,0,1,1,0,2.8Z" transform="translate(819.863 250.789)" fill="#a2a2a2" opacity="0.338"></path>
                                                        <path id="Path_113" data-name="Path 113" d="M16.079,187.8h-5.6a1.4,1.4,0,1,1,0-2.8h5.6a1.4,1.4,0,1,1,0,2.8Z" transform="translate(819.863 226.385)" fill="#a2a2a2" opacity="0.338"></path>
                                                        <path id="Path_114" data-name="Path 114" d="M16.079,27.8h-5.6a1.4,1.4,0,0,1,0-2.8h5.6a1.4,1.4,0,1,1,0,2.8Z" transform="translate(819.863 324)" fill="#a2a2a2" opacity="0.338"></path>
                                                        <path id="Path_115" data-name="Path 115" d="M120.014,210.149H41.685a.7.7,0,1,1,0-1.4h78.329a.7.7,0,1,1,0,1.4Z" transform="translate(799.163 212.467)"></path>
                                                        <circle id="Ellipse_3" data-name="Ellipse 3" cx="5.247" cy="5.247" r="5.247" transform="translate(847.553 369.042)"></circle>
                                                        <circle id="Ellipse_4" data-name="Ellipse 4" cx="10.493" cy="10.493" r="10.493" transform="translate(861.405 386.995)" fill="#fbbdb2"></circle>
                                                        <circle id="Ellipse_5" data-name="Ellipse 5" cx="10.493" cy="10.493" r="10.493" transform="translate(880.92 353.897)" fill="#fbbdb2"></circle>
                                                        <circle id="Ellipse_6" data-name="Ellipse 6" cx="5.247" cy="5.247" r="5.247" transform="translate(906.187 376.25)"></circle>
                                                        <path id="Path_116" data-name="Path 116" d="M67.278,225.8h-6.3a1.4,1.4,0,1,1,0-2.8h6.3a1.4,1.4,0,0,1,0,2.8Z" transform="translate(788.706 203.202)" fill="#a2a2a2" opacity="0.338"></path>
                                                        <path id="Path_117" data-name="Path 117" d="M117.153,225.8h-6.3a1.4,1.4,0,0,1,0-2.8h6.3a1.4,1.4,0,0,1,0,2.8Z" transform="translate(757.935 203.202)" fill="#a2a2a2" opacity="0.338"></path>
                                                        <path id="Path_118" data-name="Path 118" d="M167.278,225.8h-6.3a1.4,1.4,0,1,1,0-2.8h6.3a1.4,1.4,0,0,1,0,2.8Z" transform="translate(727.009 203.202)" fill="#a2a2a2" opacity="0.338"></path>
                                                        <path id="Path_119" data-name="Path 119" d="M219.278,225.8h-6.3a1.4,1.4,0,0,1,0-2.8h6.3a1.4,1.4,0,1,1,0,2.8Z" transform="translate(695.304 203.202)" fill="#a2a2a2" opacity="0.338"></path>
                                                        <path id="Path_120" data-name="Path 120" d="M61.529,85.642A5.946,5.946,0,1,1,67.475,79.7,5.953,5.953,0,0,1,61.529,85.642Zm0-10.493A4.547,4.547,0,1,0,66.076,79.7,4.552,4.552,0,0,0,61.529,75.149Z" transform="translate(791.234 294.554)"></path>
                                                        <path id="Path_121" data-name="Path 121" d="M101.651,139.386a11.193,11.193,0,1,1,11.193-11.193,11.205,11.205,0,0,1-11.193,11.193Zm0-20.987a9.794,9.794,0,1,0,9.794,9.794,9.8,9.8,0,0,0-9.794-9.794Z" transform="translate(770.242 269.328)" fill="#f02600"></path>
                                                        <path id="Path_122" data-name="Path 122" d="M151.776,59.386a11.193,11.193,0,1,1,11.193-11.193A11.205,11.205,0,0,1,151.776,59.386Zm0-20.987a9.794,9.794,0,1,0,9.794,9.794A9.8,9.8,0,0,0,151.776,38.4Z" transform="translate(739.677 316.197)" fill="#f02600"></path>
                                                        <path id="Path_123" data-name="Path 123" d="M213.529,103.892a5.946,5.946,0,1,1,5.946-5.946A5.953,5.953,0,0,1,213.529,103.892Zm0-10.493a4.547,4.547,0,1,0,4.547,4.547A4.552,4.552,0,0,0,213.529,93.4Z" transform="translate(697.905 283.53)"></path>
                                                    </g>
                                                </svg>
                                                <div class="media-body">
                                                    <h5 class="mt-0">0<span data-toggle="tooltip" data-placement="top" title="" data-original-title="Rejections"> Rej. Transactions</span></h5>
                                                    <p>0.00 INR</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col rb">
                                        <div class="trad-box-tabs-content">
                                            <div class="media">
                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
                                                    <g transform="translate(0,250) scale(0.100000,-0.100000)" stroke="none">
                                                        <path data-name="Path 104" d="M144 2410 c-12 -4 -31 -21 -43 -36 -20 -26 -21 -36 -21 -306 l0 -280 34 -34 c44 -44 83 -45 128 -5 l33 29 3 146 c2 80 5 146 8 146 2 0 126 -121 274 -270 153 -152 282 -273 296 -276 13 -3 36 -3 51 1 31 7 734 701 759 747 21 41 17 74 -15 110 -24 27 -36 33 -70 33 -41 0 -45 -4 -371 -330 l-330 -330 -230 230 -230 230 150 5 c144 5 151 6 175 31 14 14 28 42 32 62 5 32 1 41 -30 72 l-35 35 -273 -1 c-151 0 -284 -4 -295 -9z"></path>
                                                        <path data-name="Path 63" d="M1595 1888 c-143 -27 -285 -136 -349 -270 -163 -339 75 -721 449 -721 241 0 440 162 490 398 18 87 18 113 0 200 -33 156 -131 284 -267 348 -115 55 -206 67 -323 45z m250 -152 c165 -75 258 -266 210 -432 -49 -172 -185 -277 -360 -278 -149 0 -271 77 -337 212 -29 60 -33 76 -33 157 0 76 4 99 27 147 48 103 143 185 247 215 68 19 179 10 246 -21z"></path>
                                                        <path data-name="Path 104" d="M1554 1586 c-14 -35 0 -48 55 -52 30 -2 61 -10 69 -17 13 -10 3 -13 -54 -17 -66 -5 -69 -6 -69 -30 0 -24 3 -25 74 -30 l73 -5 -22 -20 c-13 -12 -42 -22 -73 -25 -81 -8 -77 -29 29 -150 77 -89 91 -100 120 -100 18 0 35 3 38 8 2 4 -29 47 -70 94 -41 48 -74 90 -74 93 0 3 8 5 18 5 30 0 84 40 95 70 8 22 18 30 42 32 25 2 30 7 30 28 0 21 -5 26 -32 28 -18 2 -33 7 -33 12 0 5 -3 15 -6 24 -5 13 2 16 35 16 37 0 41 2 41 25 l0 25 -140 0 c-113 0 -142 -3 -146 -14z"></path>
                                                        <path data-name="Path 104" d="M72 1144 l-22 -15 0 -479 c0 -519 -2 -502 54 -516 12 -3 116 -4 230 -2 184 3 209 5 220 21 7 9 17 17 21 17 4 0 30 -21 57 -46 91 -85 81 -84 514 -84 210 0 406 4 435 10 30 5 95 30 144 54 95 47 617 395 672 448 92 88 79 249 -25 322 -44 31 -113 44 -177 32 -33 -7 -570 -353 -587 -380 -4 -6 -8 -23 -8 -38 0 -33 39 -63 73 -56 12 2 137 81 277 175 141 94 266 174 279 178 29 9 75 -10 90 -39 7 -12 11 -38 9 -56 -2 -28 -13 -42 -53 -72 -107 -81 -575 -389 -631 -415 l-59 -28 -409 -3 -409 -3 -98 100 -99 100 2 288 3 288 188 3 c117 1 204 -2 230 -9 34 -9 71 -40 206 -174 146 -144 164 -166 164 -195 0 -38 -40 -80 -75 -80 -15 0 -78 56 -190 168 l-169 167 -115 5 c-148 6 -174 -2 -174 -61 0 -46 27 -59 125 -59 l89 0 157 -155 c91 -90 172 -161 192 -170 77 -32 180 -12 234 47 51 54 67 145 38 216 -22 52 -325 355 -390 390 -49 27 -54 27 -280 32 l-230 5 -3 27 c-6 54 -26 58 -262 58 -179 0 -219 -3 -238 -16z m368 -126 c0 -5 0 -177 0 -383 l0 -375 -130 0 -130 0 0 385 0 386 130 -3 c72 -2 130 -6 130 -10z"></path>
                                                    </g>
                                                </svg>
                                                <div class="media-body">
                                                    <h5 class="mt-0">0<span data-toggle="tooltip" data-placement="top" title="" data-original-title="Sip Rejections">SIP Rejections</span></h5>
                                                    <p>0.00 INR</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col rb">
                                        <div class="trad-box-tabs-content">
                                            <div class="media">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="91" height="80" viewBox="0 0 91 80">
                                                    <g id="Group_4073" data-name="Group 4073" fill="black" stroke="#8cb0d9" transform="translate(-1188 -345)">
                                                        <path id="Path_124" data-name="Path 124" d="M119.394,27.69H43.179a.761.761,0,1,1,0-1.523h76.215a.761.761,0,1,1,0,1.523Z" transform="translate(1158.082 319.594)" fill="#a2a2a2" opacity="0.296"></path>
                                                        <path id="Path_125" data-name="Path 125" d="M119.394,67.69H43.179a.761.761,0,1,1,0-1.523h76.215a.761.761,0,1,1,0,1.523Z" transform="translate(1158.082 295.017)" fill="#a2a2a2" opacity="0.296"></path>
                                                        <path id="Path_126" data-name="Path 126" d="M119.394,107.69H43.179a.761.761,0,1,1,0-1.523h76.215a.761.761,0,1,1,0,1.523Z" transform="translate(1158.082 270.43)" fill="#a2a2a2" opacity="0.296"></path>
                                                        <path id="Path_127" data-name="Path 127" d="M119.394,147.69H43.179a.761.761,0,1,1,0-1.523h76.215a.761.761,0,1,1,0,1.523Z" transform="translate(1158.082 245.842)" fill="#a2a2a2" opacity="0.296"></path>
                                                        <path id="Path_128" data-name="Path 128" d="M119.394,187.69H43.179a.761.761,0,1,1,0-1.523h76.215a.761.761,0,1,1,0,1.523Z" transform="translate(1158.082 221.255)" fill="#a2a2a2" opacity="0.296"></path>
                                                        <path id="Path_129" data-name="Path 129" d="M17.2,67.212H11.106a1.523,1.523,0,1,1,0-3.045H17.2a1.523,1.523,0,0,1,0,3.045Z" transform="translate(1178.417 296.25)" fill="#a2a2a2" opacity="0.3"></path>
                                                        <path id="Path_130" data-name="Path 130" d="M17.2,107.212H11.106a1.523,1.523,0,1,1,0-3.045H17.2a1.523,1.523,0,0,1,0,3.045Z" transform="translate(1178.417 271.666)" fill="#a2a2a2" opacity="0.3"></path>
                                                        <path id="Path_131" data-name="Path 131" d="M17.2,147.212H11.106a1.523,1.523,0,1,1,0-3.045H17.2a1.523,1.523,0,1,1,0,3.045Z" transform="translate(1178.417 247.083)" fill="#a2a2a2" opacity="0.3"></path>
                                                        <path id="Path_132" data-name="Path 132" d="M17.2,187.212H11.106a1.523,1.523,0,1,1,0-3.045H17.2a1.523,1.523,0,1,1,0,3.045Z" transform="translate(1178.417 222.5)" fill="#a2a2a2" opacity="0.3"></path>
                                                        <path id="Path_133" data-name="Path 133" d="M17.2,27.212H11.106a1.523,1.523,0,1,1,0-3.045H17.2a1.523,1.523,0,0,1,0,3.045Z" transform="translate(1178.417 320.833)" fill="#a2a2a2" opacity="0.3"></path>
                                                        <path id="Path_134" data-name="Path 134" d="M118.917,209.44H41.179a.761.761,0,1,1,0-1.523h77.738a.761.761,0,1,1,0,1.523Z" transform="translate(1159.321 207.979)" fill="#33353e"></path>
                                                        <path id="Path_135" data-name="Path 135" d="M80.792,226.878H73.94a1.523,1.523,0,0,1,0-3.045h6.852a1.523,1.523,0,1,1,0,3.045Z" transform="translate(1139.526 198.122)" fill="#a2a2a2" opacity="0.3"></path>
                                                        <path id="Path_136" data-name="Path 136" d="M140.792,226.878H133.94a1.523,1.523,0,1,1,0-3.045h6.852a1.523,1.523,0,1,1,0,3.045Z" transform="translate(1102.39 198.122)" fill="#a2a2a2" opacity="0.3"></path>
                                                        <path id="Path_137" data-name="Path 137" d="M200.792,226.878H193.94a1.523,1.523,0,0,1,0-3.045h6.852a1.523,1.523,0,1,1,0,3.045Z" transform="translate(1065.265 198.122)" fill="#a2a2a2" opacity="0.3"></path>
                                                        <path id="Path_138" data-name="Path 138" d="M106.944,63.815a1.14,1.14,0,0,1-.247-.027L83.856,58.713a1.143,1.143,0,0,1-.424-.191l-21.982-16L48.431,57.954a1.142,1.142,0,0,1-1.746-1.473l13.7-16.242a1.142,1.142,0,0,1,1.545-.187L84.584,56.534l21.863,4.859,12.505-17.854a1.142,1.142,0,1,1,1.871,1.31L107.88,63.328A1.141,1.141,0,0,1,106.944,63.815Z" transform="translate(1155.686 311.131)" fill="#33353e"></path>
                                                        <path id="Path_139" data-name="Path 139" d="M106.945,131.56a1.139,1.139,0,0,1-.414-.078L83.69,122.6a1.135,1.135,0,0,1-.22-.114L61.452,107.807,48.408,122.3a1.142,1.142,0,0,1-1.7-1.528l13.7-15.227a1.142,1.142,0,0,1,1.482-.186l22.738,15.158,22.162,8.619,12.446-8.541a1.142,1.142,0,1,1,1.292,1.883l-12.943,8.882a1.143,1.143,0,0,1-.646.2Z" transform="translate(1155.686 271.222)" fill="#fbbdb2"></path>
                                                        <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.665" cy="2.665" r="2.665" transform="translate(1272.909 352.661)" fill="#a2a2a2"></circle>
                                                        <circle id="Ellipse_8" data-name="Ellipse 8" cx="2.665" cy="2.665" r="2.665" transform="translate(1200.499 365.949)" fill="#a2a2a2"></circle>
                                                        <circle id="Ellipse_9" data-name="Ellipse 9" cx="2.665" cy="2.665" r="2.665" transform="translate(1214.228 349.441)" fill="#a2a2a2"></circle>
                                                        <circle id="Ellipse_10" data-name="Ellipse 10" cx="2.665" cy="2.665" r="2.665" transform="translate(1237.091 366.334)" fill="#a2a2a2"></circle>
                                                        <circle id="Ellipse_11" data-name="Ellipse 11" cx="2.665" cy="2.665" r="2.665" transform="translate(1259.953 371.475)" fill="#a2a2a2"></circle>
                                                        <circle id="Ellipse_12" data-name="Ellipse 12" cx="2.665" cy="2.665" r="2.665" transform="translate(1200.499 390.111)" fill="#f02600"></circle>
                                                        <circle id="Ellipse_13" data-name="Ellipse 13" cx="2.665" cy="2.665" r="2.665" transform="translate(1272.909 390.111)" fill="#f02600"></circle>
                                                        <circle id="Ellipse_14" data-name="Ellipse 14" cx="2.665" cy="2.665" r="2.665" transform="translate(1214.228 374.688)" fill="#f02600"></circle>
                                                        <circle id="Ellipse_15" data-name="Ellipse 15" cx="2.665" cy="2.665" r="2.665" transform="translate(1237.091 390.111)" fill="#f02600"></circle>
                                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="2.665" cy="2.665" r="2.665" transform="translate(1259.953 399.107)" fill="#f02600"></circle>
                                                        <g id="Group_98" data-name="Group 98" transform="translate(1199.738 348.68)">
                                                            <path id="Path_140" data-name="Path 140" d="M233.843,49.143a3.426,3.426,0,1,1,3.426-3.426A3.43,3.43,0,0,1,233.843,49.143Zm0-5.33a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,233.843,43.814Z" transform="translate(-158.007 -39.071)" fill="#33353e"></path>
                                                            <path id="Path_141" data-name="Path 141" d="M43.843,83.352a3.426,3.426,0,1,1,3.426-3.426A3.43,3.43,0,0,1,43.843,83.352Zm0-5.33a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,43.843,78.023Z" transform="translate(-40.417 -60.258)" fill="#33353e"></path>
                                                            <path id="Path_142" data-name="Path 142" d="M79.843,40.685a3.426,3.426,0,1,1,3.426-3.426A3.43,3.43,0,0,1,79.843,40.685Zm0-5.33a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,79.843,35.356Z" transform="translate(-62.697 -33.833)" fill="#33353e"></path>
                                                            <path id="Path_143" data-name="Path 143" d="M139.843,84.352a3.426,3.426,0,1,1,3.426-3.426A3.43,3.43,0,0,1,139.843,84.352Zm0-5.329a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,139.843,79.023Z" transform="translate(-99.831 -60.877)" fill="#33353e"></path>
                                                            <path id="Path_144" data-name="Path 144" d="M199.843,97.685a3.426,3.426,0,1,1,3.426-3.426A3.43,3.43,0,0,1,199.843,97.685Zm0-5.33a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,199.843,92.356Z" transform="translate(-136.965 -69.134)" fill="#33353e"></path>
                                                            <path id="Path_145" data-name="Path 145" d="M43.843,146.019a3.426,3.426,0,1,1,3.426-3.426A3.43,3.43,0,0,1,43.843,146.019Zm0-5.329a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,43.843,140.69Z" transform="translate(-40.417 -99.069)" fill="#33353e"></path>
                                                            <path id="Path_146" data-name="Path 146" d="M233.843,146.019a3.426,3.426,0,1,1,3.426-3.426A3.43,3.43,0,0,1,233.843,146.019Zm0-5.329a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,233.843,140.69Z" transform="translate(-158.007 -99.069)" fill="#33353e"></path>
                                                            <path id="Path_147" data-name="Path 147" d="M79.843,106.019a3.426,3.426,0,1,1,3.426-3.426A3.43,3.43,0,0,1,79.843,106.019Zm0-5.33a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,79.843,100.69Z" transform="translate(-62.697 -74.296)" fill="#33353e"></path>
                                                            <path id="Path_148" data-name="Path 148" d="M139.843,146.019a3.426,3.426,0,1,1,3.426-3.426A3.43,3.43,0,0,1,139.843,146.019Zm0-5.329a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,139.843,140.69Z" transform="translate(-99.831 -99.069)" fill="#33353e"></path>
                                                            <path id="Path_149" data-name="Path 149" d="M199.843,169.352a3.426,3.426,0,1,1,3.426-3.426A3.43,3.43,0,0,1,199.843,169.352Zm0-5.329a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,199.843,164.023Z" transform="translate(-136.965 -113.519)" fill="#33353e"></path>
                                                        </g>
                                                    </g>
                                                </svg>

                                                <div class="media-body">
                                                    <h5 class="mt-0">0<span>New SIP</span></h5>
                                                    <p>0.00 INR</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <div className="row mb-3">
                <div className="col-xl-4 pr-2">
                    <div className="dashboard-graf-box" id="activeClientDailyStatusCard">
                        <div className="heading">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-7">
                                        <h2 class="font-12 mx-0">clients</h2>
                                    </div>
                                    <span className="col-5 text-right nc-sip">
                                        <a href="#" class="btn btn-sip-vr"><i class="fa fa-cloud-download" aria-hidden="true"></i> Download Report</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-graf-box-inner text-center ">

                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />


                            <div className="graf-point-box ">
                                <ul className="p-0">
                                    <span class="graf-point point-yellow"></span>
                                    <li data-toggle="tooltip" data-placement="top" title="" data-original-title="Online means clients registered with online platform.">Online</li>
                                    <span class="graf-point point-green"></span>
                                    <li data-toggle="tooltip" data-placement="top" title="" data-original-title="New means recently added client">New</li>
                                    <span class="graf-point point-gray"></span>
                                    <li data-toggle="tooltip" data-placement="top" title="" data-original-title="Active means clients having AUM.">Active</li>
                                    <span class="graf-point point-red"></span>
                                    <li data-toggle="tooltip" data-placement="top" title="" data-original-title="Inactive means clients having zero AUM.">InActive</li>
                                </ul>

                            </div>
                        </div>

                        <div className="graf-progress pb-1">
                            <ul className="client-circle-graph">
                                <li onClick={() => handleMonthClick('Mar')} className="list-group-item">
                                    <button className={`btn btn-sm client-count ${activeMonth === 'Mar' ? 'active' : ''}`}  >Mar</button>
                                </li>
                                <li onClick={() => handleMonthClick('Apr')} classname="list-group-item">
                                    <button className={`btn btn-sm client-count ${activeMonth === 'Apr' ? 'active' : ''}`}>Apr</button>
                                </li>
                                <li onClick={() => handleMonthClick('May')} classname="list-group-item">
                                    <button className={`btn btn-sm client-count ${activeMonth === 'May' ? 'active' : ''}`}>May</button>
                                </li>
                                <li onClick={() => handleMonthClick('Jun')} classname="list-group-item">
                                    <button className={`btn btn-sm client-count ${activeMonth === 'Jun' ? 'active' : ''}`}>Jun</button>
                                </li>
                            </ul>

                        </div>
                    </div>

                </div>
                <div className="col-xl-4 px-2">
                    <div className="dashboard-graf-box-business" id="businessStatusCard">
                        <div class="heading">
                            <div class="col-12">
                                <div class="row align-items-center">
                                    <div class="col-7">
                                        <h2 class="font-12 mx-0">sip business chart</h2>
                                    </div>
                                    <span class="col-5 text-right nc-sip">
                                        <a href="#" target="_blank" class="btn btn-sip-vr">View Report</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-graf-box-business-inner text-center">
                            <div className="card-body text-center p-3">
                                <HighchartsReact highcharts={Highcharts} options={businessChartOptions} />

                            </div>


                        </div>
                        <div className="graf-point-text-box">
                            <div className="graph-box-footer">
                                <div className="gb-footer-section" id="sipDetailHint">
                                    <div id="sipDetailHint1" className="gb-footer-left">
                                        <div className="gb-row">
                                            <span class="graf-point point-blue"></span>
                                            <span className="px-2"><strong>Running</strong></span>
                                            <span><strong>0</strong></span>
                                        </div>
                                        <div className="gb-row">
                                            <span class="graf-point point-light-green"></span>
                                            <span className="px-2"><strong>Started</strong></span>
                                            <span><strong>0</strong></span>
                                        </div>
                                    </div>
                                    <div id="sipDetailHint2" className="gb-footer-right">
                                        <div className="gb-row">
                                            <span class="graf-point point-red"></span>
                                            <span className="px-2"><strong>Inactive</strong></span>
                                            <span><strong>0</strong></span>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div className="col-xl-4 px-2">
                    <div className="dashboard-graf-box-business" id="businessStatusCard">
                        <div class="heading">
                            <div class="col-12">
                                <div class="row align-items-center">
                                    <div class="col-7">
                                        <h2 class="font-12 mx-0">MONTHLY MIS</h2>
                                    </div>
                                    <span class="col-5 text-right nc-sip">
                                        <a href="#" target="_blank" class="btn btn-sip-vr">View Report</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-graf-box-business-inner text-center">
                            <div className="card-body text-center p-3">
                                <HighchartsReact highcharts={Highcharts} options={optionsMonthlyMIS} />

                            </div>


                        </div>
                        <div className="graf-point-text-box">
                            <div className="graph-box-footer">
                                <div className="gb-footer-section" id="sipDetailHint">
                                    <div id="sipDetailHint1" className="gb-footer-left">
                                        <div className="gb-row">
                                            <span class="graf-point point-blue"></span>
                                            <span className="px-2"><strong>Running</strong></span>
                                            <span><strong>0</strong></span>
                                        </div>
                                        <div className="gb-row">
                                            <span class="graf-point point-light-green"></span>
                                            <span className="px-2"><strong>Started</strong></span>
                                            <span><strong>0</strong></span>
                                        </div>
                                    </div>
                                    <div id="sipDetailHint2" className="gb-footer-right">
                                        <div className="gb-row">
                                            <span class="graf-point point-red"></span>
                                            <span className="px-2"><strong>Inactive</strong></span>
                                            <span><strong>0</strong></span>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
            <div className="row mb-3">
                <div className="col-xl-8 pr-2">
                    <div id="upcomingBusinessOpportunitiesCard" className="business-table" data-access="true">
                        <div className="heading">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-7">
                                        <h2 class="font-12 mx-0">UPCOMING BUSINESS OPPORTUNITIES</h2>
                                    </div>
                                    <span className="col-5 text-right nc-sip">
                                        <a href="#" class="btn btn-sip-vr"><i class="fa fa-cloud-download" aria-hidden="true"></i> View Report</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="slimScrollDiv" style={{ position: 'relative', overflow: 'hidden', width: 'auto', height: '349.8px' }}>
                            <div className="business-table-inner upcomingbuss-oppor nbt" style={{ overflow: 'hidden', width: 'auto', height: '349.8px' }}>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "240px" }}>
                                                <p>Mukesh Verma </p>
                                                <div class="d-flex">
                                                    <span class="border-right-light-dark pr-1">AEQPV9718Q</span>
                                                    <span class="pl-1">9826870994</span>
                                                </div>
                                            </td>
                                            <td style={{ width: "240px" }}>
                                                <p>House Planning </p>
                                                <div className="d-flex">
                                                    <span class="pl-1">Kotak</span>
                                                </div>
                                            </td>
                                            <td className="text-right" style={{ minWidth: "inherit" }}>
                                                <p><i class="fa fa-inr text-black" aria-hidden="true"></i>6,00,000.00</p>
                                                <span>By :01 Feb 2025</span>
                                            </td>
                                            <td style={{ width: "100px" }} className="d-flex justify-content-center">
                                                <button className="btn btn-sip-vr">Open Time</button>
                                            </td>
                                            <td>
                                                <span class="business-user-type" data-toggle="tooltip" data-placement="top" data-original-title="Offline">
                                                    <i class="fa-solid fa-file-lines"></i>
                                                </span>
                                            </td>
                                            <td>
                                                <span class="business-user-type" data-toggle="tooltip" data-placement="top" data-original-title="Offline">
                                                    <i class="fa-solid fa-bell"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 pr-2">
                    <div id="sipOpportunitiesCard" className="sip-opportunities-box" data-access="true">
                        <div className="heading">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-7">
                                        <h2 className="font-12 mx-0">Opportunities</h2>
                                    </div>
                                    <span className="col-5 text-right nc-sip">
                                        <a href="#" target="_blank" className="btn btn-sip-vr">View Report</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="sip-opportunities-box-inner nbt">
                            <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="m-auto d-flex">
                                            <img src={carouselbg} alt="" />
                                        </div>
                                        <div class="carousel-caption d-none d-md-block">
                                            <span class="mt-4">TOTAL SIP</span>
                                            <h2 class="mt-1 mb-3">00</h2>
                                            <span class="undeline oppo-caro-underline"></span>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="m-auto d-flex">
                                            <img src={carouselbg} alt="" />
                                        </div>
                                        <div class="carousel-caption d-none d-md-block">
                                            <span class="mt-4">TOTAL LUMPSUM</span>
                                            <h2 class="mt-1 mb-3">6,00,000.00</h2>
                                            <span class="undeline oppo-caro-underline"></span>
                                        </div>
                                    </div>
                                </div>
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-xl-8 pr-2">
                    <div id="healthTableCard" className="health-table">
                        <form role="form" action="mail-back-file" class="horiz-form family-reposrt-form mb-0" id="loadArnWiseDataId">
                            <div className="heading">
                                <div className="col-12">
                                    <div className="row align-items-center">
                                        <div class="col">
                                            <h2 class="font-12 mx-0">mailback file status (of 30 Days)</h2>
                                        </div>
                                        <span class="col-auto text-right nc-sip d-flex pr-0">
                                            <a href="#" target="_blank" class="btn btn-sip-vr btn-sip-vr-green mr-2">Upload Data Files</a>
                                            <a href="#" target="_blank" class="btn btn-sip-vr">Uploaded File's Report</a>
                                        </span>
                                        <span class="col-auto text-right nc-sip">
                                            <select class="form-control shmfus-select ml-auto" id="loadArnWiseDataValue" fdprocessedid="txbqsi">
                                                <option value="9999999">ARN-9999999</option>
                                                <option value="9999998">ARN-9999998</option>
                                                <option value="69442">ARN-69442</option>
                                            </select>
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </form>
                        <div className="no-record-section">
                            <div className="no-record-img">
                                <div className="no-record-msg">
                                <span>No record found</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-xl-4 pl-2">
                    <div id="customDataTableCard" className="custom-data-tables">
                    <div class="heading">
                    <h2 class="font-12">data Diagnosis</h2>
                    </div>
                    <div className="custom-data-tables-inner">
                        <div className="custom-data-table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th>Rta</th>
                                    <th>AVG. Gap</th>
                                    <th>status</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>CAMS</td>
                                    <td>0 Days</td>
                                    <td style={{color:'#08a345', fontSize:'20px'}} className="text-center"><i class="fa-solid fa-heart"></i></td>
                                    <td><button type="button" class="btn point-green" data-toggle="modal" fdprocessedid="0dd0sv">Not Required</button></td>
                                    </tr>
                                    <tr>
                                    <td>KARVY</td>
                                    <td>0 Days</td>
                                    <td style={{color:'#08a345', fontSize:'20px'}} className="text-center"><i class="fa-solid fa-heart"></i></td>
                                    <td><button type="button" class="btn point-green" data-toggle="modal" fdprocessedid="0dd0sv">Not Required</button></td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>

                    </div>
                    </div>

                </div>

            </div>
            <div className="row">
                <div className="col text-center">
                <p class="fs-i-12 mb-3 bb">Download Advisor X Mobile Application.</p>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <div className="app-download-btn-group text-center">
                        <a href="#" target="_blank">
                        <img src={androidDownload} alt="Android Button" width="150"/>
                        </a>
                        <a href="#" target="_blank">
                        <img src={iosDownload} alt="Android Button" width="150"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
       
        </>
    );
};

export default Content;
