import React, { useState } from 'react';
import Navbar from '../../../Navbar';
import Header from '../../../Header';
import Footer from '../../../Footer';
import { FaGlobe, FaFileExcel } from 'react-icons/fa';
const GIRenewPolicy = () => {
    const [activeTab, setActiveTab] = useState('monthWise');
    const [selectedOutput, setSelectedOutput] = useState('web');
    const [selectedOption, setSelectedOption] = useState('searchClient');

    const handleOutputSelect = (outputType) => {
        setSelectedOutput(outputType);
    };
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };
    return (
        <>
            <Navbar />
            <Header />
            <div className="container mt-4">
                <div className="row mb-3">
                    <div className="col">
                        <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>

                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'monthWise' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('monthWise')}
                                    >
                                        SELECTION BY MONTH WISE
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'policyNumber' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('policyNumber')}
                                    >
                                        SELECTION BY POLICY NUMBER
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'clientWise' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('clientWise')}
                                    >
                                        SELECTION BY CLIENT WISE
                                    </button>
                                </li>
                            </ul>

                            <div className="tab-content mt-3">
                                {activeTab === 'monthWise' && (
                                    <div className="row">
                                        <div className="col-md-4 ">
                                            <div className='d-flex mb-2'>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input mt-1"
                                                        type="radio"
                                                        name="clientOption"
                                                        id="searchClient"
                                                        value="searchClient"
                                                        checked={selectedOption === 'searchClient'}
                                                        onChange={handleOptionChange}
                                                        defaultChecked
                                                    />
                                                    <label className="form-check-label" htmlFor="searchClient">
                                                        Search Client / PAN...
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input mt-1"
                                                        type="radio"
                                                        name="clientOption"
                                                        id="allClient"
                                                        value="allClient"
                                                        checked={selectedOption === 'allClient'}
                                                        onChange={handleOptionChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="allClient">
                                                        All Client
                                                    </label>
                                                </div>
                                            </div>
                                            {selectedOption === 'searchClient' && (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search Client"
                                                />
                                            )}
                                        </div>


                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="fromDate">From Date</label>
                                                <input type="date" className="form-control" id="fromDate" />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="toDate">To Date</label>
                                                <input type="date" className="form-control" id="toDate" />
                                            </div>
                                        </div>

                                        <div className="col-md-2 d-flex flex-column">
                                            <label className="me-2">Output In</label>
                                            <div className="btn-group" role="group">
                                                <button
                                                    type="button"
                                                    className={`btn ${selectedOutput === 'web' ? 'btn-info' : 'btn-light'}`}
                                                    onClick={() => handleOutputSelect('web')}
                                                >
                                                    <FaGlobe size={20} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn ${selectedOutput === 'excel' ? 'btn-info' : 'btn-light'}`}
                                                    onClick={() => handleOutputSelect('excel')}
                                                >
                                                    <FaFileExcel size={20} />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <button type="submit" className="btn btn-success">
                                                    Show
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                )}

                                {activeTab === 'policyNumber' && (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h6>Search A Client</h6>
                                                <select className="form-select mb-3">
                                                    <option>Select</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <button className="btn btn-success">Show</button>
                                        </div>
                                    </div>
                                )}

                                {activeTab === 'clientWise' && (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-4 p-3 ">
                                                <h6>Search A Client</h6>
                                                <select className="form-select mb-3">
                                                    <option>Select</option>
                                                </select>
                                            </div>
                                            <div className="col-md-4 p-3 ">
                                                <h6>Select Policy</h6>
                                                <select className="form-select mb-3">
                                                    <option>Select</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="">
                                            <button className="btn btn-success">Show</button>
                                        </div>
                                    </div>
                                )}


                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default GIRenewPolicy;
