import React, { useState } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import { FaGlobe, FaWhatsapp, FaCalendarAlt } from 'react-icons/fa';
import { MdOutlineTextsms,MdEmail  } from "react-icons/md";

const BetweenDataXIRR = () => {
  const [selectedOutput, setSelectedOutput] = useState('web');

  const handleOutputSelect = (outputType) => {
    setSelectedOutput(outputType);
  };
  return (
    <>
      <Navbar />
      <Header />
    <div className="container mt-4">
      <div className="row mb-3">
        <div className="col">
          <div className="rounded content-start p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
            <div className='row'>
            <div className='col-md-6'>
            <form>
              <div className="mb-3">
                <label htmlFor="selectView" className="form-label">Select View By :</label>
                <select id="selectView" className="form-select">
                  <option value=''>Select</option>
                  <option value='client'>Client</option>
                  <option value='family'>Family</option>

                </select>
              </div>
              
              <div className="mb-3">
                <label htmlFor="searchClient" className="form-label">Search By Name / PAN / Folio No. :</label>
                <input type="text" className="form-control" id="searchClient" placeholder="Search client" />
              </div>
              
              <div className="mb-3">
                <label className="form-label">Duration Choose By :</label>
                <div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="durationChoose" id="manually" value="manually" />
                    <label className="form-check-label" htmlFor="manually">Manually</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="durationChoose" id="sensexGraph" value="sensexGraph" />
                    <label className="form-check-label" htmlFor="sensexGraph">Sensex Graph</label>
                  </div>
                </div>
              </div>
              
              <div className="mb-3">
                <label htmlFor="fromDate" className="form-label">From Date :</label>
                <div className="input-group">
                  <input type="text" className="form-control" id="fromDate" />
                  <span className="input-group-text"><FaCalendarAlt /></span>
                </div>
              </div>
              
              <div className="mb-3">
                <label htmlFor="toDate" className="form-label">To Date :</label>
                <div className="input-group">
                  <input type="text" className="form-control" id="toDate" />
                  <span className="input-group-text"><FaCalendarAlt /></span>
                </div>
              </div>
              
              <div className="mb-3">
                <label htmlFor="selectFundType" className="form-label">Select Fund Type :</label>
                <select id="selectFundType" className="form-select">
                  <option value=''>Select</option>
                  <option value='all'>All</option>
                  <option value='equity'>Equity</option>
                  <option value='debt'>Debt</option>
                  <option value='hybrid'>Hybrid</option>
                  <option value='sol_oriented'></option>
                  <option value='debt'>Debt</option>


                </select>
              </div>
              
              <div className="mb-3 d-flex flex-column">
                <label className="form-label">Out Put In :</label>
                <div className="btn-group" role="group">
                      <button
                        type="button"
                        className={`btn ${selectedOutput === 'web' ? 'btn-info' : 'btn-light'} px-4`}
                        onClick={() => handleOutputSelect('web')}
                      >
                        <FaGlobe size={20} />
                      </button>
                      <button
                        type="button"
                        className={`btn ${selectedOutput === 'sms' ? 'btn-info' : 'btn-light'}  px-4`}
                        onClick={() => handleOutputSelect('sms')}
                      >
                        <MdOutlineTextsms size={20} />
                      </button>
                      <button
                        type="button"
                        className={`btn ${selectedOutput === 'mail' ? 'btn-info' : 'btn-light'}  px-4`}
                        onClick={() => handleOutputSelect('mail')}
                      >
                        <MdEmail  size={20} />
                      </button>
                      <button
                        type="button"
                        className={`btn ${selectedOutput === 'whatsApp' ? 'btn-info' : 'btn-light'}  px-4`}
                        onClick={() => handleOutputSelect('whatsApp')}
                      >
                        <FaWhatsapp size={20} />
                      </button>
                    </div>
              </div>
              
              <button type="submit" className="btn btn-success">Show</button>
            </form>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />

    </>
  );
}

export default BetweenDataXIRR;