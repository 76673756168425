import React from 'react';
import Navbar from '../../../Navbar';
import Header from '../../../Header';
import Footer from '../../../Footer';

const AddInvestment = () => {
  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row">
          <div className="col-6">
            <div
              className="rounded p-lg-4 p-3"
              style={{
                backgroundColor: '#f8f9fa', 
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
              }}
            >
              <div className="mb-3">
                <label htmlFor="investorName" className="form-label">
                  Name Of Investor <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="investorName"
                  placeholder=""
                />
              </div>

              <div className="mb-3">
                <label htmlFor="issuerType" className="form-label">
                  Issuer Type <span className="text-danger">*</span>
                </label>
                <select className="form-select" id="issuerType">
                  <option>Select Type</option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="issuer" className="form-label">
                  Issuer <span className="text-danger">*</span>
                </label>
                <select className="form-select" id="issuer">
                  <option>Select Issuer</option>
                </select>
                <a href="#" className="text-primary mt-1 d-block">
                  Add New
                </a>
              </div>

              <div className="d-flex justify-content-start">
                <button className="btn btn-success me-2">Add FD/Bond</button>
                <button className="btn btn-secondary">Reset</button>
              </div>
            </div>
          </div>
          <div className="col-6"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddInvestment;
