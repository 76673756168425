import React from 'react'
import Navbar from '../Navbar'
import Header from '../Header'
import Footer from '../Footer'
import '../../assets/css/Pages css/CRM.css'
import Laptop from '../../assets/Images/CRM/laptop.png'
import Bulk from '../../assets/Images/CRM/network.png'
import Client from '../../assets/Images/CRM/analysis.png'
import SentSMS from '../../assets/Images/CRM/send_sms.png'

import Event from '../../assets/Images/CRM/solution.png'
import Bouquet from '../../assets/Images/CRM/bouquet.png'
import Vault from '../../assets/Images/CRM/e-vault.png'

import webSetting from '../../assets/Images/CRM/web-setting.png'
import appSetting from '../../assets/Images/CRM/app-setting.png'

import pushNotification from '../../assets/Images/CRM/push-notification.png'
import notificationList from '../../assets/Images/CRM/notification-list.png'
import aboutUs from '../../assets/Images/CRM/about-us.png'
import termsConditions from '../../assets/Images/CRM/terms&conditions.png'
import mobileSetting from '../../assets/Images/CRM/mobile-application.png'
import roboAdvisory from '../../assets/Images/CRM/robo-advisory.png'
import uploadApp from '../../assets/Images/CRM/app-banner.png'
import { Link } from 'react-router-dom'


const CRM = () => {
    const cardData = [
        {
            title: "CRM",
            icon: Laptop,
            content: "Setting",
            link: '/UserManager/crm/crm-setting'
        },
        {
            title: "Bulk",
            icon: Bulk,
            content: "Assign",
             link: '/UserManager/crm/bulk-assigner'
        },
        {
            title: "Client",
            icon: Client,
            content: "Analysis",
             link: '/UserManager/crm/client-analysis'
        },
        {
            title: "Sent",
            icon: SentSMS,
            content: "SMS",
             link: '/UserManager/crm/crm-setting'
        }
    ];

    const cardData2 = [
        {
            title: "Event",
            icon: Event,
            content: "Calender",
            link:'/UserManager/crm/event-calender'
        },
        {
            title: "Bouquet",
            icon: Bouquet,
            content: "Manager",
             link: '/UserManager/crm/bouquet-dashboard'
        },
        {
            title: "E-Vault",
            icon: Vault,
        }
    ]

    const cardData3 = [
        {
            title: "Web",
            icon: webSetting,
            content: "Setting"
        },
        {
            title: "App",
            icon: appSetting,
            content: "Setting"
        }
    ]

    const cardData4 = [
        {
            title: "Push",
            icon: pushNotification,
            content: "Notification"
        },
        {
            title: "Notification",
            icon: notificationList,
            content: "List"
        },
        {
            title: "About Us",
            icon: aboutUs,
            link:'/UserManager/crm/about-us'
        },
        {
            title: "Terms &",
            icon: termsConditions,
            content: "Conditions",
            link: '/UserManager/crm/term-and-conditions'

        },
        {
            title: "Mobile Application",
            icon: mobileSetting,
            content: "Setting"
        },
        {
            title: "Robo",
            icon: roboAdvisory,
            content: "Advisory"
        },
        {
            title: "Upload App",
            icon: uploadApp,
            content: "Banner"
        }
    ]

  return (
    <>
    <Navbar/>
    <Header/>
    <div className='content-wrapper position-relative'>
        <div id='changesWidth' className='container'>
            <div id='main-content-wrapper' className='container-fluid'>
                <div className='categories-heading'>
                    <div className='category-container'>
                        <div className='category-title'>
                            CRM
                        </div>
                        <span class="category-line"></span>
                    </div>
                </div>
                <div className='row nc-dash-padd-lr'>
                {cardData.map((card, index) => (
                            <div key={index} className="col-md-3 mf-bx-sm CRM_card">
                                <Link to={card.link} className="mf-dashbox nc-mf-dashbox">
                                    <div className="icon">
                                        <img className="item-icon" src={card.icon} alt=""/>
                                    </div>
                                    <div className="card-menu-content">
                                        <h4>{card.title}</h4>
                                        <p>{card.content}</p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                </div>
                <div className='categories-heading'>
                    <div className='category-container'>
                        <div className='category-title'>
                        Advisor Tools
                        </div>
                        <span class="category-line"></span>
                    </div>
                </div>
                <div className='row nc-dash-padd-lr'>
                {cardData2.map((card, index) => (
                            <div key={index} className="col-md-3 mf-bx-sm CRM_card">
                                <Link to={card.link} className="mf-dashbox nc-mf-dashbox">
                                    <div className="icon">
                                        <img className="item-icon" src={card.icon} alt=""/>
                                    </div>
                                    <div className="card-menu-content">
                                        <h4>{card.title}</h4>
                                        <p>{card.content}</p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                </div>
                <div className='categories-heading'>
                    <div className='category-container'>
                        <div className='category-title'>
                       Color Theme Management
                        </div>
                        <span class="category-line"></span>
                    </div>
                </div>
                <div className='row nc-dash-padd-lr'>
                {cardData3.map((card, index) => (
                            <div key={index} className="col-md-3 mf-bx-sm CRM_card">
                                <Link to={card.link}  className="mf-dashbox nc-mf-dashbox">
                                    <div className="icon">
                                        <img className="item-icon" src={card.icon} alt=""/>
                                    </div>
                                    <div className="card-menu-content">
                                        <h4>{card.title}</h4>
                                        <p>{card.content}</p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                </div>
                <div className='categories-heading'>
                    <div className='category-container'>
                        <div className='category-title'>
                        Mobile App Management
                        </div>
                        <span class="category-line"></span>
                    </div>
                </div>
                <div className='row nc-dash-padd-lr'>
                {cardData4.map((card, index) => (
                            <div key={index} className="col-md-3 mf-bx-sm CRM_card">
                                <Link to={card.link} className="mf-dashbox nc-mf-dashbox">
                                    <div className="icon">
                                        <img className="item-icon" src={card.icon} alt=""/>
                                    </div>
                                    <div className="card-menu-content">
                                        <h4>{card.title}</h4>
                                        <p>{card.content}</p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                </div>
            </div>
        </div>

    </div>
    <Footer />

    </>
  )
}

export default CRM