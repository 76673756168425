import React, { useState } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';

const VendorBilling = () => {
  return (
    <>
     <Navbar/>
     <Header/>
     <div className="container mt-4">
        {/* Form */}
        <div className="card p-3 mb-4" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
            <h6>Add Item of Vendor</h6>
          <div className="row">
            <div className="col-md-4 mt-3">
              <label className="form-label">Select Month of Billing</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Select Vendor</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="col-md-4 mt-3">
              <label className="form-label">Select Vendor</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Select Vendor</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3'>
              <button className="btn btn-success mt-3">
                Search
              </button>
            </div>
          </div>
        </div>
        </div>
    </>
  )
}

export default VendorBilling