import React from 'react'
import { Routes, Route } from 'react-router-dom';
import PMS from '../pages/AssetsNav/PMSDashboard';
import FDbond from '../pages/AssetsNav/FDBonds';
import Postal from '../pages/AssetsNav/Postal';
import Equity from '../pages/AssetsNav/Equity';
import Commodity from '../pages/AssetsNav/Commodity';
import RealEstate from '../pages/AssetsNav/RealEstate';
import PMSNewBusiness from '../pages/AssetsNav/PMSDashboardPages/PMSNewBusiness';
import PMSValuation from '../pages/AssetsNav/PMSDashboardPages/PMSValuation';
import PMSUpdateValue from '../pages/AssetsNav/PMSDashboardPages/PMSUpdateValue'
import PMSDeleteTrans from '../pages/AssetsNav/PMSDashboardPages/PMSDeleteTrans'
import PMSBulkUpload from '../pages/AssetsNav/PMSDashboardPages/PMSBulkUpload'
import AddInvestment from '../pages/AssetsNav/FDBondDashboard/AddInvestment'
import ViewUpdateInvestment from '../pages/AssetsNav/FDBondDashboard/ViewUpdateDeleteInvestment'
import MaturityList from '../pages/AssetsNav/FDBondDashboard/MaturityList';
import FDBondHolders from '../pages/AssetsNav/FDBondDashboard/FDBondHolders';
import Prematurity from '../pages/AssetsNav/FDBondDashboard/Permaturity';
import ShowPPF from '../pages/AssetsNav/FDBondDashboard/ShowPPF';
import EPFTable from '../pages/AssetsNav/FDBondDashboard/EPFTable';
import ManageRDTrans from '../pages/AssetsNav/FDBondDashboard/ManageRDTrans';
import BulkUpload from '../pages/AssetsNav/FDBondDashboard/UploadBulkFD';




const Assets = () => {
  return (
    <>
    <Routes>
        <Route path="/PMS/*" element={<PMSRoutes />} />
        <Route path="/FD/*" element={<FDbondRoutes />} />
         <Route path="/Postal/*" element={<PostalRoutes />} />
        <Route path="/Equity/*" element={<EquityRoutes />} />
        <Route path="/Commodity/*" element={<CommodityRoutes />} />
        <Route path="/RealEstate/*" element={<RealEstateRoutes />} />
    </Routes>   
    </>
    
  )
}
function PMSRoutes (){
  return (
    <>
      <Routes>
        <Route path="/pms-dashboard" element={<PMS />} />
        <Route path="/pms-new-business" element={<PMSNewBusiness />} />
        <Route path="/pms-valuation" element={<PMSValuation />} />
        <Route path="/update-current-value" element={<PMSUpdateValue />} />
        <Route path="/pms-delete-trans" element={<PMSDeleteTrans />} />
        <Route path="/upload-bulk-pms" element={<PMSBulkUpload />} />
      </Routes>
    </>
  )
}
function FDbondRoutes (){
  return (
    <>
      <Routes>
        <Route path="/fd-bond-dashboard" element={<FDbond />} />
        <Route path="/add-fd" element={<AddInvestment />} />
        <Route path="/update-fd-filter" element={<ViewUpdateInvestment />} />
        <Route path="/fd-maturity-filter" element={<MaturityList />} />
        <Route path="/fd-investors-filter" element={<FDBondHolders />} />
        <Route path="/fd-prematurity-filter" element={<Prematurity />} />
        <Route path="/fd-ppf-filter" element={<ShowPPF />} />
        <Route path="/fd-epf-filter" element={<EPFTable />} />
        <Route path="/fd-rd-filter" element={<ManageRDTrans />} />
        <Route path="/upload-bulk-fd" element={<BulkUpload />} />
      </Routes>
    </>
  )
}

function PostalRoutes (){
  return (
    <>
      <Routes>
        <Route path="/postal-dashboard" element={<Postal />} />

      </Routes>
    </>
  )
}

function EquityRoutes (){
  return (
    <>
      <Routes>
        <Route path="/equity-dashboard" element={<Equity />} />
      </Routes>
    </>
  )
}

function CommodityRoutes(){
  return (
    <>
      <Routes>
        <Route path="/commodity-dashboard" element={<Commodity />} />
      </Routes>
    </>
  )
}

function RealEstateRoutes(){
  return (
    <>
      <Routes>
        <Route path="/real-estate-dashboard" element={<RealEstate />} />
      </Routes>
    </>
  )
}

export default Assets