import React from 'react';
import Navbar from '../../../Navbar';
import Header from '../../../Header';
import Footer from '../../../Footer';

const GIEnterNewPolicy = () => {
  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-lg-4 p-3 my-3 bg-light" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'}}>
              <form>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="searchClient">Search Client / PAN</label>
                      <input type="text" className="form-control" id="searchClient" placeholder="Search client" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="nameOfProposer">Name of Proposer</label>
                      <input type="text" className="form-control" id="nameOfProposer" placeholder="Name of Proposer" />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="companyName">Company Name <span className='text-danger'>*</span></label>
                      <select className="form-select" id="companyName">
                        <option>Select Company Name</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="policyType">Policy Type <span className='text-danger'>*</span></label>
                      <select className="form-select" id="policyType">
                        <option>Select Scheme</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="newScheme">New Scheme</label>
                      <input type="text" className="form-control" id="newScheme" placeholder="New Scheme" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="policyNumber">Policy Number  <span className='text-danger'>*</span></label>
                      <input type="text" className="form-control" id="policyNumber" placeholder="Policy Number" />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="dob">Date of Birth  <span className='text-danger'>*</span></label>
                      <input type="date" className="form-control" id="dob" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="policyStartDate">Policy Start Date / DOC  <span className='text-danger'>*</span></label>
                      <input type="date" className="form-control" id="policyStartDate" />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="termInYears">Term (In Year) <span className='text-danger'>*</span></label>
                      <select className="form-select" id="termInYears">
                        <option>1</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="premiumAmount">Premium Amount  <span className='text-danger'>*</span></label>
                      <input type="text" className="form-control" id="premiumAmount" placeholder="Premium Amount" />
                    </div>
                  </div>
             

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="renewalDate">Renewal Date <span className='text-danger'>*</span></label>
                      <input type="date" className="form-control" id="renewalDate" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="riskCoverage">Risk Coverage <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="riskCoverage" placeholder="Risk Coverage" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="paymentMode">Payment Mode <span className="text-danger">*</span></label>
                      <select className="form-select" id="paymentMode">
                        <option>Cash</option>
                        <option>Bank Transfer</option>
                        <option>Credit/Debit Card</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="bankName">Bank Name</label>
                      <input type="text" className="form-control" id="bankName" placeholder="Bank Name" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="proposalNumber">Proposal Number <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="proposalNumber" placeholder="Proposal Number" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="nomineeName">Nominee Name</label>
                      <input type="text" className="form-control" id="nomineeName" placeholder="Nominee Name" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="relation">Relation</label>
                      <select className="form-select" id="relation">
                        <option>Select Relation</option>
                        <option>Spouse</option>
                        <option>Child</option>
                        <option>Parent</option>
                        <option>Sibling</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="remarks">Remarks</label>
                      <textarea className="form-control" id="remarks" style={{ resize: "none" }}></textarea>
                    </div>
                  </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <button type="submit" className="btn btn-success">Add Policy</button>
                    <button type="reset" className="btn btn-secondary ml-2">Reset</button>
                  </div>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GIEnterNewPolicy;
