import React from 'react'
import { useEffect } from 'react'
import Navbar from '../../Navbar'
import Header from '../../Header'
import Footer from '../../Footer'
import '../../../assets/css/Pages css/CommonCard.css'
import NewPolicy from '../../../assets/Images/Insurance/new-insurance.png'
import PMSInvestment from '../../../assets/Images/Insurance/PMS Investment.png'
import updatePolicy from '../../../assets/Images/Insurance/liUpdate.png'
import bulkUpload from '../../../assets/Images/Insurance/bulkUpload.png'
import RenewPolicy from '../../../assets/Images/Insurance/renewPolicy.png'


const PMSDashboard = () => {
  
  const cardData = [
    { name: 'Enter New Investment', icon: NewPolicy, link: '/Assets/pms/pms-new-business' },
    { name: 'PMS and Alt Investment Valuation', icon: PMSInvestment, link: '/Assets/pms/pms-valuation' },
    { name: 'Update Current Value', icon: updatePolicy, link: '/Assets/pms/update-current-value' },
    { name: 'Update / Delete Transaction', icon: RenewPolicy, link: '/Assets/pms/pms-delete-trans' },
    { name: 'Bulk Upload', icon: bulkUpload, link: '/Assets/pms/upload-bulk-pms' }
]
useEffect(() => {
  document.body.classList.add('no-scroll');
  return () => {
    document.body.classList.remove('no-scroll');
  };
}, []);
  return (
    <>
    <Navbar/>
    <Header/>
    <div className='content-wrapper position-relative mt-3 d-flex flex-column' style={{ minHeight: 'calc(100vh - 152px)'}}>
        <div className='container' id='changesWidth'>
          <div id="main-content-wrapper" className='container-fluid'>
            <div className='container-fluid'>
              <div className='row'>
                {cardData.map((card, index) => (
                  <div key={index} className='col-lg-4 col-md-6'>
                    <div className='card-menu'>
                      <div className='icon'>
                        <img src={card.icon} alt={card.name} height={54}/>
                      </div>
                      <div className='card-menu-content'>
                        <h4>{card.name}</h4>
                      </div>
                      <a href={card.link} className='card-menu-link'></a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer sticlyFooter={true}/>
      </div>

    </>
  )
}
export default PMSDashboard