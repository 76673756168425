import React from 'react'
import '../assets/css/footer.css';

const Footer = ({sticlyFooter}) => {
  return (

<footer className="sticky-footer custom-black-bg mt-auto" style={{ marginBottom: sticlyFooter?  '50px': '0px' }}>
    <div className="container">
        <div className="row">
            <div className="col-xl-12">
            <div class="text-center py-3">
                <small>Use Google Chrome for best results. Technology Partner</small>
            </div>
            </div>
        </div>
    </div>
</footer>

  )
}
export default Footer
