import React from 'react'
import { useEffect } from 'react'
import Navbar from '../../Navbar'
import Header from '../../Header'
import Footer from '../../Footer'
import '../../../assets/css/Pages css/CommonCard.css'
// import LiveEquity from '../../../assets/Images/Insurance/'
import BuyEquity from '../../../assets/Images/Insurance/card-img-4.png'
import SellEquity from '../../../assets/Images/Insurance/card-img-5.png'
import CorAction from '../../../assets/Images/Insurance/card-img-6.png'
import ViewDelete from '../../../assets/Images/Insurance/liUpdate.png'
import DeleteEquity from '../../../assets/Images/Insurance/card-img-8.png'
import BulkTransiction from '../../../assets/Images/Insurance/card-img-9.png'
import EquityHolding from '../../../assets/Images/Insurance/liHolders.png'



const Equity = () => {
  
  const cardData = [
    { name: 'Live Equity Report', icon: CorAction, link: '#' },
    { name: 'Equity P&L Report', icon: CorAction, link: '#' },
    { name: 'Buy Equity', icon: BuyEquity, link: '#' },
    { name: 'Sell Equity', icon: SellEquity, link: '#' },
    { name: 'Corporate Action', icon: CorAction, link: '#' },
    { name: 'View / Delete Transactions', icon: ViewDelete, link: '#' },
    { name: 'Delete Equity', icon: DeleteEquity, link: '#' },
    { name: 'Add Bulk Equity Transactions', icon: BulkTransiction, link: '#' },
    { name: 'Equity Holding', icon: EquityHolding, link: '#' } 
   
]
useEffect(() => {
  document.body.classList.add('no-scroll');
  return () => {
    document.body.classList.remove('no-scroll');
  };
}, []);
  return (
    <>
    <Navbar/>
    <Header/>
    <div className='content-wrapper position-relative mt-3 d-flex flex-column' style={{ minHeight: 'calc(100vh - 152px)'}}>
        <div className='container' id='changesWidth'>
          <div id="main-content-wrapper" className='container-fluid'>
            <div className='container-fluid'>
              <div className='row'>
                {cardData.map((card, index) => (
                  <div key={index} className='col-lg-4 col-md-6'>
                    <div className='card-menu'>
                      <div className='icon'>
                        <img src={card.icon} alt={card.name} height={54}/>
                      </div>
                      <div className='card-menu-content'>
                        <h4>{card.name}</h4>
                      </div>
                      <a href={card.link} className='card-menu-link'></a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer sticlyFooter={true}/>
      </div>

    </>
  )
}
export default Equity