import React, { useState } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import { AgGridReact } from 'ag-grid-react';
import '../../../assets/css/CommonTable.css'
import CustomButtonComponent from './CustomButton';

const UpdateDeleteEmp = () => {
    const [employeeData, setEmloyeeData] = useState([
        { employee_name: 'John', employee_code: '123456', mobile_number: '7894561230', email_address: 'testing@123gmail.com', address: '123', _id:456456 },
      ]);
      const [searchQuery, setSearchQuery] = useState('');
      const columnDefs = [
        { headerName: 'Employee Name', field: 'employee_name', resizable: true, width: 80 },
        { headerName: 'Employee Code', field: 'employee_code', sortable: true, filter: true, resizable: true },
        { headerName: 'Mobile Number', field: 'mobile_number', sortable: true, filter: true, resizable: true },
        { headerName: 'Email Id', field: 'email_address', sortable: true, filter: true, resizable: true },
        { headerName: 'Address', field: 'address', sortable: true, filter: true, resizable: true },
        {
          headerName: 'Actions',
          field: 'actions',
          cellRenderer: (params) => (
            <CustomButtonComponent 
              data={params.data} 
              onEdit={() => handleEditEmployee(params.data)} 
              onDelete={() => handleDeleteEmployee(params.data)}  
            />
          ),
        },
      ];

      const handleSearch = (e) => {
        setSearchQuery(e.target.value);
      };
      const filteredData = employeeData.filter(employee =>
    
        (employee.employee_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
        (employee.mobile_number || '').includes(searchQuery) ||
        (employee.email_address?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
        (employee.address?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
        (employee.employee_code || '').includes(searchQuery)
      );

      const handleEditEmployee = (data) => {}
      const handleDeleteEmployee = (data) => {}
  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            {/* <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
                <h5>Select Employee</h5>
                <div className='my-3'>
                    <div className='d-flex gap-3'>
                    <div className="form-check">
                    <input className="form-check-input" type="radio" name="searchEmp" id="searchEmp1" checked/>
                    <label className="form-check-label" for="searchEmp1">
                    Search Employee
                    </label>
                    </div>
                    <div className="form-check">
                    <input className="form-check-input" type="radio" name="showAll" id="showAll2" />
                    <label className="form-check-label" for="showAll2">
                    Show All Employee List
                    </label>
                    </div>
                    </div>
                </div>
                <div className='col-5'>
                <input type="text" className="form-control" placeholder="" />
                </div>
            </div> */}

        <div className="px-4 py-4" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
        <h4>Employee List</h4>
        <div className="row">
          <div className='col-md-3 mb-2'>
          <input
            type="text"
            className="form-control"
            placeholder="Search Employee"
            value={searchQuery}
            onChange={handleSearch}
          />
          </div>
        </div>
        <div className="ag-theme-alpine" style={{ height: 'auto', width: '100%' }}>
          <AgGridReact
            rowData={filteredData}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={7}
            domLayout="autoHeight"
            defaultColDef={{
              flex: 1, 
              minWidth: 100,
              resizable: true, 
            }}
          />
        </div>
        </div>
          </div>
          
        </div>
        <Footer />
      </div>
    </>
  );
}

export default UpdateDeleteEmp;
