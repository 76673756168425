import React, { useState } from 'react'
import '../assets/css/Home.css'
import { Link } from 'react-router-dom'
import { BiLogoFacebook } from "react-icons/bi";
import { FaLinkedinIn } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaYoutube } from "react-icons/fa6";
import Logo from "../assets/Logo/gavfh6Hp_400x400.jpg"

const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
};

const images = importAll(require.context('../assets/Images/home', false, /\.(png|jpe?g|svg)$/));

const HomePage = () => {

  const [activeIndex, setActiveIndex] = useState(0);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };


  const links = [
    {
      name: 'home'
    },
    {
      name: 'FEATURES'
    },
    {
      name: 'ROBO ADVISORY'
    },
    {
      name: 'ADVISOR X'
    },
    {
      name: 'CLIENT TESTIMONIAL'
    },
    {
      name: 'CONTACT US'
    },
    {
      name: 'PAY ONLINE'
    },
  ]


  const icons = [
    {
      icon: BiLogoFacebook,
      class: 'facebook'
    },
    {
      icon: FaLinkedinIn,
      class: 'linkedin'
    },
    {
      icon: PiInstagramLogoFill,
      class: 'instagram'
    },
    {
      icon: FaYoutube,
      class: 'youtube'
    },
  ]

  const features_data = [
    {
      img: images['global-investing-icon.png'],
      text: 'Global Investing',
      main_img: images['tab-glabal-invest.png'],
      main_title: 'Global Investing',
    },
    {
      img: images['p2p-icon.png'],
      text: 'Peer-To-Peer Lending',
      main_img: images['tab-p2p.png'],
      main_title: 'Peer-To-Peer Lending',
    },
    {
      img: images['lamf-icon.png'],
      text: 'Loan Against Mutual Funds',
      main_img: images['tab-lamf.png'],
      main_title: 'Loan Against Mutual Funds',
    },
    {
      img: images['white-label-icon.png'],
      text: 'White Label',
      main_img: images['tab-white-label.png'],
      main_title: '100% White Labeled Solution!',
    },
    {
      img: images['multi-assets-icon.png'],
      text: 'Multi-Asset',
      main_img: images['tab-multi-asset.png'],
      main_title: 'Bird Eye View for 20+ Assets!',
    },
    {
      img: images['portfolio-analysis-icon.png'],
      text: 'Portfolio Analysis',
      main_img: images['tab-portfolio-analysis.png'],
      main_title: 'Powerful Portfolio Analysis!',
    },
    {
      img: images['online-atm-icon.png'],
      text: 'Online ATM',
      main_img: images['tab-online-atm.png'],
      main_title: 'Online ATM – It’s a wallet give returns like FD!',
    },
    {
      img: images['portfolio-rebalancing-icon.png'],
      text: 'Portfolio Rebalancing',
      main_img: images['tab-portfolio-rebalancing.png'],
      main_title: 'Portfolio Re-Balancing with BSE & NSE Integration!',
    },
    {
      img: images['goal-tracking-icon.png'],
      text: 'Goal Tracker',
      main_img: images['tab-goal-gps.png'],
      main_title: 'Goal Tracker – Embedded with BSE/NSE.',
    },
    {
      img: images['financial-planning-icon.png'],
      text: 'Financial Planning',
      main_img: images['tab-financial-planning.png'],
      main_title: 'Financial Planning – Generate 40 pages Professional Financial Plans in minutes.',
    },
    {
      img: images['lamf-icon.png'],
      text: 'Video Kyc',
      main_img: images['video-kyc-icon.png'],
      main_title: 'Video KYC – Instant KYC from Anywhere.',
    },
    {
      img: images['research-icon.png'],
      text: 'Research',
      main_img: images['tab-financial-planning.png'],
      main_title: 'Research Tools',
    },
    {
      img: images['online-transaction-icon.png'],
      text: 'Online Transactions',
      main_img: images['tab-financial-planning.png'],
      main_title: 'Online Transactions',
    }
  ]


  return (
    <>
      <div className="sticky-top">
        <div className="bg-dark">
          <div className="container py-1">
            <div className="row">
              <div className="col">
                <div className="d-flex flex-row  gap-3 ">
                  <img src="https://wealthelite.in/img/whatsapp-now-button.png" style={{ maxWidth: '120px' }} className='' alt="" />
                </div>
              </div>
              <div className="col d-flex justify-content-end ">
                <div className="d-flex flex-row  gap-3 iconss">
                  {icons.map((item, index) => (
                    <div className={`${item.class} icon`} key={index}>
                      <item.icon />
                    </div>
                  ))}

                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar md-px-5 navbar-expand-lg navbar-light navbar-light_2 bg-white">
          <div className="container">
            <a className="navbar-brand" href="#"><img src={Logo} style={{ height: '80px', borderRadius: '10px' }} className='' alt="" /></a>
            <button className="navbar-toggler border-0 outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse " id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {links.map((list, index) => (
                  <li className="nav-item" key={index}>
                    <a className="nav-link active text-uppercase text-primary" aria-current="page" href="#">{list.name}</a>
                  </li>
                ))}
                <li className="nav-item mx-md-3 my-3 my-md-auto" >
                  <a className="nav-link active text-uppercase text-primary px-3 border border-primary py-1 rounded-3" aria-current="page" href="#">investor</a>
                </li>
                <li class="nav-item dropdown my-auto">
                  <a class="nav-link dropdown-toggle bg-primary text-white fs-6 py-1 px-3 rounded-3 fw-bold" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    ADVISOR
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><hr class="dropdown-divider" /></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                  </ul>
                </li>
              </ul>

            </div>
          </div>
        </nav>
      </div>
      <div className="container">
        <div className="row h-100 py-5 hero">
          <div className="left col-md-4 col-12 py-md-5 d-flex  align-items-start justify-content-center flex-column">
            <div className=" d-flex flex-column align-items-md-start align-items-center text-md-start text-center justify-content-center gap-3">
              <div className="">
                <h1 className='fw-bold'>The Future of</h1>
                <h6 className='mt-2 fw-bold'>Mutual Fund Software</h6>
              </div>
              <span>Wealth Elite is an Artificial Intelligence-based <small className='fw-bold'>WHITE LABELLED</small> Multi Assets SaaS platform for India’s Top <small className='fw-bold'>Financial Advisors</small> equipped with digital client lifecycle management (CLM) solution,
                <small className='fw-bold'>Powerful Portfolio Reporting & Re-Balancing, Business Intelligence, Online Transactions, Goal GPS, Video KYC, Financial Planning, Research.</small></span>
            </div>
          </div>
          <div className="col-md-6 ms-auto col-12 py-5">
            <img src="https://wealthelite.in/website/images/wen-header-img.webp" className='h-100 w-100' alt="" />
          </div>
        </div>
      </div>
      <div className="features py-5">
        <div className="container py-5 ">
          <h1 className='text-center fw-bold'>Features</h1>
          <div className="row mt-5">
            <div className="col-md-6 col-xl-4 col-12">
              <div className="row row-cols-3 g-2 pb-1  m-0 ">
                {features_data.map((list, index) => (
                  <div
                    key={index}
                    className='col overflow-hidden '
                    onMouseEnter={() => handleMouseEnter(index)}
                  >
                    <div className={` ${activeIndex === index ? 'active' : ''} features_data w-100 h-100`}>
                      <div
                        className="features_img"
                        style={{
                          backgroundImage: `url(${list.img})`,
                          backgroundSize: 'cover',
                        }}
                      ></div>
                      <span className='text-uppercase'>{list.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-xl-8 col-md-6 col-12 ps-md-5 mt-5 mt-md-0 active_data">
              {features_data.map((list, index) => (
                activeIndex === index && (
                  <div className="" key={index}>
                    <a className='' href={list.main_img}>
                      <img src={list.main_img} alt={list.main_img} className='w-50 ' />
                    </a>
                    <div className="mt-3 d-flex flex-column gap-2">
                      <h1 className='fw-bold'>{list.main_title}</h1>
                      {activeIndex === 0 && (
                        <div className="">
                          <h6 className='fw-bold'>Be a FAANG Advisor, Invest Globally</h6>
                          <span>MFDs, Do your clients dream of investing in Apple, Tesla, Facebook,
                            Amazon, Netflix, or Google?</span>
                          <span>Now, you can turn their dream into reality by helping them invest in these
                            global giants with REDVision.</span>
                          <span>Get access to 4,000+ stocks & 3,000+ ETFs at your fingertips.</span>
                        </div>
                      )}
                      {activeIndex === 1 &&
                        <div className="">
                          <h6 className='fw-bold'>P2P lending is one of the most preferred non-market-linked investment instruments.</h6>
                          <ul>
                            <li>Higher returns than FDs.</li>
                            <li>Earn commission on investments.</li>
                            <li>Portfolio diversification.</li>
                          </ul>
                          <span>Start investing in P2P lending today!</span>
                        </div>
                      }
                      {activeIndex === 2 &&
                        <div className="">
                          <h6 className='fw-bold'>Get an instant loan against mutual funds</h6>
                          <span>Are untimely redemptions affecting your business?</span>
                          <span>Get an alternative to redemptions, offer a loan against mutual funds in 5 minutes to your clients, earn more commission, and keep your trail income intact.</span>
                        </div>
                      }
                      {activeIndex === 3 &&
                        <div className="">
                          <div className="">
                            <h6 className='fw-bold'>URL based white labeling:</h6>
                            <span>If Financial advisor’s website URL is www.yourdomain.com, the software will be operating on the same URL the inside report URL will be look like www.yourdomain.com/MFPortfolio.</span>
                          </div>
                          <div className="">
                            <h6 className='fw-bold'>Color Theme Based White Labeling:</h6>
                            <span>The color theme of software can be exactly matched with the color theme of Financial Advisor’s website. So that when the Investor navigates from website to backend software it will be smooths transition and enjoy the same branding.</span>
                          </div>
                        </div>
                      }
                      {activeIndex === 4 &&
                        <div className="">
                          <div className="">
                            <span>Advisor can take better decisions when he have the information of complete portfolio, wealth elite is a single platform to <small className='fw-bold'>manage Mutual Funds, Life Insurance, General Insurance, Health Insurance, Direct Equity, Postal, FD, Bonds, Commodities, Real Estate, PPF, PF, PMS, Venture Capital Funds, Alternative Investments, Arts & Antiques</small> etc.</span>
                          </div>
                          <div className="">
                            <span>The Best part is all this assets can be link with investor’s <small className='fw-bold'>Goal financial planning</small> to track the progress and achievements.</span>
                          </div>
                        </div>
                      }
                      {activeIndex === 5 &&
                        <div className="">
                          <div className="">
                            <span>Reporting <small className='fw-bold'>the complicated data in most simple way is our expertise, get equity holding, sector exposure, debt paper qualities, fund type wise pie chart, goal allocations, family member wise exposure, SIPs, Capital Gain,</small> What If Scenario of your investor in few clicks.</span>
                          </div>
                        </div>
                      }
                      {activeIndex === 6 &&
                        <div className="">
                          <div className="">
                            <span>Your clients keep money idle in bank a/c, this money can be channelized in liquid fund with Insta Redemption Feature and when every client need, Online ATM gives facility to withdraw a maximum of Rs.50,000 or 90% of invested amount within 30 minutes to bank account.</span>
                          </div>
                        </div>
                      }
                      {activeIndex === 7 &&
                        <div className="">
                          <div className="">
                            <span>Our Portfolio Re-Balancing feature in Mutual Fund Software allows you to book profit at pick and invest at bottom by just a click, the modules is integrated with BSE & NSE, so that the switch order can be placed directly from Rebalancing report, this product engineering a portfolio can be more profitable and less risky.</span>
                          </div>
                          <div className="">
                            <h6 className='fw-bold'>Strategies offered in our platform:</h6>
                            <ul className='ui_list ps-3'>
                              <li>Fixed Time Interval based rebalancing.</li>
                              <li>Percentage change based rebalancing.</li>
                            </ul>
                          </div>
                        </div>
                      }
                      {activeIndex === 8 &&
                        <div className="">
                          <span>Investments cycle revolved around FEAR, GREED & HOPE, we added <small className='fw-bold'>LOVE.</small></span>
                          <span>Our Goal Tracker allow advisor to customize the Goal tacking report by adding investor’s family & kids photos with the report.</span>
                          <span>Advisor can Map all Assets with the Goal & in case of any deficit the advisor can place future orders of mutual funds via BSE/NSE from the Goal Tracker.</span>
                        </div>
                      }
                      {activeIndex === 9 &&
                        <div className="">
                          <span>The best <small className='fw-bold'>Mutual Fund Software</small> in India that Generate complete financial plans with Insurance need analysis, post retirement cash flow, Goal report with client’s photo, Asset Allocation recommendations, Net worth analysis, solvency ratio and many more.</span>
                        </div>
                      }
                      {activeIndex === 10 &&
                        <div className="">
                          <span>Instant full video KYC by invitation link for clients present in any part of the world.</span>
                        </div>
                      }
                      {activeIndex === 11 &&
                        <div className="">
                          <span><small className='fw-bold'>Mutual Fund Software for Distributors</small> allows conducting research through Model Portfolio, Funds Factsheet, SIP/STP/SWP Past performance Calculator, Human life Value Calculator, SIP Calculator, Loan EMI Calculator, FD Calculator, Child Marriage planning Calculator, Child Education Planning Calculator, Retirement Planning Calculator, Dividend History, Latest & Historical NAV Watch, Income Tax Calculator, SIP Delay Cost calculator</span>
                        </div>
                      }
                      {activeIndex === 12 &&
                        <div className="d-flex flex-column gap-3">
                          <span>Get integrated with secured online transaction portal such as NSE NMFII or BSE StarMF to place transactions instantly from anywhere.</span>
                          <div className="">
                            <h6 className='mb-0 fw-bold'>Bulk Client Onboarding</h6>
                            <span>Welcome numerous numbers of investors at a same time with no extra formalities and heavy documentations.</span>
                          </div>
                          <div className="">
                            <h6 className='mb-0 fw-bold'>Bulk Transactions</h6>
                            <span>No limits on performing transactions to experience the high rate of market returns through buy, sale, and switch.</span>
                          </div>
                          <div className="">
                            <h6 className='mb-0 fw-bold'>Sort AMC’s</h6>
                            <span>Categorize and choose among the top AMC’s to encourage clients for making investment in the recommended schemes.</span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                )
              ))}
              <div className="pt-4 mt-1">
                <a href="#" className='demo'>request demo</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage