import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Mutual from '../pages/Mutual/Mutual';
import LiveMFPortfolio from '../pages/Mutual/LiveMFPortfolio';
import Consolidated from '../pages/Mutual/Consolidated';
import PandLValuation from '../pages/Mutual/PandLValuation';
import BetweenDataXIRR from '../pages/Mutual/BetweenDataXIRR';
import RealiasedFundXIRR from '../pages/Mutual/RealiasedFundXIRR';
import Transaction from '../pages/Mutual/Transactions';
import Folio from '../pages/Mutual/FolioMaster/Folio';
import FolioMaster from '../pages/Mutual/FolioMaster/FolioMaster'
import FolioNomineeDetails from '../pages/Mutual/FolioMaster/FolioNomineeDetails';



const MutualRoutes = () =>{
    return (
    <>
    <Routes>
        <Route path='/mutual/*' element={<MFRoute />} />
    </Routes>
    </>
    )
}


function MFRoute(){
    return (
    <>
    <Routes>
        <Route path='/dashboard' element={<Mutual />}/>
        <Route path='/portfolio-report' element={<LiveMFPortfolio />}/>
        <Route path='/cas' element={<Consolidated />}/>
        <Route path='/profit-loss' element={<PandLValuation />}/>
        <Route path='/between-date-xirr' element={<BetweenDataXIRR/>}/>
        <Route path='/show-reliased-capital-gain-xirr-filter' element={<RealiasedFundXIRR/>}/>
        <Route path='/transaction-report-filter' element={<Transaction/>}/>
        <Route path='/show-folio-master' element={<Folio/>}/>
        <Route path='/show-folio-master-filter' element={<FolioMaster/>}/>
        <Route path='/folio-nominee-details' element={<FolioNomineeDetails/>}/>
    </Routes>
    </>
    )
}


export default MutualRoutes;