import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

const notify = (type, title, message) => {
    Store.addNotification({
    type: type, // 'default', 'success', 'info', 'warning', 'danger'
    title: title,
    message: message,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
    //   onScreen: true
    }
  });
};

// Use :-  notify('success', 'This is a success message!', 'Success');
export default notify;
