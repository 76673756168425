import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import Loader from '../../../component/Loader';
import notify from '../../Notification';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const UpdateFamily = () => {
  const baseURL = process.env.REACT_APP_BASEURL;

  const [isLoading, setIsLoading] = useState(false);
  const [clientSearch, setClientSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [familyHead, setFamilyHead] = useState(null);

  useEffect(() => {
    const fetchResults = async () => {
      if (clientSearch.length > 2) {
        setIsLoading(true);
        try {
          const response = await axios.get(`${baseURL}/api/search_client?q=${clientSearch}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          setSearchResults(response.data);
          setShowDropdown(true);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error('Error fetching search results', error);
          notify('danger', 'Error', 'Failed to load search results');

        }
      } else {
        setShowDropdown(false);
        setIsLoading(false);
      }
    };

    const debounceFetchResults = setTimeout(() => {
      fetchResults();
    }, 300);

    return () => clearTimeout(debounceFetchResults);
  }, [clientSearch, baseURL]);

  const handleResultClick = (result) => {
    setClientSearch(`${result.user_details.first_name} ${result.user_details.last_name}`);
    setSelectedClientId(result._id);
    setFamilyHead(result);
    setShowDropdown(false);
  };

  const columnDefs = [
    { headerName: 'Sr. No.', field: 'srNo', width: 110 },
    { headerName: 'Family Head/Members', field: 'name', width: 300, cellStyle: params => params.data.isHead ? { fontWeight: 'bold', color: 'green' } : {} },
    { headerName: 'Client ID', field: 'clientId', width: 200 },
    { headerName: 'PAN', field: 'pan', width: 200 },
    { headerName: 'Mobile No', field: 'mobile', width: 150 },
    { headerName: 'Relation', field: 'relation', width: 160 },
    {
      headerName: 'Action',
      field: 'actions',
      width: 170,
      cellRenderer: (params) => {
        if (params.data.isHead) {
          return null; 
        }
        return (
          <div>
            <button
              className='btn btn-danger btn-sm'
              onClick={() => handleDelete(params.data)}
              type='button'
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const handleDelete = (data) =>{
    console.log(data.clientId);
  }

  const rowData = familyHead ? [
    {
      srNo: 1,
      name: `${familyHead.user_details.first_name} ${familyHead.user_details.last_name}`,
      clientId: familyHead._id,
      pan: familyHead.user_details.pan_number,
      mobile: familyHead.user_details.mobile_number,
      relation: 'Family Head',
      isHead: true
    }
  ] : [];

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <Header />

      <Container className="mt-4">
        <Row className="mb-3">
          <Col>
            <div
              className="rounded p-lg-4 p-3 my-3"
              style={{
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              }}
            >
              <h5 className="mb-3 d-flex align-items-center">
                <span className="badge bg-secondary me-2">Step 1</span>
                Select Family Head
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Info about this step</Tooltip>}
                >
                  <span className="ms-auto">
                    <FaInfoCircle color="blue" />
                  </span>
                </OverlayTrigger>
              </h5>
              <Form>
                <Form.Group controlId="familyHead">
                  <Form.Label>Family Head:</Form.Label>
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Control
                        type="text"
                        placeholder="Search client"
                        value={clientSearch}
                        onChange={(e) => setClientSearch(e.target.value)}
                      />
                      {showDropdown && searchResults.length > 0 && (
                        <ul
                          className="dropdown-menu show mx-2"
                          style={{ width: '90%', maxHeight: '200px', overflowY: 'auto' }}
                        >
                          {searchResults.map((result, index) => (
                            <li
                              key={index}
                              className="dropdown-item border-bottom"
                              onClick={() => handleResultClick(result)}
                            >
                              {`${result.user_details.first_name} ${result.user_details.last_name} [${result._id}]`}
                            </li>
                          ))}
                        </ul>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>

        {/* Family Table */}
        {familyHead && (
          <Row>
            <Col>
              <div
                className="ag-theme-alpine my-4"
                style={{width: '100%' }}
                
              >
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={rowData}
                  defaultColDef={{ resizable: true, sortable: true, }}
                  domLayout="autoHeight" 
                />
              </div>
            </Col>
          </Row>
        )}

        <Footer />
      </Container>
    </>
  );
};

export default UpdateFamily;
