import React, { useState } from 'react';
import { FaGlobe, FaFileExcel } from 'react-icons/fa';
import Navbar from '../../../Navbar';
import Header from '../../../Header';
import Footer from '../../../Footer';

const GIHolders = () => {
  const [selectedOutput, setSelectedOutput] = useState('web');

  const handleOutputSelect = (outputType) => {
    setSelectedOutput(outputType);
  };

  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h6>Include /Exclude Matured GI Investments</h6>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="investmentOptions" id="include" defaultChecked />
                    <label className="form-check-label" htmlFor="include">
                      Include Matured GI Investments
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="investmentOptions" id="exclude" />
                    <label className="form-check-label" htmlFor="exclude">
                      Exclude Matured GI Investments
                    </label>
                  </div>
                </div>
                <div className="col-md-2 d-flex flex-column">
                    <label className="me-2">Output In</label>
                    <div className="btn-group" role="group">
                      <button
                        type="button"
                        className={`btn ${selectedOutput === 'web' ? 'btn-info' : 'btn-light'}`}
                        onClick={() => handleOutputSelect('web')}
                      >
                        <FaGlobe size={20} />
                      </button>
                      <button
                        type="button"
                        className={`btn ${selectedOutput === 'excel' ? 'btn-info' : 'btn-light'}`}
                        onClick={() => handleOutputSelect('excel')}
                      >
                        <FaFileExcel size={20} />
                      </button>
                    </div>
                  </div>
              </div>
              <div className="row mt-3">
                  <div className="col-md-2">
                    <button type="submit" className="btn btn-success">
                      Show
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GIHolders;
