import React, { useState } from 'react';
import Navbar from '../../../Navbar';
import Header from '../../../Header';
import Footer from '../../../Footer';
import { FaGlobe, FaFileExcel } from 'react-icons/fa';



const MaturityList = () => {
  const [selectedOutput, setSelectedOutput] = useState('web');
  const [selectedOption, setSelectedOption] = useState('searchClient');



  const handleOutputSelect = (outputType) => {
    setSelectedOutput(outputType);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              <div className="row">
                <div className="col-md-4 ">
                  <label htmlFor="client" className="form-label">
                    Select Client
                  </label>
                  <select className="form-select" id="client">
                    <option>Select Client</option>
                  </select>

                </div>


                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" id="fromDate" />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" id="toDate" />
                  </div>
                </div>

                <div className="col-md-2 d-flex flex-column">
                  <label className="me-2">Output In</label>
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className={`btn ${selectedOutput === 'web' ? 'btn-info' : 'btn-light'}`}
                      onClick={() => handleOutputSelect('web')}
                    >
                      <FaGlobe size={20} />
                    </button>
                    <button
                      type="button"
                      className={`btn ${selectedOutput === 'excel' ? 'btn-info' : 'btn-light'}`}
                      onClick={() => handleOutputSelect('excel')}
                    >
                      <FaFileExcel size={20} />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <button type="submit" className="btn btn-success">
                      Show
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default MaturityList;