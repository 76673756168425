import React from 'react';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./../node_modules/bootstrap/dist/js/bootstrap.js";

import './App.css';
import LandingPage from './component/LandingPage';
import {Routes, Route, Router, BrowserRouter } from 'react-router-dom';
import UserManager from './component/routes/UserManager.jsx';
import Insurance from './component/routes/Insurance.jsx';
import Assets from './component/routes/Assets.jsx';
import MutualRoutes from './component/routes/MutualRoutes.jsx'
import Login from './component/pages/Login.jsx';
import HomePage from './component/HomePage';
import PrivateRoute from './component/ProtectedRoutes/ProtectedRoutes.jsx';



function App() {

  return (
    <>
    <ReactNotifications />
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/login" element={<Login/>} />
        
        <Route path="/dashboard" element={<PrivateRoute element={LandingPage}/>} />
        <Route path="/UserManager/*" element={<PrivateRoute element={UserManager}/>} />
        <Route path="/Insurance/*" element={<PrivateRoute element={Insurance}/>} />
        <Route path="/Assets/*" element={<PrivateRoute element={Assets}/>} />
        <Route path="/MutualFund/*" element={<PrivateRoute element={MutualRoutes}/>} />
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
