import React from 'react'
import Navbar from '../../Navbar'
import Header from '../../Header'
import Footer from '../../Footer'
import { Link } from 'react-router-dom'


const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  };

  const images = importAll(require.context('../../../assets/Images/Mutual', false, /\.(png|jpe?g|svg)$/));



const Mutual = () => {

    const mutualData = [
        {
            link: '/MutualFund/mutual/portfolio-report',
            icon: images['card-img (27).png'],
            title: 'Live MF',
            sub_text: 'Portfolio',
        },
        {
            link: '/MutualFund/mutual/cas',
            icon: images['card-img (26).png'],
            title: 'Consolidated',
            sub_text: 'Statement(CAS)',
        },
        {
            link: '/MutualFund/mutual/profit-loss',
            icon: images['card-img (28).png'],
            title: 'P & L Valution',
            sub_text: 'Report',
        },
        {
            link: '/MutualFund/mutual/between-date-xirr',
            icon: images['card-img (25).png'],
            title: 'Between',
            sub_text: 'Data XIRR',
        },
        {
            link: '/MutualFund/mutual/show-reliased-capital-gain-xirr-filter',
            icon: images['card-img (24).png'],
            title: 'Realised',
            sub_text: 'Funds XIRR',
        },
        {
            link: '/MutualFund/mutual/transaction-report-filter',
            icon: images['card-img (23).png'],
            title: 'Transctions',
        },
        {
            link: '/MutualFund/mutual/show-folio-master',
            icon: images['card-img (22).png'],
            title: 'Folio',
            sub_text: 'Master',
        },
        {
            link: '#',
            icon: images['card-img (21).png'],
            title: 'AUM',
            sub_text: 'Master',
        },
        {
            link: '#',
            icon: images['card-img (20).png'],
            title: 'SIP/STP/SWP',
            sub_text: 'Master',
        },
        {
            link: '#',
            icon: images['card-img (19).png'],
            title: 'AUM Growth',
            sub_text: 'Report',
        },
        {
            link: '#',
            icon: images['card-img (18).png'],
            title: 'Grow Your',
            sub_text: 'Business',
        },
        {
            link: '#',
            icon: images['card-img (17).png'],
            title: 'Allocation',
            sub_text: 'Report',
        },
        {
            link: '#',
            icon: images['card-img (16).png'],
            title: 'Recent',
            sub_text: 'Transaction',
        },
        {
            link: '#',
            icon: images['card-img (14).png'],
            title: 'pre-Printed',
            sub_text: 'Slips',
        },
        {
            link: '#',
            icon: images['card-img (15).png'],
            title: 'National',
            sub_text: 'Capital Gain',
        },
        {
            link: '#',
            icon: images['card-img (13).png'],
            title: 'Realised',
            sub_text: 'Capital Gain',
        },
        {
            link: '#',
            icon: images['card-img (12).png'],
            title: 'Monthly MIS',
        },
        {
            link: '#',
            icon: images['card-img (11).png'],
            title: 'FY Wise',
            sub_text: 'Report',
        },
        {
            link: '#',
            icon: images['card-img (10).png'],
            title: 'Maturity',
            sub_text: 'Report',
        },
        {
            link: '#',
            icon: images['card-img (8).png'],
            title: 'Load Free',
            sub_text: 'Units Report',
        },
        {
            link: '#',
            icon: images['card-img (9).png'],
            title: 'Brokerage Report',
            sub_text: 'Report',
        },
        {
            link: '#',
            icon: images['card-img (7).png'],
            title: 'Export To',
            sub_text: 'AWS Report',
        },
        {
            link: '#',
            icon: images['card-img (6).png'],
            title: 'Due Diligence',
            sub_text: 'Report',
        },
        {
            link: '#',
            icon: images['card-img (5).png'],
            title: 'Tax',
            sub_text: 'Package',
        },
        {
            link: '#',
            icon: images['card-img (4).png'],
            title: 'KYC',
            sub_text: 'Report',
        },
        {
            link: '#',
            icon: images['card-img (30).png'],
            title: 'Online ATM',
        },
        {
            link: '#',
            icon: images['card-img (3).png'],
            title: 'Dividend',
            sub_text: 'Report',
        },
        {
            link: '#',
            icon: images['card-img (2).png'],
            title: 'SOA',
            sub_text: 'Downloads',
        },
        {
            link: '#',
            icon: images['card-img (1).png'],
            title: 'PortFolio',
            sub_text: 'Re-Balance',
        },
        {
            link: '#',
            icon: images['card-img (29).png'],
            title: 'Business Oppo.',
            sub_text: 'Report',
        },
        {
            link: '#',
            icon: images['card-img (11).png'],
            title: 'Disclaimer',
        },
    ]


    return (
        <>
            <Navbar />
            <Header />
            <div className="container pt-3">
                <div className="row row-cols-lg-5 row-cols-md-3 row-cols-2">
                    {mutualData.map((list, index) => (
                        <div key={index} className="text-dark mf-bx-sm CRM_card">
                            <Link to={list.link} className="mf-dashbox nc-mf-dashbox">
                                <div className="icon">
                                    <img src={list.icon} alt="" className='item-icon' />
                                </div>
                                <div className="card-menu-content">
                                    <h4 className='text-nowrap'>{list.title}</h4>
                                    <span className='text-dark'>{list.sub_text && list.sub_text}</span>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <Footer sticlyFooter={true} />
        </>
    )
}

export default Mutual