import React from 'react'
import { useEffect } from 'react'
import Navbar from '../../Navbar'
import Header from '../../Header'
import Footer from '../../Footer'
import '../../../assets/css/Pages css/CommonCard.css'
import NewPolicy from '../../../assets/Images/Insurance/new-insurance.png'
import updatePolicy from '../../../assets/Images/Insurance/liUpdate.png'
import bulkUpload from '../../../assets/Images/Insurance/bulkUpload.png'
import MaturityList from '../../../assets/Images/Insurance/maturityList.png'
import Fdbond from '../../../assets/Images/Insurance/fdBond.png'
import Prematurity from '../../../assets/Images/Insurance/card-Img-1.png'
import PPF from '../../../assets/Images/Insurance/PMS Investment.png'
import Table from '../../../assets/Images/Insurance/PMS Investment.png'
import Transactions from '../../../assets/Images/Insurance/card-img-2.png'
import { Link } from 'react-router-dom'

 const FDBonds = () => {
  const cardData = [
    { name: 'Add Investment', icon: NewPolicy, link: '/Assets/FD/add-fd' },
    { name: 'View / Update/ Delete Investment', icon: updatePolicy, link: '/Assets/FD/update-fd-filter' },
    { name: 'Maturity List', icon: MaturityList, link: '/Assets/FD/fd-maturity-filter' },
    { name: 'FD / Bond Holders List', icon: Fdbond, link: '/Assets/FD/fd-investors-filter' },
    { name: 'Prematurity Encashment', icon: Prematurity, link: '/Assets/FD/fd-prematurity-filter' },
    { name: 'Show PPF', icon: PPF, link: '/Assets/FD/fd-ppf-filter' },
    { name: 'EPF Table', icon: Table, link: '/Assets/FD/fd-epf-filter' },
    { name: 'Manage RD Transactions', icon: Transactions, link: '/Assets/FD/fd-rd-filter' },
    { name: 'Upload Bulk FD', icon: bulkUpload, link: '/Assets/FD/upload-bulk-fd' }
]
useEffect(() => {
  document.body.classList.add('no-scroll');
  return () => {
    document.body.classList.remove('no-scroll');
  };
}, []);
  return (
    <>
    <Navbar/>
    <Header/>
    <div className='content-wrapper position-relative mt-3 d-flex flex-column' style={{ minHeight: 'calc(100vh - 152px)'}}>
        <div className='container' id='changesWidth'>
          <div id="main-content-wrapper" className='container-fluid'>
            <div className='container-fluid'>
              <div className='row'>
                {cardData.map((card, index) => (
                  <div key={index} className='col-lg-4 col-md-6'>
                    <div className='card-menu'>
                      <div className='icon'>
                        <img src={card.icon} alt={card.name} height={54}/>
                      </div>
                      <div className='card-menu-content'>
                        <h4>{card.name}</h4>
                      </div>
                      <Link to={card.link} className='card-menu-link'></Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer sticlyFooter={true}/>
      </div>

    </>
  )
}

export default FDBonds
