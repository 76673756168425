import React from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

const FamilyReletion = () => {
  return (
    <>
      <Navbar />
      <Header />
      <Container className="mt-4">
        <Row className='mb-3'>
          <Col>
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
            <Form>
                <Form.Group controlId="familyHead">
                  <Form.Label>Search Family Head</Form.Label>
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Control type="text" placeholder="" />
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

export default FamilyReletion;
