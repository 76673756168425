import React from 'react';
import Navbar from '../../../Navbar';
import Header from '../../../Header';
import Footer from '../../../Footer';
import { FaFileExcel } from 'react-icons/fa';


const UpdateBulk = () => {
  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
            <div>
                <div className="row">
                <div className="col-md-6 d-flex flex-column align-items-center bg-light rounded p-3">
                  <FaFileExcel size={80} color="#28a745" className="mb-3" />
                  <button type="button" className="btn btn-success">Download Template Excel File</button>
                  <p className="mt-2">
                    <small className="text-muted">Note: Excel File Must Be In <span className="text-danger">".xls"</span> Format</small>
                  </p>
                </div>
                <div className="col-md-6 d-flex flex-column bg-light rounded p-3">
                  <p>Please Select A File And Click Upload Button</p>
                  <div className="mb-3">
                    <input type="file" className="form-control" />
                  </div>
                  <button type="button" className="btn btn-success col-3">Upload</button>
                </div>
              </div>
             </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default UpdateBulk;