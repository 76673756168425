import React from 'react';
import Navbar from '../../../Navbar';
import Header from '../../../Header';
import Footer from '../../../Footer';

const UpdatePolicy = () => {
  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              <div class="mb-3">
                <label for="SelectClient" class="form-label">Select Client / PAN..</label>
                <input type="text" class="form-control col-4" id="SelectClient" />
              </div>
              <button className='btn btn-success'>Show</button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default UpdatePolicy;