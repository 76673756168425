import React, { useState, useRef } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import { Form, Row, Col } from 'react-bootstrap';
import Loader from '../../../component/Loader';
import notify from '../../Notification';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import CustomButtonComponent from './MergeClientFilterButton';

const DifferClient = () => {
    const [searchBy, setSearchBy] = useState('name');
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [rowData, setRowData] = useState([]);
    const debounceTimeoutRef = useRef(null);

    const baseURL = process.env.REACT_APP_BASEURL;

    const mapSearchBy = (searchBy) => {
        switch (searchBy) {
            case 'name':
                return 'first_name';
            case 'email':
                return 'email';
            case 'pan':
                return 'pan_number';
            case 'mobile':
                return 'mobile_number';
            case 'address':
                return 'address';
            default:
                return 'first_name';
        }
    };

    const fetchSearchResults = async (query) => {
        if (query.length > 2) {
            setIsLoading(true);
            try {
                const transformedSearchBy = mapSearchBy(searchBy);
                const response = await axios.post(`${baseURL}/api/search_client_by_type`, {
                    searchQuery: query,
                    searchBy: transformedSearchBy,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = response.data;

                if (data) {
                    setRowData(data);
                } else {
                    notify('danger', 'Error', 'No clients found!');
                    setRowData([]);
                }
            } catch (error) {
                notify('danger', 'Error', 'Failed to search!');
                setRowData([]);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleSearchInput = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
            fetchSearchResults(query);
        }, 500);
    };

    const columnDefs = [
        { headerName: 'Sr. No.', field: 'srNo', valueGetter: 'node.rowIndex + 1', width: 50 },
        { headerName: 'Client Name', field: 'clientName', valueGetter: (params) => `${params.data.user_details.first_name} ${params.data.user_details.last_name}` },
        { headerName: 'Client ID', field: '_id', width: 200 },
        { headerName: 'PAN', field: 'pan_number', width: 150, valueGetter: (params) => `${params.data.user_details.pan_number}` },
        { headerName: 'Mobile', field: 'mobile_number', width: 150, valueGetter: (params) => `${params.data.user_details.mobile_number}` },
        { headerName: 'Email', field: 'email', width: 200, valueGetter: (params) => `${params.data.user_details.email}` },
        { headerName: 'Pincode', field: 'pincode', width: 120, valueGetter: (params) => `${params.data.user_details.pincode}` },
        {
            headerName: 'Actions',
            field: 'actions',
            width: 142,
            cellRenderer: (params) => (
                <CustomButtonComponent
                    data={params.data}
                    mainClient={() => mainClient(params.data)}
                />
            ),
        },
    ];

    const mainClient = async (clientData) => {
        console.log(clientData)
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseURL}/api/${ clientData._id }/get_merge_client`,{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status) {
                notify('success', 'Success', 'Client merged successfully!');
                console.log(response)
            } else {
                notify('danger', 'Error', 'Failed to merge client!');
            }
        } catch (error) {
            notify('danger', 'Error', 'An error occurred while merging the client!');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <Navbar />
            <Header />
            <div className="container mt-4">
                <div className="row mb-3">
                    <div className="col">
                        <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
                            <div className="p-3 bg-white rounded">
                                <Row className="align-items-center">
                                    <Col xs={12} md={2} className="p-3 d-flex flex-column justify-content-center align-items-center rounded" style={{ backgroundColor: '#C8C8C8' }}>
                                        <div className="fw-bold text-danger me-2">Step 1</div>
                                        <div className="step-description fw-bold">Select Main Client</div>
                                    </Col>

                                    <Col xs={12} md={10}>
                                        <Form>
                                            <Row className="align-items-center">
                                                <Col xs={12} md={4}>
                                                    <Form.Label className="fw-bold">Search by -</Form.Label>
                                                    <Form.Select
                                                        value={searchBy}
                                                        onChange={(e) => setSearchBy(e.target.value)}
                                                    >
                                                        <option value="name">Name</option>
                                                        <option value="email">Email</option>
                                                        <option value="pan">PAN No.</option>
                                                        <option value="mobile">Mobile No.</option>
                                                        <option value="address">Address</option>
                                                    </Form.Select>
                                                </Col>

                                                <Col xs={12} md={6} className="mt-3 mt-md-0">
                                                    <Form.Label className="fw-bold">Search Client Here</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`Search ${searchBy.charAt(0).toUpperCase() + searchBy.slice(1)} Here...`}
                                                        value={searchQuery}
                                                        onChange={handleSearchInput}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>

                                    <div className="mt-3">
                                        {rowData.length > 0 && (
                                            <>
                                                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                                    <AgGridReact
                                                        rowData={rowData}
                                                        columnDefs={columnDefs}
                                                        domLayout="autoHeight"
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>

                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default DifferClient;
