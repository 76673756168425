import React from 'react';
import Navbar from '../../../Navbar';
import Header from '../../../Header';
import Footer from '../../../Footer';

const UploadBulkFD = () => {
  return (
    <>
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row">
          <div className="col">
            <div
              className="rounded p-lg-4 p-3 mb-3"
              style={{
                backgroundColor: '#f8f9fa',
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
              }}
            >
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="fixedScheme" className="form-label">
                    Select Fixed Scheme
                  </label>
                  <select className="form-select" id="fixedScheme">
                    <option>Select Fixed Income</option>
                  </select>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="client" className="form-label">
                    Select Client
                  </label>
                  <select className="form-select" id="client">
                    <option>Select Client</option>
                  </select>
                </div>
              </div>
              <button className="btn btn-success">Submit</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UploadBulkFD;
