import React from 'react'
import { useEffect } from 'react'
import Navbar from '../../Navbar'
import Header from '../../Header'
import Footer from '../../Footer'
import '../../../assets/css/Pages css/CommonCard.css'
import NewPolicy from '../../../assets/Images/Insurance/new-insurance.png'
import PMSInvestment from '../../../assets/Images/Insurance/maturityList.png'
import updatePolicy from '../../../assets/Images/Insurance/liUpdate.png'
import PostalList from '../../../assets/Images/Insurance/fdBond.png'
import cardImg_1 from  '../../../assets/Images/Insurance/card-Img-1.png'
import RDTrans from '../../../assets/Images/Insurance/card-img-2.png'
import PPF from '../../../assets/Images/Insurance/PMS Investment.png'


const Postal = () => {
  
  const cardData = [
    { name: 'Enter New Postal', icon: NewPolicy, link: '#' },
    { name: 'View / Update/ Delete Postal', icon: updatePolicy, link: '#' },
    { name: 'Maturity List', icon: PMSInvestment, link: '#' },
    { name: 'Postal Investors List', icon: PostalList, link: '#' },
    { name: 'Prematurity Encashment', icon: cardImg_1, link: '#' },
    { name: ' Manage RD Transactions', icon: RDTrans, link: '#' },
    { name: 'Show PPF', icon: PPF, link: '#' }
   
]
useEffect(() => {
  document.body.classList.add('no-scroll');
  return () => {
    document.body.classList.remove('no-scroll');
  };
}, []);
  return (
    <>
    <Navbar/>
    <Header/>
    <div className='content-wrapper position-relative mt-3 d-flex flex-column' style={{ minHeight: 'calc(100vh - 152px)'}}>
        <div className='container' id='changesWidth'>
          <div id="main-content-wrapper" className='container-fluid'>
            <div className='container-fluid'>
              <div className='row'>
                {cardData.map((card, index) => (
                  <div key={index} className='col-lg-4 col-md-6'>
                    <div className='card-menu'>
                      <div className='icon'>
                        <img src={card.icon} alt={card.name} height={54}/>
                      </div>
                      <div className='card-menu-content'>
                        <h4>{card.name}</h4>
                      </div>
                      <a href={card.link} className='card-menu-link'></a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer sticlyFooter={true}/>
      </div>

    </>
  )
}
export default Postal