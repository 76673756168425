import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../../component/Loader';
import notify from '../../Notification';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';



const Transaction = () => {
  const baseURL = process.env.REACT_APP_BASEURL;

  const [isLoading, setIsLoading] = useState(false);
  const [clientSearch, setClientSearch] = useState('');
  const [viewBy, setViewBy] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedClientData, setSelectedClientData] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [rowData, setRowData] = useState([]); 

  const columnDefs = [
    { headerName: "Sr. No", field: "srNo", valueGetter: "node.rowIndex + 1", sortable: true, width: 100 },
    { headerName: "Folio Number", field: "folio_number", sortable: true, filter: true, width:500 },
    { headerName: "Scheme Name", field: "scheme_name", sortable: true, filter: true, width:645 },
  ];
  useEffect(() => {
    const fetchResults = async () => {
      if (clientSearch.length > 2) {
        setIsLoading(true);
        try {
          const response = await axios.get(`${baseURL}/api/search_client?q=${clientSearch}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          setSearchResults(response.data);
          setShowDropdown(true);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false)
          notify('danger', 'Error', 'Failed to load search results');
        }
      } else {
        setIsLoading(false);
        setShowDropdown(false);
      }
    };

    const debounceFetchResults = setTimeout(() => {
      fetchResults();
    }, 300);

    return () => clearTimeout(debounceFetchResults);
  }, [clientSearch, baseURL]);
  const get_client_investment_fund_list = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseURL}/api/get_client_investment_fund_list/${selectedClientId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const schemes = response.data.schemes.map((scheme, index) => ({
        srNo: index + 1,
        folio_number: scheme.folio_number,
        scheme_name: scheme.scheme_name,
      }));

      setRowData(schemes); 
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notify('danger', 'Error', 'Failed to load search results');
    }
  };

  const handleResultClick = (result) => {
    setClientSearch(`${result.user_details.first_name} ${result.user_details.last_name}`);
    setSelectedClientId(result._id);
    setSelectedClientData(result.user_details);
    setShowDropdown(false);
  };

  const handelShowData = (e) => {
    e.preventDefault();

    if (!viewBy) {
      notify('danger', 'Error', 'Please select an option to view by');
      return;
    }
    if (!selectedClientId) {
      notify('danger', 'Error', 'Please select client');
      return;
    }
    if (viewBy === 'client') {
      get_client_investment_fund_list();
    }
    setShowDetails(true);
  };

  const currentDate = new Date().toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <Header />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col">
            <div className="rounded p-lg-4 p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
              <div className="row g-3 align-items-center">
                <div className="col-md-3">
                  <label htmlFor="viewBy" className="form-label">Select View By</label>
                  <select
                    className="form-select"
                    value={viewBy}
                    onChange={(e) => setViewBy(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="client">Client</option>
                    <option value="family">Family</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="search" className="form-label">Search By Name / PAN / Folio No.</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search client"
                      value={clientSearch}
                      onChange={(e) => setClientSearch(e.target.value)}
                    />
                    {showDropdown && searchResults.length > 0 && (
                      <ul
                        className="dropdown-menu show mx-2 border-bottom"
                        style={{ width: '90%', maxHeight: '200px', overflowY: 'auto' }}
                      >
                        {searchResults.map((result, index) => (
                          <li
                            key={index}
                            className="dropdown-item"
                            onClick={() => handleResultClick(result)}
                          >
                            {`${result.user_details.first_name} ${result.user_details.last_name} [${result._id}]`}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <button className="btn btn-success" onClick={handelShowData}>Show</button>
                </div>
              </div>
            </div>
            {showDetails && (
              <>
                <div
                  className="rounded p-lg-4 p-3 my-3"
                  style={{
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                  }}
                >
                  <div className="container-fluid mb-4">
                    <div className="row">
                      <div className="col-md-4">
                        <p className="mb-0"><strong>Client Name :</strong></p>
                        <p className="mb-0">{`${selectedClientData.first_name} ${selectedClientData.last_name}`}</p>
                        <p className="mb-0 text-muted small">{`[PAN: ${selectedClientData.pan_number}]`}</p>
                        <p className="mb-0 text-muted small">{`[Email: ${selectedClientData.email}]`}</p>
                        <p className="mb-0 text-muted small">{`[DOB: ${selectedClientData.date_of_birth}]`}</p>
                      </div>
                      <div className="col-md-4">
                        <p className="mb-0"><strong>Address :</strong></p>
                        <p className="mb-0">76 INDRALOK COLONY SUDAMANAGAR NEAR</p>
                        <p className="mb-0">ASHIRWAD BHAWANINDOREINDORE452009</p>
                        <p className="mb-0 mt-2"><strong>Contact No. :</strong></p>
                        <p className="mb-0">{selectedClientData.mobile_number}</p>
                      </div>
                      <div className="col-md-4">
                        <p className="mb-0"><strong>Current Sensex :</strong></p>
                        <p className="mb-0 text-success fw-bold">83462.61 ↑ 514.38 (0.62%)</p>
                        <p className="mb-0 mt-2"><strong>Export In:</strong></p>

                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <p className="mb-0"><strong>Valuation Report as on Date</strong></p>
                        <p className="mb-0">{currentDate}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ag-theme-alpine my-4"
                    style={{ width: '100%'}}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={rowData}
                      domLayout="autoHeight"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Transaction;
