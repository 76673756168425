import React, { useState } from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import '../../../assets/css/AddClient.css';

const AddClient = () => {
  const [key, setKey] = useState('personal');

  const handleNext = () => {
    if (key === 'personal') setKey('address');
    if (key === 'address') setKey('bank');
    if (key === 'bank') setKey('demat');
  };

  const handlePrev = () => {
    if (key === 'address') setKey('personal');
    if (key === 'bank') setKey('address');
    if (key === 'demat') setKey('bank');
  };
const [bankDetails, setBankDetails] = useState([
  {
    bankName: '',
    bankBranch: '',
    bankAddress: '',
    accountNumber: '',
    accountType: '',
    bankCity: '',
    pinCode: '',
    micr: '',
    ifsc: '',
    isMainAccount: true, 
  },
]);

const handleBankDetailChange = (index, field, value) => {
  const newBankDetails = [...bankDetails];
  newBankDetails[index][field] = value;
  setBankDetails(newBankDetails);
};

const handleMainAccountSelection = (selectedIndex) => {
  const newBankDetails = bankDetails.map((bankDetail, index) => ({
    ...bankDetail,
    isMainAccount: index === selectedIndex, 
  }));
  setBankDetails(newBankDetails);
};

const handleAddBankDetail = () => {
  setBankDetails([
    ...bankDetails,
    {
      bankName: '',
      bankBranch: '',
      bankAddress: '',
      accountNumber: '',
      accountType: '',
      bankCity: '',
      pinCode: '',
      micr: '',
      ifsc: '',
      isMainAccount: false, 
    },
  ]);
};

const handleRemoveBankDetail = (index) => {
  const newBankDetails = bankDetails.filter((_, i) => i !== index);

  if (newBankDetails.length === 1) {
    newBankDetails[0].isMainAccount = true;
  }

  setBankDetails(newBankDetails);
};

const [dematDetails, setDematDetails] = useState({
  dpDetails: '',
  dpName: '',
  dpId: '',
  beneficiaryAccountNo: '',
});

const [isProfileLocked, setIsProfileLocked] = useState(false);

const handleDematDetailChange = (field, value) => {
  setDematDetails({
    ...dematDetails,
    [field]: value,
  });
};

const handleProfileLockChange = () => {
  setIsProfileLocked(!isProfileLocked);
};


  
  return (
    <>
      <Navbar />
      <Header />
      <div className="container my-5">
        <ul className="nav nav-tabs mb-3 gap-md-4 gap-2" id="controlled-tab-example">
          <li className="nav-item tab-items">
            <a
              className={`nav-link  ${key === 'personal' ? 'active' : ''}`}
              onClick={() => setKey('personal')}
            >
              <div className='d-flex flex-column'>
                <span className="number">Step 1</span>
                <p className="m-0">Personal Information</p>
              </div>
            </a>
          </li>
          <li className="nav-item tab-items">
            <a
              className={`nav-link  ${key === 'address' ? 'active' : ''}`}
              onClick={() => setKey('address')}
            >
              <div className='d-flex flex-column'>
                <span className="number">Step 2</span>
                <p className="m-0">Address Information</p>
              </div>
            </a>
          </li>
          <li className="nav-item tab-items">
            <a
              className={`nav-link  ${key === 'bank' ? 'active' : ''}`}
              onClick={() => setKey('bank')}
            >
              <div className='d-flex flex-column'>
                <span className="number">Step 3</span>
                <p className="m-0">  Bank Information</p>
              </div>

            </a>
          </li>
          <li className="nav-item tab-items">
            <a
              className={`nav-link  ${key === 'demat' ? 'active' : ''}`}
              onClick={() => setKey('demat')}
            >
              <div className='d-flex flex-column'>
                <span className="number">Step 4</span>
                <p className="m-0">Demat Account Info</p>
              </div>
            </a>
          </li>
        </ul>
        <div className="tab-content p-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
          {key === 'personal' && (
            <div>
              <strong className='h4'>Personal Information</strong>
              <form>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Full Name</label>
                      <input type="text" className="form-control" placeholder="Enter full name" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Phone</label>
                      <input type="text" className="form-control" placeholder="Enter phone number" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Aadhar Number</label>
                      <input type="text" className="form-control" placeholder="Enter Aadhar number" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Email</label>
                      <input type="email" className="form-control" placeholder="Enter email" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Anniversary (If Applicable)</label>
                      <div>
                        <input type="radio" checked id="single" name="maritalStatus" value="single" />
                        <label htmlFor="single" className="ms-2">Single</label>
                        <input type="radio" id="married" name="maritalStatus" value="married" className="ms-3" />
                        <label htmlFor="married" className="ms-2">Married</label>
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <label>Equity UCC</label>
                      <input type="text" className="form-control" placeholder="Enter Equity UCC" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Select Occupation</label>
                      <select className="form-select">
                        <option value="">Select Occupation</option>
                        <option value="1">Occupation 1</option>
                        <option value="2">Occupation 2</option>
                        <option value="3">Occupation 3</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Client Label</label>
                      <input type="text" className="form-control" placeholder="Enter client label" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Mobile</label>
                      <div className="input-group">
                        <span className="input-group-text">+91</span>
                        <input type="text" className="form-control" placeholder="Enter mobile number" />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <label>PAN Number</label>
                      <div className="d-flex">
                        <input type="text" className="form-control col-7" placeholder="Enter PAN number" />
                        <button type="button" className="btn btn-primary btn-sm ms-2 col-2">Verify</button>
                        <button type="button" className="btn btn-primary btn-sm ms-2 col-2">Check KYC</button>
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <label>Date of Birth</label>
                      <input type="date" className="form-control" />
                    </div>
                    <div className="form-group mt-3">
                      <label>Blood Group</label>
                      <input type="text" className="form-control" placeholder="Enter blood group" />
                    </div>
                    <div className="form-group mt-3">
                      <label>CAN</label>
                      <input type="text" className="form-control" placeholder="Enter CAN" />
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                <button type="button" className="btn btn-success mt-3" onClick={handleNext}>
                  Next
                </button>
                </div>
              </form>
            </div>
          )}
          {key === 'address' && (
            <div>
              <strong className='h4'>Address Information</strong>
              <form>
                <div className="my-4">
                <div className='d-flex justify-content-between'>
                  <h5>Current Address</h5>
                  <div className="form-check mb-3">
                    <input type="radio" className="form-check-input" checked id="currentMainAddress" name="mainAddress" />
                    <label className="form-check-label" htmlFor="currentMainAddress">Main Address</label>
                  </div>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="currentAddress">Address</label>
                    <input type="text" className="form-control" id="currentAddress" placeholder="Address" />
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="currentCountry">Country</label>
                      <input type="text" className="form-control" id="currentCountry" placeholder="Country" />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="currentState">State</label>
                      <input type="text" className="form-control" id="currentState" placeholder="State" />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="currentCity">City</label>
                      <input type="text" className="form-control" id="currentCity" placeholder="City" />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="currentArea">Area</label>
                      <input type="text" className="form-control" id="currentArea" placeholder="Area" />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="currentPinCode">Pin Code</label>
                      <input type="text" className="form-control" id="currentPinCode" placeholder="Pin Code" />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="currentPhone">Phone</label>
                      <input type="text" className="form-control" id="currentPhone" placeholder="Phone" />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="currentMobile">Mobile</label>
                      <input type="text" className="form-control" id="currentMobile" placeholder="Mobile" />
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                <div className='d-flex justify-content-between'>
                <h5>Permanent Address</h5>
                  <div className="form-check mb-3">
                    <input type="radio" className="form-check-input" id="permanentMainAddress" name="mainAddress" />
                    <label className="form-check-label" htmlFor="permanentMainAddress">Main Address</label>
                  </div>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="permanentAddress">Address</label>
                    <input type="text" className="form-control" id="permanentAddress" placeholder="Address" />
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="permanentCountry">Country</label>
                      <input type="text" className="form-control" id="permanentCountry" placeholder="Country" />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="permanentState">State</label>
                      <input type="text" className="form-control" id="permanentState" placeholder="State" />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="permanentCity">City</label>
                      <input type="text" className="form-control" id="permanentCity" placeholder="City" />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="permanentPinCode">Pin Code</label>
                      <input type="text" className="form-control" id="permanentPinCode" placeholder="Pin Code" />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="permanentPhone">Phone</label>
                      <input type="text" className="form-control" id="permanentPhone" placeholder="Phone" />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="permanentMobile">Mobile</label>
                      <input type="text" className="form-control" id="permanentMobile" placeholder="Mobile" />
                    </div>
                  </div>
                </div>

                <div className='d-flex justify-content-end'>
                <button type="button" className="btn btn-secondary mt-3" onClick={handlePrev}>
                  Previous
                </button>
                <button type="button" className="btn btn-success mt-3 ms-2" onClick={handleNext}>
                  Next
                </button>
                </div>
              </form>
            </div>
          )}
    {key === 'bank' && (
  <div>
    <strong className='h4'>Bank Information</strong>
    <form>
      {bankDetails.map((bankDetail, index) => (
        <div key={index} className="mb-4">
          <div className="row">
            <div className="col-md-6">
              <label>Bank Name</label>
              <input
                type="text"
                className="form-control"
                value={bankDetail.bankName}
                onChange={(e) => handleBankDetailChange(index, 'bankName', e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Bank Branch</label>
              <input
                type="text"
                className="form-control"
                value={bankDetail.bankBranch}
                onChange={(e) => handleBankDetailChange(index, 'bankBranch', e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <label>Bank Address</label>
              <input
                type="text"
                className="form-control"
                value={bankDetail.bankAddress}
                onChange={(e) => handleBankDetailChange(index, 'bankAddress', e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Account Number</label>
              <input
                type="text"
                className="form-control"
                value={bankDetail.accountNumber}
                onChange={(e) => handleBankDetailChange(index, 'accountNumber', e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <label>Account Type</label>
              <input
                type="text"
                className="form-control"
                value={bankDetail.accountType}
                onChange={(e) => handleBankDetailChange(index, 'accountType', e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Bank City</label>
              <input
                type="text"
                className="form-control"
                value={bankDetail.bankCity}
                onChange={(e) => handleBankDetailChange(index, 'bankCity', e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <label>Pin Code</label>
              <input
                type="text"
                className="form-control"
                value={bankDetail.pinCode}
                onChange={(e) => handleBankDetailChange(index, 'pinCode', e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>MICR</label>
              <input
                type="text"
                className="form-control"
                value={bankDetail.micr}
                onChange={(e) => handleBankDetailChange(index, 'micr', e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <label>IFSC</label>
              <input
                type="text"
                className="form-control"
                value={bankDetail.ifsc}
                onChange={(e) => handleBankDetailChange(index, 'ifsc', e.target.value)}
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <label className="ms-2">Main Account</label>
              <input
                type="radio"
                className="mt-0 mx-3"
                checked={bankDetail.isMainAccount}
                onChange={() => handleMainAccountSelection(index)}
              />
            </div>
          </div>
          {index !== 0 && bankDetails.length > 1 && (
            <div className="row mt-3">
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveBankDetail(index)}
                >
                  Remove
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
      
      <button
        type="button"
        className="btn btn-link"
        onClick={handleAddBankDetail}
      >
        [+] Add more address bank
      </button>

      <div className="d-flex justify-content-end mt-3">
        <button type="button" className="btn btn-secondary" onClick={handlePrev}>
          Previous
        </button>
        <button type="button" className="btn btn-success ms-2" onClick={handleNext}>
          Next
        </button>
      </div>
    </form>
  </div>
)}


{key === 'demat' && (
  <div>
    <strong className='h4'>Demat Account  Information</strong>
    <form>
      <div className="row mt-3">
        <div className="col-md-6">
          <label>DP Details</label>
          <input
            type="text"
            className="form-control"
            value={dematDetails.dpDetails}
            onChange={(e) => handleDematDetailChange('dpDetails', e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label>DP Name</label>
          <input
            type="text"
            className="form-control"
            value={dematDetails.dpName}
            onChange={(e) => handleDematDetailChange('dpName', e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <label>DP ID</label>
          <input
            type="text"
            className="form-control"
            value={dematDetails.dpId}
            onChange={(e) => handleDematDetailChange('dpId', e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label>Beneficiary A/C No</label>
          <input
            type="text"
            className="form-control"
            value={dematDetails.beneficiaryAccountNo}
            onChange={(e) => handleDematDetailChange('beneficiaryAccountNo', e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <label>
            <input
              type="checkbox"
              className=" mx-2"
              checked={isProfileLocked}
              onChange={handleProfileLockChange}
            />
            Do you want to lock Profile?
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <button type="button" className="btn btn-secondary" onClick={handlePrev}>
          Previous
        </button>
        <button type="button" className="btn btn-success ms-2">
          Submit
        </button>
      </div>
    </form>
  </div>
)}

        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddClient;
