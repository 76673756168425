import React from 'react';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';

const EmployeeClientMap = () => {
    return (
        <>
            <Navbar />
            <Header />
            <div className="container mt-4">
                <div className="row mb-3">
                    <div className="col">
                        <div className="rounded p-3 my-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
                            <div className="row">
                                <div className="col-lg-10">
                                    <div className="p-3">
                                        <h5>Change Existing Employee</h5>
                                        <div className="d-flex mt-3">
                                            <input type="text" placeholder="Load client" className="form-control col-4" />
                                            <button className="btn btn-success ms-2">Load Client</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default EmployeeClientMap;
