import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Navbar from '../../Navbar';
import Header from '../../Header';
import Footer from '../../Footer';
import '../../../assets/css/CommonTable.css'

export default function ClientAnalysisReport() {

    const [activeTab, setActiveTab] = useState('city');

    const rowData = [
        { srNo: 1, cityName: 'City Not Specified', noOfClients: 552 },
        { srNo: 2, cityName: 'Indore', noOfClients: 2387 },
        { srNo: 3, cityName: 'Indore M P', noOfClients: 1 },
        { srNo: 4, cityName: 'Rajkot', noOfClients: 9 },
        { srNo: 5, cityName: 'Bhopal', noOfClients: 48 },
        { srNo: 6, cityName: 'Mumbai', noOfClients: 57 },
    ];

    const columnDefs = [
        { headerName: 'Sr. No.', field: 'srNo', resizable: true, headerClass: 'header-black' },
        { headerName: 'City Name', field: 'cityName', sortable: true, filter: true, resizable: true, headerClass: 'header-black' },
        { headerName: 'No. of Clients', field: 'noOfClients', sortable: true, filter: true, resizable: true, headerClass: 'header-black' },
    ];

    const rowDataPin = [
        { srNo: 1, pincode: 'Pincode Not Available', cityName: '',noOfClients: 652 },
        { srNo: 2, pincode: '452008', cityName: 'Indore',noOfClients: 49 },
        { srNo: 3, pincode: '450011', cityName: 'Indore',noOfClients: 2 },
        { srNo: 4, pincode: '452018', cityName: 'Indore',noOfClients: 87 },
        { srNo: 5, pincode: '452001', cityName: 'Indore',noOfClients: 786 },
        { srNo: 6, pincode: '452005', cityName: 'Indore',noOfClients: 10 },
    ];

    const columnDefsPin = [
        { headerName: 'Sr. No.', field: 'srNo', resizable: true, headerClass: 'header-black' },
        { headerName: 'Pincode', field: 'pincode', sortable: true, filter: true, resizable: true, headerClass: 'header-black' },
        { headerName: 'City Name', field: 'cityName', sortable: true, filter: true, resizable: true, headerClass: 'header-black' },
        { headerName: 'No. Of Clients', field: 'noOfClients', sortable: true, filter: true, resizable: true, headerClass: 'header-black' },

    ];



    const chartOptions = {
        chart: {
            type: 'pie',
        },
        title: {
            text: 'Client Analysis Report',
        },
        credits: {
            enabled: false, 
        },
        series: [
            {
                name: 'Clients',
                data: rowData.map(row => ({
                    name: row.cityName,
                    y: row.noOfClients,
                })),
            },
        ],
    };

    return (
        <>
            <Navbar />
            <Header />
            <div className="container mt-4">
                <h5 className="font-semibold">Client Analysis Report</h5>

                {/* Tab Panel */}
                <ul className="nav nav-tabs border-0" role="tablist">
                    <li className="nav-item clientAnalysis mx-1">
                        <a
                            className={`nav-link ${activeTab === 'city' ? 'active' : ''}`}
                            href="#city"
                            onClick={() => setActiveTab('city')}
                        >
                            CITY
                        </a>
                    </li>
                    <li className="nav-item clientAnalysis mx-1">
                        <a
                            className={`nav-link ${activeTab === 'cityAreaPin' ? 'active' : ''}`}
                            href="#cityAreaPin"
                            onClick={() => setActiveTab('cityAreaPin')}
                        >
                            CITY AREA PIN
                        </a>
                    </li>
                    <li className="nav-item clientAnalysis mx-1">
                        <a
                            className={`nav-link ${activeTab === 'riskProfile' ? 'active' : ''}`}
                            href="#riskProfile"
                            onClick={() => setActiveTab('riskProfile')}
                        >
                            RISK PROFILE
                        </a>
                    </li>
                    <li className="nav-item clientAnalysis mx-1">
                        <a
                            className={`nav-link ${activeTab === 'category' ? 'active' : ''}`}
                            href="#category"
                            onClick={() => setActiveTab('category')}
                        >
                            CATEGORY
                        </a>
                    </li>
                    <li className="nav-item clientAnalysis mx-1">
                        <a
                            className={`nav-link ${activeTab === 'company' ? 'active' : ''}`}
                            href="#company"
                            onClick={() => setActiveTab('company')}
                        >
                            COMPANY
                        </a>
                    </li>
                    <li className="nav-item clientAnalysis mx-1">
                        <a
                            className={`nav-link ${activeTab === 'designation' ? 'active' : ''}`}
                            href="#designation"
                            onClick={() => setActiveTab('designation')}
                        >
                            DESIGNATION
                        </a>
                    </li>
                </ul>

           

                {/* Tab Content */}
                <div className="tab-content mt-4 clientAnalysisContent">
                    <div className={`tab-pane fade ${activeTab === 'city' ? 'show active' : ''}`} id="city">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                    <AgGridReact
                                        rowData={rowData}
                                        columnDefs={columnDefs}
                                        pagination={true}
                                        paginationPageSize={5}
                                        domLayout="autoHeight" 
                                        defaultColDef={{
                                            flex: 1, 
                                            resizable: true, 
                                        }}  
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 border rounded">
                                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'cityAreaPin' ? 'show active' : ''}`} id="cityAreaPin">
                    <div className="row">
                            <div className="col-md-8">
                                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                    <AgGridReact
                                        rowData={rowDataPin}
                                        columnDefs={columnDefsPin}
                                        pagination={true}
                                        paginationPageSize={5}
                                        domLayout="autoHeight" 
                                        defaultColDef={{
                                            flex: 1, 
                                            resizable: true, 
                                        }}
                                        
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 border rounded">
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'riskProfile' ? 'show active' : ''}`} id="riskProfile">
                    <div className="row">
                            <div className="col-md-8">
                                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                    <AgGridReact
                                        rowData={rowDataPin}
                                        columnDefs={columnDefsPin}
                                        pagination={true}
                                        paginationPageSize={5}
                                        domLayout="autoHeight" 
                                        defaultColDef={{
                                            flex: 1, 
                                            resizable: true, 
                                        }}
                                        
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 border rounded">
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'category' ? 'show active' : ''}`} id="category">
                    <div className="row">
                            <div className="col-md-8">
                                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                    <AgGridReact
                                        rowData={rowDataPin}
                                        columnDefs={columnDefsPin}
                                        pagination={true}
                                        paginationPageSize={5}
                                        domLayout="autoHeight" 
                                        defaultColDef={{
                                            flex: 1, 
                                            resizable: true, 
                                        }}
                                        
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 border rounded">
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'company' ? 'show active' : ''}`} id="company">
                    <div className="row">
                            <div className="col-md-8">
                                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                    <AgGridReact
                                        rowData={rowDataPin}
                                        columnDefs={columnDefsPin}
                                        pagination={true}
                                        paginationPageSize={5}
                                        domLayout="autoHeight" 
                                        defaultColDef={{
                                            flex: 1, 
                                            resizable: true, 
                                        }}
                                        
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 border rounded">
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'designation' ? 'show active' : ''}`} id="designation">
                    <div className="row">
                            <div className="col-md-8">
                                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                    <AgGridReact
                                        rowData={rowDataPin}
                                        columnDefs={columnDefsPin}
                                        pagination={true}
                                        paginationPageSize={5}
                                        domLayout="autoHeight" 
                                        defaultColDef={{
                                            flex: 1, 
                                            resizable: true, 
                                        }}
                                        
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 border rounded">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
